import {
  CreateRouteDto,
  RouteSource,
  RoutesMutationResponseDto,
} from "@sofarocean/wayfinder-typescript-client";
import _ from "lodash";
import { useCallback, useContext, useMemo } from "react";
import { useMutation } from "react-query";
import AnalyticsContext, { AnalyticsEvent } from "../contexts/Analytics";
import { CrystalGlobeApiContext } from "../contexts/CrystalGlobeApiContext";
import {
  Route,
  Schedule,
  ScheduleElement,
  Schedules,
} from "../shared-types/RouteTypes";

type ScheduleWithDefinedScheduleElements = Schedule & {
  calculated?: {
    scheduleElements: ScheduleElement[];
  };
  manual?: {
    scheduleElements: ScheduleElement[];
  };
};

export const getRouteForApi = (
  route: Route
): Route & {
  extensions: { uuid: string };
  schedules: Schedules & { schedules: ScheduleWithDefinedScheduleElements[] };
} => {
  const clonedRoute = _.cloneDeep(route);
  const uuid = clonedRoute.extensions?.uuid;
  if (clonedRoute.extensions && uuid) {
    const schedules: {
      schedules: ScheduleWithDefinedScheduleElements[];
    } = (clonedRoute.schedules?.schedules && {
      schedules: clonedRoute.schedules.schedules.filter(
        (s: {
          calculated?: {
            scheduleElements?: ScheduleElement[];
          };
          manual?: { scheduleElements?: ScheduleElement[] };
        }): s is ScheduleWithDefinedScheduleElements =>
          Boolean(s.calculated?.scheduleElements || s.manual?.scheduleElements)
      ),
    }) ?? { schedules: [] };
    return {
      ...clonedRoute,
      extensions: {
        ...clonedRoute.extensions,
        uuid,
        readonly: clonedRoute.extensions.readonly,
      },
      schedules,
    };
  } else {
    throw Error("Cannot save a route with no uuid");
  }
};

export function useSaveRoute() {
  const { RoutesApi } = useContext(CrystalGlobeApiContext);
  const { trackAnalyticsEvent } = useContext(AnalyticsContext);

  const saveRouteToServer = async (
    route: Route,
    routeUuid: string,
    voyageUuid: string,
    source: RouteSource
  ) => {
    const body: CreateRouteDto = {
      uuid: routeUuid,
      voyageUuid: voyageUuid,
      source,
      rtzRouteObject: getRouteForApi(route), // TODO: make frontend and backend use the same type for this
      canCreateChannels: false,
    };
    if (route.extensions?.canCreateChannels) {
      body.canCreateChannels = true;
      body.source = RouteSource.PathControl;
    }
    return RoutesApi.createRoute({ createRouteDto: body });
  };

  const onSaveSuccess = useCallback(
    (route: RoutesMutationResponseDto) => {
      trackAnalyticsEvent(AnalyticsEvent.SavedRoute, {
        routeUuid: route?.data?.created[0]?.uuid,
      });
    },
    [trackAnalyticsEvent]
  );

  const {
    mutateAsync: saveRoute,
    isLoading: saveRouteIsLoading,
    isError: saveRouteIsError,
    reset: resetSaveRoute,
  } = useMutation(
    ({
      route,
      voyageUuid,
      source,
    }: {
      route: Route;
      voyageUuid: string;
      source: RouteSource;
    }) => {
      return saveRouteToServer(
        route,
        route.extensions!.uuid!,
        voyageUuid,
        source
      );
    },
    {
      onSuccess: onSaveSuccess,
    }
  );

  return useMemo(
    () => ({
      saveRoute,
      saveRouteIsLoading,
      saveRouteIsError,
      resetSaveRoute,
    }),
    [resetSaveRoute, saveRoute, saveRouteIsError, saveRouteIsLoading]
  );
}

import {
  RouteDataLookups,
  RouteMetadata,
  TimestampedRouteData,
} from "contexts/RouteStoreContext/state-types";
import { debounce } from "lodash";
import { useContext, useEffect, useMemo } from "react";
import { RoutingControlsUiState } from "components/routes/Import/use-route-import";
import { useStableDeepComparedReference } from "shared-hooks/use-stable-deepcompared-reference";
import { RouteSummaryData } from "helpers/routeSummary";
import AnalyticsContext, { AnalyticsEvent } from "../Analytics";
import {
  GeometryType,
  GM_Point,
  Route,
  SimulatedRoute,
} from "../../shared-types/RouteTypes";
import { useContextualRouteProvider } from "./ContextualRouteContext";
import { RouteStoreContext, useRouteStoreDispatch } from ".";

const noop = () => null;

export type UseRouteHookResult = {
  voyageUuid?: string;
  route: Route | undefined;
  simulatedRoute: SimulatedRoute | undefined;
  routeSummaryData: RouteSummaryData | undefined;
  metadata:
    | Omit<
        RouteMetadata,
        "simulationSubscriberReferenceCount" | "subscriberReferenceCount"
      >
    | undefined;
  lookup: RouteDataLookups | undefined;
  guidanceParameters: RoutingControlsUiState | undefined;
  /** Callback to move a single waypoint */
  updateWaypointPosition: (waypointID: number, position: GM_Point) => void;

  /** Callback to update a single waypoint's speed*/
  updateWaypointSpeed: (waypointID: number, speedKts: number) => void;

  /** Callback to update a single waypoint's time */
  updateWaypointTimestamp: (waypointID: number, iso: string) => void;

  /** Callback to update a single waypoint's drift */
  updateWaypointDrifting: (
    waypointID: number,
    state: boolean,
    endTimestamp: string
  ) => void;

  /** Callback to add a waypoint to the specified route */
  addWaypoint: (
    previousWaypointID: number,
    nextWaypointID: number | undefined,
    position: GM_Point
  ) => void;

  /** Callback to remove a waypoint from the specified route */
  removeWaypoint: (waypointID: number) => void;

  updateWaypointGeometryType: (
    waypointID: number,
    geometryType: GeometryType
  ) => void;

  ContextualRouteProvider: React.FC<{}>;
  routeDescriptor?: string;
  timestampedRouteData?: TimestampedRouteData;
};

/** Hook used for getting and setting data on a particular stored `Route` and
 *  (optionally) a simulated version of that `Route` using simulated weather.
 */
const useRoute = (
  /** The Uuid of the route the consuming component cares about
   *
   * Note that this is an optional parameter, but you will only get meaningful
   * data back if this parameter is set. This may or may not be useful behavior,
   * but since you can't call this hook conditionally there might be cases
   * where we want to use it in a component that can partially render before
   * we have a concrete route Uuid.
   */
  routeUuid?: string,
  /** Set this flag to `true` if the consuming component cares about getting
   *  up-to-date simulated route data (including weather, ETAs, etc)
   */
  withSimulationData: boolean = false,
  /** Set this flag to `true` if you want to skip the schedule update when
   *  adding or removing waypoints.
   */
  skipScheduleUpdate: boolean = false
): UseRouteHookResult => {
  const dispatch = useRouteStoreDispatch();

  // Let the global store know we want to subscribe to this Route.
  // This will automatically lazy-load data for the route if needed.
  useEffect(() => {
    dispatch({
      type: "changeRouteSubscription",
      payload: {
        routeUuid,
        change: "subscribe",
      },
    });
    return () => {
      dispatch({
        type: "changeRouteSubscription",
        payload: {
          routeUuid,
          change: "unsubscribe",
        },
      });
    };
  }, [dispatch, routeUuid]);

  const { routes } = useContext(RouteStoreContext);
  const routeStoreObject = useMemo(() => {
    const result = routeUuid ? routes[routeUuid] : undefined;

    // FIXME separate the subscriber count metadata out
    // somewhere else so that this reference is stable

    // remove subscriber counts to prevent deepcompare changes
    let metadata;
    if (result?.metadata) {
      const {
        simulationSubscriberReferenceCount,
        subscriberReferenceCount,
        ...rest
      } = result.metadata;
      metadata = rest;
    }
    return (
      result && {
        ...result,
        metadata,
      }
    );
  }, [routeUuid, routes]);

  const stableRouteStoreObject = useStableDeepComparedReference(
    routeStoreObject // FIXME make this not needed
  );

  // We want to be able to track analytics events for certain actions
  // dispatched through this hook.
  const { trackAnalyticsEvent } = useContext(AnalyticsContext);

  // If the hook's args request simulator data, let our central store know
  // we care about the simulation result. The app should only run simulations
  // on routes where more than one mounted component is subscribed to simulator
  // results.
  useEffect(() => {
    if (withSimulationData) {
      dispatch({
        type: "changeSimulatedRouteSubscription",
        payload: {
          routeUuid,
          change: "subscribe",
        },
      });
      return () => {
        dispatch({
          type: "changeSimulatedRouteSubscription",
          payload: {
            routeUuid,
            change: "unsubscribe",
          },
        });
      };
    }
  }, [withSimulationData, routeUuid, dispatch]);

  const hasRouteData = stableRouteStoreObject && routeUuid;
  // Memoize callbacks so we can pass them around without unintended renders
  const callbacks = useMemo(() => {
    if (!hasRouteData) {
      // No Route data! Return a dummy callbacks
      return {
        updateWaypointPosition: noop,
        addWaypoint: noop,
        removeWaypoint: noop,
        updateWaypointGeometryType: noop,
        updateWaypointSpeed: noop,
        updateWaypointTimestamp: noop,
        updateWaypointDrifting: noop,
      };
    } else {
      // Ok, we have a real Route
      // Return Route data and dispatch functions "bound" to this Route
      return {
        updateWaypointPosition: (waypointID: number, position: GM_Point) => {
          dispatch({
            type: "moveWaypoint",
            payload: {
              waypointID,
              position,
              routeUuid,
            },
          });
          debounce(() => {
            trackAnalyticsEvent(AnalyticsEvent.WaypointModified, {
              route: routeUuid,
              waypointID: waypointID,
            });
          }, 500);
        },
        addWaypoint: (
          previousWaypointID: number,
          nextWaypointID: number | undefined,
          position: GM_Point
        ) =>
          dispatch({
            type: "addWaypoint",
            payload: {
              previousWaypointID,
              nextWaypointID,
              position,
              routeUuid,
              skipScheduleUpdate,
            },
          }),
        removeWaypoint: (waypointID: number) =>
          dispatch({
            type: "removeWaypoint",
            payload: {
              waypointID,
              routeUuid,
              skipScheduleUpdate,
            },
          }),
        updateWaypointGeometryType: (
          waypointID: number,
          geometryType: GeometryType
        ) => {
          dispatch({
            type: "updateWaypointGeometryType",
            payload: {
              waypointID,
              routeUuid,
              geometryType,
            },
          });
        },
        updateWaypointSpeed: (waypointID: number, speedKts: number) => {
          dispatch({
            type: "updateWaypointSpeed",
            payload: {
              waypointID,
              speedKts,
              routeUuid,
            },
          });
        },
        updateWaypointTimestamp: (waypointID: number, iso: string) => {
          dispatch({
            type: "updateWaypointTimestamp",
            payload: {
              waypointID,
              iso,
              routeUuid,
            },
          });
        },
        updateWaypointDrifting: (
          waypointID: number,
          state: boolean,
          endTimestamp: string
        ) => {
          dispatch({
            type: "updateWaypointDrifting",
            payload: {
              waypointID,
              state,
              routeUuid,
              endTimestamp,
            },
          });
        },
      };
    }
  }, [
    dispatch,
    hasRouteData,
    routeUuid,
    skipScheduleUpdate,
    trackAnalyticsEvent,
  ]);

  /** Get a pre-built context provider that injects the specified routeUuid
   *  into the "contextual" route context. When this provider is
   *  mounted, all its ancestors will have a reference to this route and voyage
   *  and can access that directly via the `useContextualRoute` hook.
   */
  const ContextualRouteProvider = useContextualRouteProvider(routeUuid);
  return useMemo(
    () => ({
      ...callbacks,
      voyageUuid: stableRouteStoreObject?.voyageUuid || undefined,
      route: stableRouteStoreObject?.data.route,
      guidanceParameters: stableRouteStoreObject?.data.routingControlsUiState,
      // Don't return simulated route data unless requested in the hook args
      simulatedRoute: withSimulationData
        ? stableRouteStoreObject?.data.simulatedRoute
        : undefined,
      routeSummaryData: stableRouteStoreObject?.data.routeSummaryData,
      metadata: stableRouteStoreObject?.metadata,
      lookup: stableRouteStoreObject?.data.lookup,
      routeDescriptor: stableRouteStoreObject?.data.routeDescriptor,
      timestampedRouteData: stableRouteStoreObject?.data.timestampedRouteData,
      ContextualRouteProvider,
    }),
    [
      ContextualRouteProvider,
      callbacks,
      stableRouteStoreObject,
      withSimulationData,
    ]
  );
};
export default useRoute;

import React, { useEffect, useState } from "react";
import logo from "bundle-data/images/Wayfinder-by-sofar-logo.png";
import { makeStyles } from "tss-react/mui";
import { extendedPalette } from "styles/theme";

export const useStyles = makeStyles()(() => ({
  fullscreen: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  message: {
    fontFamily: "sans-serif",
    fontSize: "1.5em",
    display: "block",
    marginTop: 40,
    color: "#555",
  },
  logo: {
    display: "block",
    maxWidth: "50vw",
  },
  note: {
    textAlign: "center",
    color: extendedPalette.neutralMedium,
    paddingTop: 20,
  },
}));
const space = "\u00a0";
const dots = [space + space + space, "." + space + space, ".." + space, "..."];
// Keep the initial bundle size small and don't use Material-UI here!
const FallbackLoadingScreen: React.FC<{
  message?: string;
  animate?: boolean;
}> = ({ message = "Loading", animate = true }) => {
  const [dotsIndex, setDotsIndex] = useState(0);
  const { classes } = useStyles();
  useEffect(() => {
    const interval = window.setInterval(() => {
      setDotsIndex((dotsIndex + 1) % 4);
    }, 500);
    return () => {
      window.clearInterval(interval);
    };
  });
  return (
    <div className={classes.fullscreen}>
      <img src={logo} className={classes.logo} alt="Sofar Wayfinder" />
      <div className={classes.message}>
        {message}
        {animate ? dots[dotsIndex] : ""}
      </div>
    </div>
  );
};
export default FallbackLoadingScreen;

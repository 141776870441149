import React, { ReactElement } from "react";
import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { WayfinderButton } from "DLS";
import {
  WayfinderDialog,
  WayfinderDialogTitle,
  WayfinderDialogContent,
  WayfinderDialogContentText,
  WayfinderDialogActions,
} from "DLS";

export type ErrorModalProps = {
  showTitle: boolean;
  showDetails: boolean;
  infoMessage: string | ReactElement;
  error: Error | undefined;
  canDismiss?: boolean;
  canReload?: boolean;
  dismissError?: () => void | undefined;
  reloadPage?: () => void | undefined;
};

const useStyles = makeStyles()((theme: Theme) => ({
  code: { whiteSpace: "pre-wrap", maxHeight: 300, overflowY: "auto" },
}));

/** Error modal component for displaying errors directly to the user.
 *
 *  Note: It might feel natural to add Sentry.io logging into this component but
 *  it seems like that typically results in errors being reported twice: once
 *  when they are thrown and/or logged with console.error and again when this
 *  component mounts. So don't worry about it here and just use `consoleAndSentryError()`
 *  if you want an error to be reported back to the mothership!
 */
export const ErrorModal: React.FC<ErrorModalProps> = ({
  showTitle,
  showDetails,
  infoMessage,
  error,
  canDismiss = true,
  canReload = true,
  dismissError = undefined,
  reloadPage = undefined,
}) => {
  const { classes } = useStyles();

  return (
    <WayfinderDialog open={!!error}>
      {showTitle && <WayfinderDialogTitle>Error</WayfinderDialogTitle>}
      <WayfinderDialogContent>
        <WayfinderDialogContentText>{infoMessage}</WayfinderDialogContentText>
        {showDetails && (
          <WayfinderDialogContentText variant="subtitle2">
            Details
            <div>
              <code className={classes.code}>{error?.message}</code>
            </div>
          </WayfinderDialogContentText>
        )}
      </WayfinderDialogContent>
      <WayfinderDialogActions>
        {canDismiss && (
          <WayfinderButton
            variant="tertiary"
            onClick={dismissError}
            color="primary"
            autoFocus
          >
            OK
          </WayfinderButton>
        )}
        {canReload && (
          <WayfinderButton
            variant="tertiary"
            onClick={reloadPage}
            color="primary"
            autoFocus
          >
            Restart Wayfinder
          </WayfinderButton>
        )}
      </WayfinderDialogActions>
    </WayfinderDialog>
  );
};

import React from "react";
import { Polygon, MultiPolygon } from "@turf/helpers";
import {
  StatefulForecastProvider,
  ForecastMetadata,
} from "@sofarocean/weather-cubes";
import AsyncWeatherProviderProxy from "web-workers/weather-worker/AsyncWeatherProviderProxy";
import { ForecastStatus } from "./use-weather-state";
import { useIceLayers } from "./use-ice-layers";

export type WeatherContextType = {
  getForecastValue: StatefulForecastProvider["getForecastValue"];
  getManyForecastValues: StatefulForecastProvider["getManyForecastValues"];
  calculateSimulatorWaypoints: AsyncWeatherProviderProxy["calculateSimulatorWaypoints"];
  buildWebGLBuffer: AsyncWeatherProviderProxy["buildWebGLBuffer"];
  generateTilesAsync: AsyncWeatherProviderProxy["generateTiles"];
  getRasterTile: StatefulForecastProvider["getRasterTile"];
  checkForForecastUpdates: () => void;
  downloadLatestForecast: () => void;
  setRegionOfInterest: (roi: Polygon | MultiPolygon | null) => void;
  calculateWeatherVariableExtremaLabels: AsyncWeatherProviderProxy["calculateWeatherVariableExtremaLabels"];
  currentForecastMetadata: ForecastMetadata;
  setWeatherFetch: (newFetch: typeof fetch | undefined) => void;
  reInitializeWeatherProvider: (
    newFetch: typeof fetch | undefined,
    newSimulatedHistoricalTimeString?: string | undefined
  ) => Promise<void>;
  forecastStatus: ForecastStatus;
  forecastComplete: boolean;
  iceLayersResult: ReturnType<typeof useIceLayers>;
  // we manage the pressure center ROI separately because calculating it is expensive.
  // sometimes we only need to show it on a subset of the map relative to the other weather that's being shown, which keeps things snappier
  pressureCenterRoiQuadkeys: string[] | null;
};

const WeatherContextDefaults: WeatherContextType = {
  getForecastValue: async () => undefined,
  calculateSimulatorWaypoints: async () => undefined,
  getManyForecastValues: async () => undefined,
  buildWebGLBuffer: async () => "NO_DATA",
  getRasterTile: async () => undefined,
  generateTilesAsync: async () => [],
  checkForForecastUpdates: () => null,
  downloadLatestForecast: () => null,
  setRegionOfInterest: (roi: Polygon | MultiPolygon | null) => null,
  calculateWeatherVariableExtremaLabels: async () => undefined,
  currentForecastMetadata: {
    downloadError: null,
    downloadStatus: "idle",
    downloadProgress: 0,
    weatherVariables: [],
    forecastUUID: "",
    initialized: false,
  },
  setWeatherFetch: (newFetch: typeof fetch | undefined) => {},
  reInitializeWeatherProvider: async (
    newFetch: typeof fetch | undefined,
    newSimulatedHistoricalTimeString: string | undefined
  ) => {},
  forecastStatus: "not-started",
  forecastComplete: false,
  iceLayersResult: {
    data: {
      arctic: {
        fill: {
          packIceZone: [],
          marginalIceZone: [],
        },
        line: {
          packIceZone: [],
          marginalIceZone: [],
        },
      },
      antarctic: {
        fill: {
          packIceZone: [],
          marginalIceZone: [],
        },
        line: {
          packIceZone: [],
          marginalIceZone: [],
        },
      },
    },
    isLoading: false,
  },
  pressureCenterRoiQuadkeys: null,
};

/** Provide one-way access to the weather data for consumers of this context
 *  for now. Eventually this should include methods that allow a consumer to
 *  request an update to the weather variables, region of interest, or to
 *  download new forecasts
 */
const WeatherContext = React.createContext<WeatherContextType>(
  WeatherContextDefaults
);

export default WeatherContext;

import { Chip, SxProps } from "@mui/material";
import React, { useMemo } from "react";
import { typographyStyles } from "styles/theme";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles<{ color?: string; hasContent?: boolean }>()(
  (theme, { color, hasContent }) => ({
    tag: {
      backgroundColor: color ?? "#000",
      color: "#fff",
      width: hasContent ? 96 : "auto",
      textAlign: "center",
      height: 20,
      ...typographyStyles.displaySmall,
    },
  })
);

export const RouteLabel = ({
  label,
  color,
  className,
  sx,
}: {
  label: string;
  color: string;
  className?: string;
  sx?: SxProps;
}) => {
  const tagStyleProps = useMemo(() => ({ color, hasContent: Boolean(label) }), [
    color,
    label,
  ]);
  const { classes: tagClasses } = useStyles(tagStyleProps);

  return (
    <Chip
      label={label.toUpperCase()}
      className={`${tagClasses.tag} ${className ?? ""}`}
      data-cy={`chip-${label.toLowerCase()}`}
      sx={sx}
    />
  );
};

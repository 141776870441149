import { useContext, useMemo } from "react";
import { useQuery } from "react-query";
import { getOrganizationsQueryKey } from "helpers/crystalGlobeApi";
import { CrystalGlobeApiContext } from "../../contexts/CrystalGlobeApiContext";

export function useOrganizations() {
  const { OrganizationsApi } = useContext(CrystalGlobeApiContext);

  const { data: orgs, error, isLoading, refetch } = useQuery(
    getOrganizationsQueryKey(),
    async () => {
      try {
        return await OrganizationsApi.getOrganizations();
      } catch (err) {
        console.error(
          "Unable to fetch organization data. Filtering disabled",
          err
        );
        return undefined;
      }
    },
    {
      retry: 3,
    }
  );

  return useMemo(
    () => ({ orgs: orgs?.data, error, isLoading, refresh: refetch }),
    [error, isLoading, orgs, refetch]
  );
}

import { RouteScoreOptions } from "../components/sidebar/RouteSummary/use-route-score-options";
import { CalculatedScheduleElement } from "../shared-types/RouteTypes";

export const options: RouteScoreOptions = {
  adverseCurrentMax: 0.8,
  adverseCurrentMin: 0.0,
  favorableCurrentMax: 0.8,
  favorableCurrentMin: 0,
  adverseWaveHeightMax: 4.5,
  adverseWaveHeightMin: 2.5,
  favorableWaveHeightMax: 1.5,
  favorableWindSpeedMax: 20,
  adverseWindSpeedMax: 45,
  adverseWindSpeedMin: 20,
  useAbsoluteLinearCurrentScores: false,
  absoluteCurrentScoreThreshold: 4,
  trivialComparisonBasisThreshold: 0.000005,
  maxRatioForReportingSmallerRouteScore: 0.75,
  minRatioForReportingLargerRouteScore: 1.5,
  shorterDistanceReportingMinimumNM: 1,
  earlierArrivalReportingMinimumHr: 1,
  lowerConsumptionReportingMinimumMT: 1,
};

export const safetyWarningElements = [
  {
    waypointId: 0,
    etd: "2020-01-21T00:00:33.488Z",
    windSpeed: 0.1,
    extensions: {
      parametricRollWarning: "high",
      significantWaveHeight: 0.1,
    },
  },
  {
    waypointId: 1,
    eta: "2020-01-21T01:00:33.488Z",
    windSpeed: 1,
    extensions: {
      parametricRollWarning: "high",
      significantWaveHeight: 1,
    },
  },
  {
    waypointId: 2,
    eta: "2020-01-22T02:00:33.488Z",
    windSpeed: 2,
    extensions: {
      parametricRollWarning: "low",
      significantWaveHeight: 2,
    },
  },
  {
    waypointId: 3,
    eta: "2020-01-22T03:00:33.488Z",
    windSpeed: 3,
    extensions: {
      parametricRollWarning: "high",
      significantWaveHeight: 3,
    },
  },
  {
    waypointId: 4,
    eta: "2020-01-22T04:00:33.488Z",
    windSpeed: 4,
    extensions: {
      parametricRollWarning: "none",
      significantWaveHeight: 4,
    },
  },
  {
    waypointId: 5,
    eta: "2020-01-24T05:00:33.488Z",
    windSpeed: 5,
    extensions: {
      parametricRollWarning: "high",
      significantWaveHeight: 5,
    },
  },
  {
    waypointId: 6,
    eta: "2020-01-24T06:00:33.488Z",
    windSpeed: 6,
    extensions: {
      parametricRollWarning: "high",
      significantWaveHeight: 6,
    },
  },
] as CalculatedScheduleElement[];

export const weatherStatisticsElements = [
  {
    waypointId: 0,
    etd: "2020-01-21T00:00:33.488Z",
    windSpeed: 0,
    windDirection: 0,
    extensions: {
      parametricRollWarning: "high",
      significantWaveHeight: 0,
      meanDirection: 0,
    },
  },
  {
    waypointId: 1,
    eta: "2020-01-21T01:00:33.488Z",
    etd: "2020-01-21T01:30:33.488Z",
    windSpeed: 1,
    windDirection: 10,
    extensions: {
      parametricRollWarning: "high",
      significantWaveHeight: 1,
      meanDirection: 11,
    },
  },
  {
    waypointId: 2,
    eta: "2020-01-22T02:00:33.488Z",
    etd: "2020-01-22T02:30:33.488Z",
    windSpeed: 2,
    windDirection: 20,
    extensions: {
      parametricRollWarning: "low",
      significantWaveHeight: 2,
      meanDirection: 22,
    },
  },
  {
    waypointId: 3,
    eta: "2020-01-22T03:00:33.488Z",
    etd: "2020-01-22T03:30:33.488Z",
    windSpeed: 3,
    windDirection: 30,
    extensions: {
      parametricRollWarning: "high",
      significantWaveHeight: 3,
      meanDirection: 33,
    },
  },
  {
    waypointId: 4,
    eta: "2020-01-22T04:00:33.488Z",
    etd: "2020-01-22T04:30:33.488Z",
    windSpeed: 4,
    windDirection: 40,
    extensions: {
      parametricRollWarning: "none",
      significantWaveHeight: 4,
      meanDirection: 44,
    },
  },
  {
    waypointId: 5,
    eta: "2020-01-24T05:00:33.488Z",
    etd: "2020-01-24T05:30:33.488Z",
    windSpeed: 5,
    windDirection: 50,
    extensions: {
      parametricRollWarning: "high",
      significantWaveHeight: 5,
      meanDirection: 55,
    },
  },
  {
    waypointId: 6,
    eta: "2020-01-24T06:00:33.488Z",
    windSpeed: 6,
    windDirection: 60,
    extensions: {
      parametricRollWarning: "high",
      significantWaveHeight: 6,
      meanDirection: 66,
    },
  },
] as CalculatedScheduleElement[];

export const dailyGuidanceElements = [
  {
    waypointId: 0,
    etd: "2020-01-21T00:00:33.488Z",
    windSpeed: 0,
    windDirection: 0,
    rpm: 0,
    speed: 0,
    fuel: 0,
  },
  {
    waypointId: 1,
    eta: "2020-01-21T01:00:33.488Z",
    windSpeed: 1,
    windDirection: 10,
    rpm: 1,
    speed: 1,
    fuel: 1000,
  },
  {
    waypointId: 2,
    eta: "2020-01-22T02:00:33.488Z",
    windSpeed: 2,
    windDirection: 20,
    rpm: 2,
    speed: 2,
    fuel: 2000,
  },
  {
    waypointId: 3,
    eta: "2020-01-22T03:00:33.488Z",
    windSpeed: 3,
    windDirection: 30,
    rpm: 3,
    speed: 3,
    fuel: 3000,
  },
  {
    waypointId: 4,
    eta: "2020-01-22T04:00:33.488Z",
    windSpeed: 4,
    windDirection: 40,
    rpm: 4,
    speed: 4,
    fuel: 4000,
  },
  {
    waypointId: 5,
    eta: "2020-01-24T05:00:33.488Z",
    windSpeed: 5,
    windDirection: 50,
    rpm: 5,
    speed: 5,
    fuel: 5000,
  },
  {
    waypointId: 6,
    eta: "2020-01-24T06:00:33.488Z",
    windSpeed: 6,
    windDirection: 60,
    rpm: 6,
    speed: 6,
    fuel: 6000,
  },
] as CalculatedScheduleElement[];

export const dailyGuidanceElementsWithDrift = [
  {
    waypointId: 0,
    etd: "2020-01-21T00:00:33.488Z",
    windSpeed: 0,
    windDirection: 0,
    rpm: 0,
    speed: 0,
    fuel: 0,
  },
  {
    waypointId: 1,
    eta: "2020-01-21T01:00:33.488Z",
    windSpeed: 1,
    windDirection: 10,
    rpm: 1,
    speed: 1,
    fuel: 1000,
  },
  {
    waypointId: 2,
    eta: "2020-01-22T02:00:33.488Z",
    windSpeed: 2,
    windDirection: 20,
    rpm: 2,
    speed: 2,
    fuel: 2000,
  },
  {
    waypointId: 3,
    eta: "2020-01-22T03:00:33.488Z",
    windSpeed: 3,
    windDirection: 30,
    rpm: 3,
    speed: 3,
    fuel: 3000,
    extensions: { drifting: "start" },
  },
  {
    waypointId: 4,
    eta: "2020-01-22T04:00:33.488Z",
    windSpeed: 4,
    windDirection: 40,
    rpm: 0,
    fuel: 0,
    speed: 0,
    extensions: { drifting: "end" },
  },
  {
    waypointId: 5,
    eta: "2020-01-24T05:00:33.488Z",
    windSpeed: 5,
    windDirection: 50,
    rpm: 5,
    speed: 5,
    fuel: 5000,
  },
  {
    waypointId: 6,
    eta: "2020-01-24T06:00:33.488Z",
    windSpeed: 6,
    windDirection: 60,
    rpm: 6,
    speed: 6,
    fuel: 6000,
  },
] as CalculatedScheduleElement[];

/**
 * Returns true if the current app user is an automated system, such as Playwright.
 *
 * This relies on `navigator.webdriver`. See https://developer.mozilla.org/en-US/docs/Web/API/Navigator/webdriver.
 *
 * @returns
 */
export function isAutomatedTestingSession() {
  return navigator.webdriver;
}

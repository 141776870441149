import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";

import logo from "bundle-data/images/Wayfinder-by-sofar-logo.png";

import AnalyticsContext, { AnalyticsEvent } from "contexts/Analytics";
import { AuthenticationContext } from "contexts/AuthenticationContext";
import { WayfinderTypography } from "DLS";
import { useVessel } from "shared-hooks/data-fetch-hooks/use-vessel";
import useVoyage from "shared-hooks/data-fetch-hooks/use-voyage";
import { useFleetViewVisibility } from "shared-hooks/visibility-hooks/use-fleet-view-visibility";
import { useWayfinderUrl } from "shared-hooks/use-wayfinder-url";
import FallbackLoadingScreen from "../FallbackLoadingScreen";

const SplashScreen: React.FC<{}> = () => {
  const { trackAnalyticsEvent } = useContext(AnalyticsContext);
  const history = useHistory();

  const { metadata, metadataIsLoading } = useContext(AuthenticationContext);

  const userMetadataVesselQuery = useVessel(metadata?.vesselUuid ?? "");
  const {
    vessel: userMetadataVessel,
    isLoading: userMetadataVesselIsLoading,
  } = userMetadataVesselQuery;
  const primaryVoyageUuid = userMetadataVessel?.primaryVoyageUuid;

  const {
    voyage: primaryVoyage,
    voyageIsLoading: primaryVoyageIsLoading,
  } = useVoyage(primaryVoyageUuid || undefined);

  const allowFleetView = useFleetViewVisibility();

  const { getUrl } = useWayfinderUrl();
  useEffect(() => {
    // FIXME move all redirects to a central location so there are no race conditions
    if (metadataIsLoading || primaryVoyageIsLoading) return;

    if (primaryVoyage) {
      if (!userMetadataVesselIsLoading && userMetadataVessel) {
        const redirectUrl = getUrl("voyage", {
          params: {
            voyageUuid: primaryVoyage.uuid,
            vesselUuid: userMetadataVessel.uuid,
          },
        });
        trackAnalyticsEvent(
          AnalyticsEvent.RedirectedToPrimaryVoyageFromSplash,
          {
            redirectVoyageUuid: primaryVoyage.uuid,
            voyageStatus: primaryVoyage.status,
            redirectUrl,
          }
        );
        if (redirectUrl) {
          history.replace(redirectUrl);
        }
      }
    } else if (allowFleetView) {
      history.replace("/fleet");
    }
  }, [
    history,
    metadataIsLoading,
    primaryVoyage,
    trackAnalyticsEvent,
    allowFleetView,
    userMetadataVessel,
    getUrl,
    userMetadataVesselIsLoading,
    primaryVoyageUuid,
    primaryVoyageIsLoading,
  ]);

  return metadataIsLoading || primaryVoyageIsLoading ? (
    <FallbackLoadingScreen />
  ) : (
    <Container>
      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        height="100vh"
        width="100%"
      >
        <Box marginBottom="1em">
          <img
            src={logo}
            alt={"Sofar Wayfinder"}
            style={{ maxWidth: "50vw" }}
          />
        </Box>

        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          marginTop="15px"
        >
          <WayfinderTypography
            variant="displayLarge"
            sx={{
              fontSize: 28,
            }}
          >
            Our Support Team will reach out shortly to help set up your first
            voyage.
          </WayfinderTypography>
        </Box>
      </Box>
    </Container>
  );
};

export default SplashScreen;

import React, { useMemo } from "react";
import { useEffect, useRef, useState } from "react";
import { useWayfinderUrl } from "shared-hooks/use-wayfinder-url";

export type NowContextType = {
  now: Date;
};

const NowContextDefaults: NowContextType = {
  now: new Date(),
};

export const NowContext = React.createContext<NowContextType>(
  NowContextDefaults
);

export const NowContextProvider: React.FC = ({ children }) => {
  const { simulatedHistoricalTime } = useWayfinderUrl();

  // advance the "NOW" line up to date every minute, unless the
  // `simulatedHistoricalTime` override is set. In that case, assume the
  // simulated time is "NOW"
  const [now, setNow] = useState(simulatedHistoricalTime ?? new Date());
  const nowInterval = useRef<number | null>();
  useEffect(() => {
    nowInterval.current = window.setInterval(
      () => setNow(simulatedHistoricalTime ?? new Date()),
      60 * 1000
    );
    return () => {
      if (nowInterval.current) window.clearInterval(nowInterval.current);
    };
  }, [nowInterval, setNow, now, simulatedHistoricalTime]);
  return (
    <NowContext.Provider
      value={useMemo(() => ({ now }), [now])}
      children={children}
    />
  );
};

import { consoleAndSentryError } from "helpers/error-logging";
import { Store, get, set } from "idb-keyval";
import { useMutation, useQuery, useQueryClient } from "react-query";

/*
 * Helper hook to get and set key-value pairs in IndexDB, making sure updates
 *  propogate immediately between any components that uses this hook.
 */
export function useLocalPersistentState<TValue = unknown>(
  key: string,
  store?: Store
) {
  const cacheKey = ["__persistent-value", { key }];
  const queryClient = useQueryClient();
  const {
    data,
    isError: isQueryError,
    isLoading: isQueryLoading,
  } = useQuery<TValue>(
    cacheKey,
    async () => (await get(key, store)) as TValue,
    { refetchOnWindowFocus: true }
  );

  const {
    mutateAsync,
    isLoading: isMutationLoading,
    isError: isMutationError,
  } = useMutation<void, unknown, TValue>(
    (value: TValue) => set(key, value, store),
    {
      onMutate: (newValue: TValue) => {
        // Cancel any in-progress queries for this value
        queryClient.cancelQueries(cacheKey);
        // Optimistically update cached value
        queryClient.setQueryData(cacheKey, () => newValue);
      },
      onError: (err) => {
        consoleAndSentryError("Couldn't write value to IndexDB: ", err);
        // Force re-fetching the cached value from the source
        queryClient.invalidateQueries(cacheKey);
      },
    }
  );

  return {
    value: data,
    setValue: mutateAsync,
    isLoading: isQueryLoading || isMutationLoading,
    isError: isQueryError || isMutationError,
  };
}

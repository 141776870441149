import React, { useContext } from "react";
import {
  RoutesReducerAction as RouteStoreReducerAction,
  useRouteStore,
} from "contexts/RouteStoreContext/use-route-store";
import { RouteStoreData } from "./state-types";

export type RouteStoreContextType = RouteStoreData;
export const RouteStoreContext = React.createContext<RouteStoreContextType>({
  routes: {},
  routeStartTimes: {},
  editorConfiguration: {
    manualScheduleUpdateStrategy: "maintain-average-speed",
    editorAverageSpeedKts: 20,
    editorRta: undefined,
    sidebarHelperText: "",
  },
});

// Provide a separate context for dispatch so components that only need dispatch
// will not be rendered again every time the state updates
export type RouteStoreDispatchContextType = {
  dispatch: React.Dispatch<RouteStoreReducerAction>;
  createRoute: ReturnType<typeof useRouteStore>["callbacks"]["createRoute"];
  updateRoute: ReturnType<typeof useRouteStore>["callbacks"]["updateRoute"];
  removeRoute: ReturnType<typeof useRouteStore>["callbacks"]["removeRoute"];
  updateEditorConfiguration: ReturnType<
    typeof useRouteStore
  >["callbacks"]["updateEditorConfiguration"];
};
const routeStoreDispatchDefaults = {
  dispatch: () => {},
  createRoute: () => {},
  updateRoute: () => {},
  removeRoute: () => {},
  updateEditorConfiguration: () => {},
};
export const RouteStoreDispatchContext = React.createContext<RouteStoreDispatchContextType>(
  routeStoreDispatchDefaults
);

export const useRouteStoreDispatch = () =>
  useContext(RouteStoreDispatchContext).dispatch;

import {
  devWayfinderBaseUrl,
  localWayfinderBaseUrl,
  productionWayfinderBaseUrl,
  stagingWayfinderBaseUrl,
} from "config";
import useAppSetting from "contexts/AppSettingsContext";

// the default wayfinder base url, depending on environment
export const defaultBase =
  process.env.NODE_ENV === "production"
    ? productionWayfinderBaseUrl
    : stagingWayfinderBaseUrl;

export const useWayfinderBaseUrl = () => {
  // base defined on the command line when compiling wayfinder
  const processBase = process.env.REACT_APP_CRYSTAL_GLOBE_BASE_URL;
  // base defined in /settings
  let { value: apiUrlOverride } = useAppSetting("apiUrlOverride");
  if (process.env.NODE_ENV !== "development") {
    apiUrlOverride = "";
  }
  // the override to use if there are any
  const overrideBase = apiUrlOverride || processBase;
  // the base to use
  const wayfinderBaseUrl = overrideBase || defaultBase;
  // whether there is an override defined
  const hasApiUrlOverride =
    Boolean(overrideBase) && overrideBase !== defaultBase;
  return { wayfinderBaseUrl, hasApiUrlOverride };
};

export const useIsProduction = (): boolean => {
  const { wayfinderBaseUrl } = useWayfinderBaseUrl();
  return wayfinderBaseUrl === productionWayfinderBaseUrl;
};

export const useIsStaging = (): boolean => {
  const { wayfinderBaseUrl } = useWayfinderBaseUrl();
  return wayfinderBaseUrl === stagingWayfinderBaseUrl;
};

export const useIsDev = (): boolean => {
  const { wayfinderBaseUrl } = useWayfinderBaseUrl();
  return wayfinderBaseUrl === devWayfinderBaseUrl;
};

export const useIsLocal = (): boolean => {
  const { wayfinderBaseUrl } = useWayfinderBaseUrl();
  return wayfinderBaseUrl === localWayfinderBaseUrl;
};

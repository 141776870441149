import {
  FleetSummaryConfigurationDto,
  FleetSummaryDto,
  FleetSummaryPaginatedResponseDto,
} from "@sofarocean/wayfinder-typescript-client";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import {
  InfiniteData,
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useInfiniteQuery,
  useQuery,
} from "react-query";

import { CrystalGlobeApiContext } from "contexts/CrystalGlobeApiContext";
import useAppSetting from "contexts/AppSettingsContext";
import { QUERY_KEY_STRINGS } from "shared-types";
import { useLDFlags } from "shared-hooks/use-ld-flags";
import { getFleetSummaryForVesselQueryKey } from "helpers/crystalGlobeApi";
import { useStableDeepComparedReference } from "shared-hooks/use-stable-deepcompared-reference";
import { DateTime } from "luxon";
import { useVessels } from "./use-vessel";

type RefetchFleetSummaryFunction = <TPageData>(
  options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
) => Promise<
  QueryObserverResult<InfiniteData<FleetSummaryPaginatedResponseDto>, unknown>
>;
const REFRESH_HEADERS_INIT = {
  headers: {
    "X-Refresh-Service-Worker": "true",
  },
};

export const useFleetSummary: (
  enabled: boolean
) => {
  data: FleetSummaryDto[];
  configuration: FleetSummaryConfigurationDto | undefined;
  isLoading: boolean | undefined;
  isFetching: boolean;
  isRefetching: boolean;
  refetch: RefetchFleetSummaryFunction | undefined;
  fetchingProgress: number | null;
  dataTimestamp: DateTime | null;
} = (enabled = true) => {
  const { FleetSummaryApi } = useContext(CrystalGlobeApiContext);
  const { value: showAllVessels } = useAppSetting("showAllVesselsInFleetView");

  const { charterPartyDailySummary, showCiiTable } = useLDFlags();
  const isStale = useRef<boolean | null>(null);
  // the service-worker will not serve cached data in response to requests with the refresh header
  // so we leave the header out of the initial load
  const init = useRef<RequestInit | undefined>(undefined);
  const pageSize = 50;
  // what's up with all of these useRefs?
  // there are two reasons we use them here
  // 1. we do not need react to re-render when they are set because they are just used in by fetch logic
  // 2. getNextPageParam() is called on every render, so updating state from there creates an infinite loop. useRef's avoid that
  const totalPageCount = useRef<number | null>(null);
  const fetchedPageCount = useRef<number | null>(null);
  const firstLoadComplete = useRef(false);
  const [fetchingProgress, setFetchingProgress] = useState<null | number>(null);
  const refetchRef = useRef<RefetchFleetSummaryFunction | undefined>();
  const [dataTimestamp, setDataTimestamp] = useState<DateTime | null>(null);

  // use the vessel api to determine the page count before the fleet data loads
  // so that we can show a progrerss bar
  const { vessels, vesselsAreLoading } = useVessels(true);
  const vesselCount = vessels.filter((v) => v.showInFleetView).length;
  useEffect(() => {
    if (!vesselsAreLoading) {
      totalPageCount.current = Math.ceil(vesselCount / pageSize);
      if (fetchedPageCount.current) {
        setFetchingProgress(
          (100 * fetchedPageCount.current) / totalPageCount.current
        );
      }
    }
  }, [vesselCount, vesselsAreLoading]);

  const {
    data: fleetData,
    isLoading,
    isFetching,
    isRefetching,
    hasNextPage: canFetchMore,
    fetchNextPage: fetchMore,
    refetch,
  } = useInfiniteQuery(
    QUERY_KEY_STRINGS.FLEET_SUMMARY,
    async ({
      pageParam,
    }: {
      pageParam?: string;
    }): Promise<FleetSummaryPaginatedResponseDto> => {
      if (!pageParam) {
        fetchedPageCount.current = 0;
        setFetchingProgress(0);
      }
      const response = await FleetSummaryApi.getFleetSummaryRaw(
        {
          pageSize,
          cursor: pageParam,
          onlyFleetViewable: !showAllVessels,
          includeCpDailySummary: Boolean(charterPartyDailySummary),
          includeCiiYtdMetrics: showCiiTable,
        },
        init.current
      );
      if (response.raw.headers.get("X-Service-Worker")) {
        // Keep track of whether the data we get was cached by the service worker
        isStale.current = true;
      }

      const receivedDate = response.raw.headers.get("X-Date-Received");

      setDataTimestamp(
        DateTime.fromJSDate(receivedDate ? new Date(receivedDate) : new Date())
      );

      fetchedPageCount.current = fetchedPageCount.current
        ? fetchedPageCount.current + 1
        : 1;

      totalPageCount.current =
        totalPageCount.current &&
        Math.max(fetchedPageCount.current, totalPageCount.current);

      const progress =
        totalPageCount.current === null
          ? null
          : Math.min(
              fetchedPageCount.current
                ? fetchedPageCount.current / totalPageCount.current
                : 0,
              1
            ) * 100;
      if (fetchingProgress !== progress) {
        setFetchingProgress(progress);
      }

      return response.value();
    },
    {
      retry: 3,
      // this gets called for the latest page loaded, but when refetching
      // it is called both for the latest refetch and repeatedly for the last page of the previous fetch
      getNextPageParam: function (lastPage, allPages) {
        // we can tell we have loaded the last page by checking the metadata
        // after the initial load, we want to update the total page count and check if we loaded stale data
        if (!firstLoadComplete.current && !lastPage.metadata.hasNextPage) {
          firstLoadComplete.current = true;
          // update the total page count, which is used to track progress while loading
          totalPageCount.current = allPages.length;
          // from now on, always get fresh data
          init.current = REFRESH_HEADERS_INIT;
          if (isStale.current) {
            // if we loaded stale data from the service worker, immediately fetch fresh data
            isStale.current = false;
            refetchRef.current?.();
          }
        }
        return lastPage.metadata.hasNextPage
          ? lastPage.metadata.nextCursor
          : undefined;
      },
      refetchInterval: false,
      enabled,
    }
  );

  refetchRef.current = refetch;

  useEffect(() => {
    if (canFetchMore && !isFetching) {
      fetchMore();
    }
  }, [canFetchMore, fetchMore, isFetching]);

  return useMemo(() => {
    const data = fleetData
      ? fleetData.pages
          ?.flatMap((d) => d.data)
          .filter((p: FleetSummaryDto | undefined): p is FleetSummaryDto =>
            Boolean(p)
          )
      : [];
    const configurations = fleetData?.pages?.map(
      (dto) => dto.metadata.fleetSummaryConfiguration
    );
    const configuration = configurations?.reduce((prev, cur) => ({
      ...prev,
      goodWeatherConfigs: {
        ...prev.goodWeatherConfigs,
        ...cur.goodWeatherConfigs,
      },
    }));
    return {
      data,
      configuration,
      isLoading: isLoading || canFetchMore,
      isFetching: isFetching,
      isRefetching,
      refetch,
      fetchingProgress,
      dataTimestamp,
    };
  }, [
    fleetData,
    isLoading,
    canFetchMore,
    isFetching,
    isRefetching,
    refetch,
    fetchingProgress,
    dataTimestamp,
  ]);
};

export const useFleetSummaryForVessel: (
  vesselUuid: string,
  enabled: boolean
) => {
  data?: FleetSummaryDto;
  configuration?: FleetSummaryConfigurationDto;
  isLoading: boolean | undefined;
} = (vesselUuid, enabled) => {
  const { FleetSummaryApi } = useContext(CrystalGlobeApiContext);

  const { data: fleetSummaryForVessel, isLoading } = useQuery(
    getFleetSummaryForVesselQueryKey(vesselUuid),
    async () => {
      if (vesselUuid) {
        return await FleetSummaryApi.getFleetSummaryForVessel({ vesselUuid });
      } else {
        return undefined;
      }
    },
    {
      retry: 3,
      enabled,
    }
  );
  const stableData = useStableDeepComparedReference(fleetSummaryForVessel);
  return useMemo(
    () => ({
      data: stableData,
      configuration: stableData?._configuration,
      isLoading,
    }),
    [stableData, isLoading]
  );
};

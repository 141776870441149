import React, { useContext } from "react";
import { getHumanReadableLmtTimezone, getLmtTimezone } from "helpers/routes";
import { useLatestOrProjectedVesselPosition } from "shared-hooks/data-fetch-hooks/use-current-vessel-position";
import { useCurrentVoyageLeg } from "components/WayfinderApp/CurrentSession/contexts";
import { DateTime } from "luxon";
import { NowContext } from "contexts/Now";

export type WayfinderTimeProps = {
  date: Date | string | undefined;
  variant:
    | "time"
    | "date"
    | "date-time"
    | "date-year"
    | "date-year-time"
    | "gmt-offset-only";
  showMinutes?: boolean;
  showTimezone?: boolean;
  showInUtc?: boolean;
};

export const WayfinderTime: React.FC<WayfinderTimeProps> = ({
  date,
  variant,
  showMinutes,
  showTimezone,
  showInUtc,
}) => {
  const { now } = useContext(NowContext);
  const voyageUuid = useCurrentVoyageLeg().voyage?.uuid;
  const vesselLongitude = useLatestOrProjectedVesselPosition(voyageUuid, now)
    .lon;
  const lmtTimezone = vesselLongitude ? getLmtTimezone(vesselLongitude) : "";

  const options = {
    showTimezone:
      showTimezone !== undefined
        ? showTimezone
        : variant === "date" ||
          variant === "date-time" ||
          variant === "date-year-time" ||
          variant === "date-year",
    showTime:
      variant === "time" ||
      variant === "date-time" ||
      variant === "date-year-time",
    showDate:
      variant === "date" ||
      variant === "date-time" ||
      variant === "date-year" ||
      variant === "date-year-time",
    showYear: variant === "date-year" || variant === "date-year-time",
    showMinutes,
  };

  if (variant === "gmt-offset-only") {
    return <>{getHumanReadableLmtTimezone(lmtTimezone)}</>;
  }

  if (date) {
    const timezone = showInUtc ? "Z" : " LMT";
    const timezoneLabel =
      options?.showTimezone === undefined || options.showTimezone
        ? timezone
        : "";

    const time = DateTime.fromJSDate(new Date(date));
    const utcTime = time.toUTC();
    const lmtTime = time.setZone(lmtTimezone);
    let format = "";
    if (options.showDate) {
      format += "MMM dd";
      if (options.showTime || options.showYear) {
        format += ", ";
      }
    }
    if (options.showYear) {
      format += "yyyy";
      if (options.showTime) {
        format += ", ";
      }
    }
    if (options.showTime) {
      format += "HH";
      if (options.showMinutes || options.showMinutes === undefined) {
        format += ":mm";
      }
    }
    const formattedDate = showInUtc
      ? utcTime.toFormat(format)
      : lmtTime.toFormat(format);

    return (
      <span title={utcTime.toISO() ?? undefined}>
        {formattedDate + timezoneLabel}
      </span>
    );
  } else {
    return <></>;
  }
};

export default WayfinderTime;

import { makeStyles } from "tss-react/mui";
import { extendedPalette, typographyStyles } from "styles/theme";
import { BUTTON_STYLES } from "styles/component-variables";

const actionRowPaddingX = 24 - BUTTON_STYLES.BUTTON_LABEL_PADDING_X;
const actionRowPaddingBottom = 24 - BUTTON_STYLES.BUTTON_PADDING_Y;

export const useWayfinderDialogClasses = makeStyles()(() => ({
  unroundedBorders: {
    borderRadius: 0,
  },
}));

export const useWayfinderDialogTitleClasses = makeStyles()(() => ({
  root: {
    ...typographyStyles.header,
    paddingTop: "20px",
    paddingBottom: 0,
  },
}));

export const useWayfinderDialogContentClasses = makeStyles()(() => ({
  root: {
    paddingTop: "10px",
    paddingBottom: 0,
    marginBottom: 0,
  },
}));

export const useWayfinderDialogTextClasses = makeStyles()(() => ({
  root: {
    ...typographyStyles.body,
    marginBottom: 40 - BUTTON_STYLES.BUTTON_PADDING_Y,
    color: extendedPalette.surface,
  },
}));

export const useWayfinderDialogActionsClasses = makeStyles()(() => ({
  root: {
    display: "flex",
    padding: `0 ${actionRowPaddingX}px  ${actionRowPaddingBottom}px ${actionRowPaddingX}px`,
  },
}));

import { useQuery } from "react-query";
import { useCallback, useContext, useMemo } from "react";
import config from "../../config";
import { CrystalGlobeApiContext } from "../../contexts/CrystalGlobeApiContext";
import { getLatestRouteSuggestionQueryKey } from "../../helpers/crystalGlobeApi";

export const useRouteSuggestion = (voyageUuid: string | undefined) => {
  const { VoyagesApi } = useContext(CrystalGlobeApiContext);

  const {
    data: { latestRouteSuggestion } = {},
    remove,
    refetch,
    isLoading: suggestedRouteIsLoading,
  } = useQuery(
    getLatestRouteSuggestionQueryKey(voyageUuid),
    async () =>
      await VoyagesApi.getLatestRouteSuggestionForVoyage({
        voyageUuid: voyageUuid!,
      }),
    {
      retry: 2,
      refetchInterval: config.routePollIntervalMs,
      enabled: Boolean(voyageUuid),
      useErrorBoundary: false,
      suspense: false,
    }
  );

  const forceRefresh = useCallback(() => {
    remove();
    return refetch();
  }, [remove, refetch]);

  return useMemo(
    () => ({
      suggestedRouteUuid: latestRouteSuggestion?.routeUuid,
      suggestedRoute: latestRouteSuggestion,
      forceRefresh,
      suggestedRouteIsLoading,
    }),
    [latestRouteSuggestion, forceRefresh, suggestedRouteIsLoading]
  );
};

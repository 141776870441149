import { AuthenticationContext } from "contexts/AuthenticationContext";
import { useContext, useMemo } from "react";
import { useLDFlags } from "shared-hooks/use-ld-flags";

export const useWayfinderSettingsVisibility = () => {
  const { featureIsAllowed } = useContext(AuthenticationContext);
  const enableSettingsPage = featureIsAllowed("settings");
  const { showSettingsPage } = useLDFlags();
  return useMemo(() => enableSettingsPage && showSettingsPage, [
    enableSettingsPage,
    showSettingsPage,
  ]);
};

import { useContext, useMemo } from "react";
import { useRouteMatch } from "react-router-dom";
import { AuthenticationContext } from "../../contexts/AuthenticationContext";
import {
  GLOBAL_AREA_CONSTRAINT_SETTINGS_PATH,
  ORGANIZATION_AREA_CONSTRAINT_SETTINGS_PATH,
} from "../use-wayfinder-url";

/*
 * Helper hook to determine if the current user is allowed to view global area
 * constraint settings
 */
export const useGlobalAreaConstraintVisibility = () => {
  const { featureIsAllowed } = useContext(AuthenticationContext);
  const enableAreaConstraints = featureIsAllowed("globalAreaConstraints");
  return useMemo(() => enableAreaConstraints, [enableAreaConstraints]);
};

/*
 * Helper hook to determine if the current user is allowed to view organization-
 * specific area constraint settings
 *
 * NOTE: Any user with permissions to view global area constraints (aka admins)
 * will also be able to view organization-specific area constraints
 */
export const useOrganizationAreaConstraintVisibility = () => {
  const { featureIsAllowed } = useContext(AuthenticationContext);
  const enableAreaConstraints = featureIsAllowed("globalAreaConstraints");
  const enableOrgAreaConstraints = featureIsAllowed(
    "organizationAreaConstraints"
  );
  return useMemo(() => enableAreaConstraints || enableOrgAreaConstraints, [
    enableAreaConstraints,
    enableOrgAreaConstraints,
  ]);
};

/*
 * Helper hook to determine if the current user is allowed to view voyage-specific
 * area constraint settings
 *
 * NOTE: Any user with permissions to view global or organization-specific area
 * constraints will also be able to view voyage-specific area constraints
 */
export const useVoyageAreaConstraintVisibility = () => {
  const { featureIsAllowed } = useContext(AuthenticationContext);
  const enableAreaConstraints = featureIsAllowed("globalAreaConstraints");
  const enableOrgAreaConstraints = featureIsAllowed(
    "organizationAreaConstraints"
  );
  const enableVoyageAreaConstraints = featureIsAllowed("voyageAreaConstraints");
  return useMemo(
    () =>
      enableAreaConstraints ||
      enableOrgAreaConstraints ||
      enableVoyageAreaConstraints,
    [
      enableAreaConstraints,
      enableOrgAreaConstraints,
      enableVoyageAreaConstraints,
    ]
  );
};

/*
 * Helper hook to determine if the current user is permitted 'strict' or non strict
 * area constraint editing. If strict, the user will not be permitted to modify geometries
 * of area constraints, nor will they be able to create or delete constraints
 */
export const useStrictAreaConstraints = () => {
  const { featureIsAllowed } = useContext(AuthenticationContext);
  const enableAreaConstraints = featureIsAllowed("globalAreaConstraints");
  return useMemo(
    () => ({
      strict: !enableAreaConstraints,
    }),
    [enableAreaConstraints]
  );
};

/*
 * Helper hook to determine if the current page is the global area constraint settings page,
 * which causes the global ACs to render on the map & controls a number of UI tweaks to the
 * shared AreaConstraint components
 */
export const useIsGlobalAreaConstraintSettingsPage = () => {
  return !!useRouteMatch({
    path: GLOBAL_AREA_CONSTRAINT_SETTINGS_PATH,
    exact: true,
  });
};

/*
 * Helper hook to determine if the current page is the organization area constraint settings page,
 * which causes the organization ACs for the chosen orgId to render on the map & controls a number
 * of UI tweaks to the shared AreaConstraint components
 */
export const useIsOrganizationAreaConstraintSettingsPage = () => {
  return !!useRouteMatch({
    path: ORGANIZATION_AREA_CONSTRAINT_SETTINGS_PATH,
    exact: true,
  });
};

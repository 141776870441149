import { makeStyles } from "tss-react/mui";
import { VoyageStatusV2 } from "@sofarocean/wayfinder-typescript-client";
import React, { FC } from "react";
import { extendedPalette } from "styles/theme";
import { Box } from "@mui/material";

export type WayfinderVoyageStatusDotProps = {
  voyageStatus: VoyageStatusV2;
};

const statusToBackgroundColor = (status: VoyageStatusV2) => {
  switch (status) {
    case VoyageStatusV2.RoutingUnderway:
    case VoyageStatusV2.StandbyUnderway:
      return extendedPalette.voyageStates.inProgress;
    case VoyageStatusV2.PendingData:
    case VoyageStatusV2.StandbyForArrival:
    case VoyageStatusV2.StandbyForDeparture:
      return extendedPalette.voyageStates.planning;
    case VoyageStatusV2.Arrived:
    case VoyageStatusV2.Archived:
      return extendedPalette.voyageStates.completed;
  }
};

export const WayfinderVoyageStatusDot: FC<WayfinderVoyageStatusDotProps> = ({
  voyageStatus,
}) => {
  return (
    <Box
      component="span"
      sx={{
        minHeight: "0.5rem",
        minWidth: "0.5rem",
        borderRadius: "50%",
        marginRight: "0.5rem",
        display: "inline-block",
        backgroundColor: statusToBackgroundColor(voyageStatus),
      }}
    />
  );
};

import React from "react";
import { LogoutOptions, RedirectLoginOptions } from "@auth0/auth0-react";
import { User } from "@auth0/auth0-react";
import { CurrentUserDto } from "@sofarocean/wayfinder-typescript-client";
import { FeatureName } from "./permissions";

export type SofarUserRoles = (
  | "Captain"
  | "Sofar Wayfinder Admin"
  | "Operator"
)[];
export type UserMetadata = {
  // sofar
  accountType: "single" | "shared";
  vesselUuid: null | string;
  organization?: {
    display_name?: string;
    id?: string;
    name?: string;
  };
  organizationDisplayName?: string;
  organizationId?: string;
  organizationName?: string;
  organizationRoles?: SofarUserRoles;

  // auth0
  roles?: SofarUserRoles;
  email?: string;
  email_verified?: boolean;
  name?: string;
  nickname?: string;
  picture?: string;
  sub?: string;
  updated_at?: string;
};

export type AuthenticationContextType = {
  token: string | undefined;
  loginWithRedirect: (options?: RedirectLoginOptions) => Promise<void>;
  logoutWithRedirect: (options?: LogoutOptions) => void;
  authenticationError: Error | undefined;
  isAuthenticated: boolean;
  authenticationIsLoading: boolean;
  user: User | undefined;
  wayfinderApiCurrentUser: CurrentUserDto | undefined;
  metadata: UserMetadata | undefined;
  metadataIsLoading: boolean;
  auth0IsLoading: boolean;
  authorizationHeaders: { Authorization: string };
  featureIsAllowed: (featureName: FeatureName) => boolean;
};

export const AuthenticationContextDefaults: AuthenticationContextType = {
  token: undefined,
  loginWithRedirect: () => Promise.resolve(),
  logoutWithRedirect: () => {},
  authenticationError: undefined,
  isAuthenticated: false,
  authenticationIsLoading: false,
  user: undefined,
  wayfinderApiCurrentUser: undefined,
  metadata: undefined,
  metadataIsLoading: false,
  auth0IsLoading: false,
  authorizationHeaders: { Authorization: `Bearer undefined` },
  featureIsAllowed: (featureName: FeatureName) => false,
};
export const AuthenticationContext = React.createContext<AuthenticationContextType>(
  AuthenticationContextDefaults
);

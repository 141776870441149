import { useMemo, useContext, useCallback } from "react";
import { useQuery } from "react-query";
import {
  VoyageDto,
  VoyageStatusV2,
} from "@sofarocean/wayfinder-typescript-client";
import { CrystalGlobeApiContext } from "../../contexts/CrystalGlobeApiContext";
import useAppSetting from "../../contexts/AppSettingsContext";
import { useStableDeepComparedReference } from "../use-stable-deepcompared-reference";
import { getVoyageQueryKey } from "../../helpers/crystalGlobeApi";
import config from "../../config";

type UseVoyageHookResult = {
  voyageIsLoading: boolean;
  voyage: VoyageDto | undefined;
  forceRefresh: () => void;
};

/** Hook that lets a component get and set stored values related to a given
 *  `Voyage`. With no `voyageUuid`, this hook can be used to get a
 *  `createVoyage()` callback and bootstrap a brand new `Voyage`.
 */
const useVoyage = (
  voyageUuid?: string,
  options?: { allowErrors: boolean }
): UseVoyageHookResult => {
  const { VoyagesApi } = useContext(CrystalGlobeApiContext);

  // useQuery is returning a different reference on two sequential runs, even when the
  // voyage data has not actually changed. Could not find any docs that said why.
  // Below we use a hook to make the reference stable, so that the rest of the app
  // does not get a bunch of meaningless reference changes.
  const {
    data: unstableVoyageReference,
    isLoading: voyageIsLoading,
    remove,
    refetch,
  } = useQuery(
    getVoyageQueryKey(voyageUuid),
    async () => {
      try {
        if (voyageUuid) {
          return await VoyagesApi.getVoyage({ voyageUuid, withDeleted: false });
        } else {
          return undefined;
        }
      } catch (e) {
        // This allows us to catch errors so they don't bubble all the way up to the globel error
        // context. It's helpful for allowing callers to just throw away errors that might be
        // expected but don't functionally matter.
        if (options?.allowErrors) return undefined;

        throw e;
      }
    },
    {
      retry: 3,
      refetchInterval: config.routePollIntervalMs,
      enabled: Boolean(voyageUuid),
    }
  );
  const forceRefresh = useCallback(() => {
    remove();
    return refetch();
  }, [remove, refetch]);

  const voyage = useStableDeepComparedReference(unstableVoyageReference);

  // Allow voyage status override for testing
  const { value: voyageStatusOverride } = useAppSetting("voyageStatusOverride");
  const { value: activeRouteUuidOverride } = useAppSetting(
    "activeRouteUuidOverride"
  );
  const voyageWithOverrides = useMemo(() => {
    if (voyageStatusOverride && voyage) {
      return {
        ...voyage,
        ...(activeRouteUuidOverride && voyage.activeRoute
          ? {
              activeRouteUuid: activeRouteUuidOverride,
              activeRoute: {
                ...voyage.activeRoute,
                routeUuid: activeRouteUuidOverride,
              },
            }
          : undefined),
        statusV2: voyageStatusOverride as VoyageStatusV2,
      };
    }
    return voyage;
  }, [activeRouteUuidOverride, voyage, voyageStatusOverride]);

  return useMemo(
    () => ({
      forceRefresh,
      voyageIsLoading,
      voyage: voyageWithOverrides,
    }),
    [forceRefresh, voyageWithOverrides, voyageIsLoading]
  );
};

export default useVoyage;

import React from "react";
import ReactFileReader from "react-file-reader";

import { WayfinderButton, WayfinderButtonProps } from "./WayfinderButton";

type FileImportButtonProps = {
  fileTypes: [string, string][][] | string[];
  onFileUpload: (files: File[]) => Promise<void>;
  buttonText: string;
} & WayfinderButtonProps;

export const FileImportButton: React.FC<FileImportButtonProps> = ({
  fileTypes,
  onFileUpload,
  buttonText,
  variant,
  disabled,
}) => (
  <div data-cy="file-reader">
    <ReactFileReader
      fileTypes={fileTypes}
      base64={false}
      multipleFiles={false}
      handleFiles={onFileUpload}
    >
      <WayfinderButton variant={variant} disabled={disabled}>
        {buttonText}
      </WayfinderButton>
    </ReactFileReader>
  </div>
);

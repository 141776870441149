export const featurePermissions = {
  optimizationSidebarEconomicConstraints: "app-feature:optimization-sidebar-economic-constraints" as const,
  fleetView: "app-feature:fleet-view" as const,
  globalAreaConstraints: "app-feature:area-constraints" as const,
  organizationAreaConstraints: "app-feature:organization-area-constraints" as const,
  voyageAreaConstraints: "app-feature:voyage-area-constraints" as const,
  voyageCreation: "app-feature:voyage-creation" as const,
  viewEconomicComparison: "app-feature:economic-comparison" as const,
  rollAngle: "app-feature:roll-angle" as const,
  pitchAngle: "app-feature:pitch-angle" as const,
  updateVoyageConstraints: "app-feature:update-voyage-constraints" as const,
  vesselDetailsPage: "app-feature:vessel-details-page" as const,
  importMarketFactorCsv: "app-feature:import-market-factor-csv" as const,
  fleetViewOrgFilter: "app-feature:fleet-view-organization-filter" as const,
  routeRequests: "app-feature:route-requests" as const,
  globalWeatherInVoyageScreen: "app-feature:global-weather-in-voyage-screen",
  settings: "app-feature:settings" as const,
} as const;

export type FeatureName = keyof typeof featurePermissions;

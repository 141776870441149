import { makeStyles } from "tss-react/mui";
import { VoyageStatusV2 } from "@sofarocean/wayfinder-typescript-client";
import { WayfinderVoyageStatusDot } from "DLS/wayfinder-voyage-status-dot/WayfinderVoyageStatusDot";
import React, { useMemo } from "react";
import { extendedPalette, typographyStyles } from "../../styles/theme";

export type VoyageStateProps = {
  status?: VoyageStatusV2;
  dot?: boolean;
  extraClass?: string;
};

const useStyles = makeStyles()(() => ({
  container: {
    ...typographyStyles.buttonSmall,
    whiteSpace: "nowrap",
    "&.inProgress": {
      color: extendedPalette.voyageStates.inProgress,
    },
    "&.planning": {
      color: extendedPalette.voyageStates.planning,
    },
    "&.completed": {
      color: extendedPalette.voyageStates.completed,
    },
  },
}));

export const VoyageState: React.FC<VoyageStateProps> = ({
  status,
  dot,
  extraClass,
}) => {
  const { classes: styles } = useStyles();

  const [label, labelClass] = useMemo(() => {
    switch (status) {
      case VoyageStatusV2.RoutingUnderway:
      case VoyageStatusV2.StandbyUnderway:
        return ["In Progress", "inProgress"];
      case VoyageStatusV2.PendingData:
      case VoyageStatusV2.StandbyForDeparture:
      case VoyageStatusV2.StandbyForArrival:
        return ["Planning", "planning"];
      case VoyageStatusV2.Arrived:
      case VoyageStatusV2.Archived:
        return ["Completed", "completed"];
    }
    return [`Invalid Status: "${status} ${extraClass}"`, "error"];
  }, [extraClass, status]);

  return (
    <span className={`${styles.container} ${labelClass} ${extraClass}`}>
      {dot && status && <WayfinderVoyageStatusDot voyageStatus={status} />}
      {label}
    </span>
  );
};

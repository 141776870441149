import { useMutation, useQueryClient } from "react-query";
import { useCallback, useContext, useMemo, useState } from "react";
import {
  ActiveRouteDto,
  ActiveRoutesMutationResponseDtoData,
  CreateRouteDto,
  RouteSource,
} from "@sofarocean/wayfinder-typescript-client";
import { Route } from "shared-types/RouteTypes";
import useAppSetting from "contexts/AppSettingsContext";
import { CrystalGlobeApiContext } from "../contexts/CrystalGlobeApiContext/index";
import { synchronizeQueryCache } from "../helpers/crystalGlobeApi";
import AnalyticsContext, { AnalyticsEvent } from "../contexts/Analytics";
import { useImportedRouteUuids } from "../components/routes/Import/use-imported-route-uuids";
import useVoyage from "./data-fetch-hooks/use-voyage";
import { getRouteForApi } from "./use-route-save";

type BaseDto = object & { __type: string };
const getFirstActiveRoute = (dataCreated: BaseDto[] | undefined) =>
  dataCreated?.find(
    (c: ActiveRouteDto | BaseDto): c is ActiveRouteDto =>
      c.__type === "ActiveRoute"
  );

export function useActiveRouteUuidOverride() {
  let { value: activeRouteUuidOverride } = useAppSetting(
    "activeRouteUuidOverride"
  );
  // do not allow this override outside of a dev environment
  if (process.env.NODE_ENV !== "development") {
    activeRouteUuidOverride = "";
  }
  return activeRouteUuidOverride;
}

export const useActiveRoute = (voyageUuid?: string) => {
  const { ActiveRoutesApi } = useContext(CrystalGlobeApiContext);
  const { trackAnalyticsEvent } = useContext(AnalyticsContext);
  const [pendingActiveRoute, setPendingActiveRoute] = useState<
    string | undefined
  >();
  const {
    voyage,
    forceRefresh,
    voyageIsLoading: activeRouteIsLoading,
  } = useVoyage(voyageUuid);

  const queryClient = useQueryClient();

  const activeRouteUuidOverride = useActiveRouteUuidOverride();
  const activeRouteUuid =
    activeRouteUuidOverride || voyage?.activeRoute?.routeUuid;

  const importedRouteUuids = useImportedRouteUuids();

  const onSetRouteActiveSuccess = useCallback(
    (result: ActiveRoutesMutationResponseDtoData) => {
      const activeUuid =
        result?.created && result.created.length
          ? getFirstActiveRoute(result.created as BaseDto[])?.routeUuid
          : "unknown";
      trackAnalyticsEvent(AnalyticsEvent.MadeRouteActive, {
        routeUuid: activeUuid,
        voyageUuid,
      });
      if (activeUuid && importedRouteUuids.includes(activeUuid))
        trackAnalyticsEvent(AnalyticsEvent.MadeImportedRouteActive, {
          routeUuid: activeUuid,
          voyageUuid,
        });
      forceRefresh();
    },
    [forceRefresh, importedRouteUuids, trackAnalyticsEvent, voyageUuid]
  );

  const {
    mutateAsync: setRouteActive,
    isLoading: setRouteActiveIsLoading,
    isError: setRouteActiveIsError,
    reset: setRouteActiveReset,
  } = useMutation<
    ActiveRoutesMutationResponseDtoData,
    unknown,
    { routeUuid?: string; route?: Route; voyageUuidArg?: string }
  >(
    async ({ routeUuid, route, voyageUuidArg }) => {
      const voyageUuidForRequest = voyageUuidArg ?? voyageUuid;
      if (voyageUuidForRequest) {
        if (routeUuid) {
          setPendingActiveRoute(routeUuid);
          const res = await ActiveRoutesApi.makeRouteActive({
            makeRouteActiveDto: { voyageUuid: voyageUuidForRequest, routeUuid },
          });
          synchronizeQueryCache(res.data, queryClient);
          setPendingActiveRoute(undefined);
          return res.data;
        } else if (route?.extensions?.uuid) {
          setPendingActiveRoute(route.extensions.uuid);
          const body: CreateRouteDto = {
            uuid: route.extensions.uuid,
            voyageUuid: voyageUuidForRequest,
            source: RouteSource.Imported,
            rtzRouteObject: getRouteForApi(route),
            canCreateChannels: false,
          };
          const res = await ActiveRoutesApi.makeRouteActive({
            makeRouteActiveDto: {
              voyageUuid: voyageUuidForRequest,
              route: body,
            },
          });
          synchronizeQueryCache(res.data, queryClient);
          setPendingActiveRoute(undefined);
          return res.data;
        } else {
          throw Error(
            "No route or route uuid provided when updating active route."
          );
        }
      } else {
        throw Error("No voyage uuid provided when updating active route.");
      }
    },
    {
      onSuccess: onSetRouteActiveSuccess,
    }
  );

  return useMemo(
    () => ({
      activeRouteUuid: activeRouteUuid ?? undefined,
      forceRefresh,
      activeRouteIsLoading,
      setRouteActive,
      pendingActiveRoute,
      setRouteActiveIsLoading,
      setRouteActiveIsError,
      setRouteActiveReset,
    }),
    [
      activeRouteUuid,
      forceRefresh,
      activeRouteIsLoading,
      setRouteActive,
      pendingActiveRoute,
      setRouteActiveIsLoading,
      setRouteActiveIsError,
      setRouteActiveReset,
    ]
  );
};

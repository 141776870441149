import React, { Suspense, useEffect, useState } from "react";
import { QueryCache, QueryClient, QueryClientProvider } from "react-query";
import { Router } from "react-router-dom";

import { datadogRum } from "@datadog/browser-rum";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import GlobalErrorHandler from "components/GlobalErrorHandler";
import FallbackLoadingScreen from "screens/FallbackLoadingScreen";

import { ThemeProvider } from "@mui/material/styles";
import config, { useLocalSentry } from "config";
import { createBrowserHistory } from "history";
import { Route as DomRoute } from "react-router-dom";
import * as registerServiceWorker from "service-worker/register";
import { ServiceWorkerStatus } from "service-worker/register";
import { theme } from "styles/theme";
import { QueryParamProvider } from "use-query-params";

import { Integration } from "@sentry/types";
import buildCommit from "bundle-data/build-commit.json";
import { AuthenticationProvider } from "components/AuthenticationProvider";
import {
  WaitForAppSettings,
  wayfinderAppSettingDefinitions,
  WayfinderAppSettingName,
} from "contexts/AppSettingsContext";
import { CrystalGlobeApiProvider } from "contexts/CrystalGlobeApiContext";
import useErrorState from "contexts/ErrorContext/use-error-state";
import { isAutomatedTestingSession } from "helpers/test-helpers";
import { AuthenticationGuard } from "../AuthenticationGuard";
import ErrorDialog from "../GlobalErrorHandler/ErrorDialog";
import { RouterSwitch } from "./RouterSwitch";

const history = createBrowserHistory();

const isLocal = window.location.hostname.includes("localhost");

if (!isLocal || useLocalSentry) {
  const integrations: Integration[] = [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
  ];

  Sentry.init({
    dsn: config.sentryDSN,
    autoSessionTracking: true,
    integrations,
    tracesSampleRate: 1.0,
    release: "wayfinder-frontend@" + buildCommit.sha1,
    environment: window.location.hostname,
    maxBreadcrumbs: 50,
  });
}

if (!isLocal && !isAutomatedTestingSession()) {
  datadogRum.init({
    applicationId: config.datadog.applicationId,
    clientToken: config.datadog.clientToken,
    site: "datadoghq.com",
    service: "wayfinder-frontend",
    env: process.env.NODE_ENV,
    version: buildCommit.sha1,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    allowedTracingUrls: [/https:\/\/.*\.sofarocean\.com\/wayfinder/],
    startSessionReplayRecordingManually: true,
  } as any);
}

// Split out vendors and app
const VendorContextProvider = React.lazy(
  () => import("contexts/VendorContext")
);
const WayfinderAppStateManager = React.lazy(
  () => import("./WayfinderAppStateManager")
);

const WayfinderApp: React.FC<{}> = () => {
  const [
    serviceWorkerStatus,
    setServiceWorkerStatus,
  ] = useState<ServiceWorkerStatus>({
    hasCheckedForUpdate: false,
    isNewAppReady: false,
    isNewAppPublished: false,
    needsRefresh: false,
    autoRefreshOnUpdate: false,
    updateNow: () => window.location.reload(),
  });
  useEffect(() => {
    // Register the service worker and regularly check for updates
    registerServiceWorker.register(
      setServiceWorkerStatus,
      config.serviceWorkerUpdateFrequencySeconds
    );

    // stop trackpad pinching from scaling the page
    document.addEventListener(
      "wheel",
      (e) => {
        if (e.ctrlKey) {
          // only set on pinches, not scrolls
          e.preventDefault();
        }
      },
      { passive: false }
    );
  }, []);
  const errorState = useErrorState();
  const {
    showTitle: showErrorTitle,
    showDetails: showErrorDetails,
    error,
    showError,
    setShowError,
    description,
    onDismissError,
  } = errorState;

  // Shared cache used to store results from all react-query queries
  const queryCache = new QueryCache();

  // Note: It's very important that this Query Client comes from a useState so
  // it stays stable! Without this, whenever the app rerenders we get a new
  // Query Client, which breaks the client caching. This manifests as
  // server-side mutations not immediately updating the UI, because the
  // mutation actually changes a different query cache.
  // See the docs for more info https://tanstack.com/query/latest/docs/eslint/stable-query-client
  const [queryClient] = useState(() => {
    return new QueryClient({
      queryCache,
      defaultOptions: {
        queries: {
          // `useErrorBoundary` can catch exceptions and re-raise them in the renderer context
          // on the next render cycle, allowing fetch exceptions to be caught by GlobalErrorHandler
          // but instead of setting it here, we leave it to each specific query to decide if re-raising
          // is the right choice.
          useErrorBoundary: false,
          // The API context should handle setting the appropriate error state when a request fails,
          // but we don't want to show the error dialog until *every* retry has failed, so we only
          // set showError to true on the last retry (when `onError` is called)
          onError: () => setShowError(true),
          // `refetchOnWindowFocus` disables refetching all queries when the window regains
          // focus (which also occurs when switching between devtools and main window)
          refetchOnWindowFocus: false,
        },
      },
    });
  });

  return (
    <GlobalErrorHandler state={errorState}>
      <ThemeProvider theme={theme}>
        <Router history={history}>
          <QueryParamProvider ReactRouterRoute={DomRoute}>
            <QueryClientProvider client={queryClient}>
              <WaitForAppSettings
                settings={
                  Object.keys(
                    wayfinderAppSettingDefinitions
                  ) as WayfinderAppSettingName[]
                }
              >
                <Suspense fallback={<FallbackLoadingScreen />}>
                  <VendorContextProvider>
                    <AuthenticationProvider>
                      <CrystalGlobeApiProvider>
                        <AuthenticationGuard>
                          <WayfinderAppStateManager
                            serviceWorkerStatus={serviceWorkerStatus}
                          >
                            <RouterSwitch />
                            <ErrorDialog
                              showError={showError}
                              showTitle={showErrorTitle}
                              showDetails={showErrorDetails}
                              error={error}
                              description={description}
                              dismissError={onDismissError}
                            />
                          </WayfinderAppStateManager>
                        </AuthenticationGuard>
                      </CrystalGlobeApiProvider>
                    </AuthenticationProvider>
                  </VendorContextProvider>
                </Suspense>
              </WaitForAppSettings>
            </QueryClientProvider>
          </QueryParamProvider>
        </Router>
      </ThemeProvider>
    </GlobalErrorHandler>
  );
};

export default WayfinderApp;

import { isNil } from "lodash";

import {
  GeoJsonPointDto,
  GeoJsonPointDtoFromJSON,
  PointEnum,
  PortDto,
} from "@sofarocean/wayfinder-typescript-client";

export type PortVariant = "primary" | "secondary" | "tertiary";

export type Port = Pick<PortDto, "displayName" | "timezone"> &
  Partial<Omit<PortDto, "location">> & {
    variant?: PortVariant;
    latitude?: number;
    longitude?: number;
  };

export const portDtoToPort = (port: PortDto): Port => {
  const {
    uuid,
    displayName,
    unlocode,
    country,
    location,
    timezone,
    euEtsApplies,
    showOnMap,
  } = port;
  return {
    uuid,
    displayName,
    unlocode,
    country,
    // default all ports to tertiary (regular) variant
    variant: "tertiary",
    latitude: location.coordinates[1],
    longitude: location.coordinates[0],
    timezone,
    euEtsApplies,
    showOnMap,
  };
};

export const geoJsonPointDtoToPort = (
  port: Port | null,
  point: GeoJsonPointDto | null
): Port | null => {
  return isNil(port) && isNil(point)
    ? null
    : {
        ...(port || { displayName: "" }),
        latitude: point?.coordinates[1],
        longitude: point?.coordinates[0],
      };
};

export const portToGeoJsonPointDto = (
  port: Port | null
): GeoJsonPointDto | undefined => {
  return !isNil(port?.longitude) && !isNil(port?.latitude)
    ? GeoJsonPointDtoFromJSON({
        coordinates: [port?.longitude as number, port?.latitude as number],
        type: PointEnum.Point,
      })
    : undefined;
};

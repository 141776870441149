import { WaypointLookup } from "contexts/RouteStoreContext/state-types";
import { Route, Waypoint } from "../shared-types/RouteTypes";

export const getRouteSchedule = (
  { waypoints, schedules }: Route,
  waypointLookup?: WaypointLookup
) => {
  let departureWaypoint: Waypoint | undefined;
  let arrivalWaypoint: Waypoint | undefined;

  const manualSchedule = schedules?.schedules?.[0]?.manual;
  const calculatedSchedule = schedules?.schedules?.[0]?.calculated;
  const scheduleElements = (manualSchedule ?? calculatedSchedule)
    ?.scheduleElements;

  const departureScheduleElement = scheduleElements?.[0];
  const arrivalScheduleElement =
    scheduleElements?.[scheduleElements.length - 1];

  if (departureScheduleElement) {
    departureWaypoint = // only use find() if there is no result in the lookup
      waypointLookup?.byId[departureScheduleElement.waypointId] ??
      waypoints.waypoints.find(
        (w) => departureScheduleElement.waypointId === w.id
      );
  }
  if (arrivalScheduleElement) {
    arrivalWaypoint = // only use find() if there is no result in the lookup
      waypointLookup?.byId[arrivalScheduleElement.waypointId] ??
      waypoints.waypoints.find(
        (w) => arrivalScheduleElement.waypointId === w.id
      );
  }
  return {
    manualSchedule,
    calculatedSchedule,
    scheduleElements,
    departureWaypoint,
    arrivalWaypoint,
    departureScheduleElement,
    arrivalScheduleElement,
  };
};

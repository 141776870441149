import moment from "moment";
import {
  CalculatedScheduleElementExtensions,
  CommonScheduleElementExtensions,
  Manual,
  ManualScheduleElement,
  Route,
  RouteExtensions,
  RouteInfoExtensions,
  Schedule,
  ScheduleElement,
  Waypoint,
  WaypointExtensions,
} from "shared-types/RouteTypes";
import {
  calculateNauticalMilesBetweenWaypoints,
  computeNewRouteSpeedsAndTimes,
} from "helpers/routes";
import { validate as validateUuid } from "uuid";
import { Context } from "@sentry/types";
import { isNumber } from "helpers/units";
import _, { cloneDeep, isArray, isNil } from "lodash";
import { captureException } from "@sentry/react";
import rtzSchema from "./rtz-schema-version-1_2.jsonschema.json";

type RouteValidationInput = {
  route: Route;
  isRouteImported?: boolean;
  // skip recursion
  isFinal?: boolean;
};

export type RouteValidationErrorType =
  | "uuid-is-invalid"
  | "missing-first-manual-schedule-elements"
  | "missing-default-waypoint-id"
  | "missing-speeds"
  | "has-speed-on-first-schedule-element"
  | "missing-times"
  | "times-do-not-parse"
  | "times-and-speeds-do-not-agree"
  | "properties-are-wrong-data-type"
  | "waypoints-in-schedule-do-not-exist"
  | "missing-schedule-elements"
  | "schedule-only-includes-departure-and-arrival" // found in Navig8 Totem routes
  | "could-not-compute-distance-between-waypoints-in-schedule"
  | "duplicated-times"
  | "imported-route-with-duplicate-waypoint"
  | "imported-route-with-duplicate-waypoint-ids"
  | "schedule-element-order-does-not-match-waypoint-order"
  | "has-manual-and-calculated-schedules"
  | "missing-route-name";

export type RouteValidationErrorWithMessage = {
  // how it failed
  type: RouteValidationErrorType;
  // a string that could go into a message to the user or to sentry
  message?: string;
  // some data that might help the receiver of the error decide how bad it is or what to do
  data?: any;
};

export type RouteValidationResult = {
  isValid: boolean;
  errors?: RouteValidationErrorWithMessage[];
};

// The degrees of precision used when comparing route speeds to computes speeds
const SPEED_COMPARISON_PRECISION = 1;
// The number of hours difference that is tolerated when assessing if the end time of a route
// computed using its speeds is in alignment with the timestamp on the final schedule element
const SCHEDULE_TIME_ALIGNMENT_THRESHOLD = 1;

const numericSchemaTypes = ["integer", "nonNegativeInteger", "decimal"];
const stringSchemaTypes = [
  "string",
  "GeometryType",
  "QName",
  "dateTime",
  "time",
  "duration",
];

/**
 * Get the definitions in the schema that have properties
 * TODO consider updating this to return them for the name of the element, rather than types like GMPoint, which are being lost
 * See comments below
 */
type Definitions = typeof rtzSchema.definitions;
type ObjectWithProperties<T> = {
  properties: any;
} & {
  [K in keyof T]: T[K];
};
type DefinitionsWithProperties = {
  [K in keyof Definitions]: ObjectWithProperties<
    Definitions[keyof Definitions]
  >;
};

const definitionsWithProperties: DefinitionsWithProperties = Object.fromEntries(
  Object.entries(rtzSchema.definitions).filter((entry): entry is [
    any,
    { properties: any } & typeof entry[1]
  ] => Boolean(entry[1].hasOwnProperty("properties")))
);

// Derive the types of properties found in the route.
// For now, the property names of all fields are assumes to be unique,
// ie we assume that "name" is not defined once as a "QName" and elsewhere as a "string"
// in reality, the property is defined as both, but it does not matter
const rtzPropertyTypes = Object.fromEntries(
  Object.entries(definitionsWithProperties)
    .map(([_, { properties }]) => {
      return Object.entries(properties as Record<string, any>).map(
        ([propertyName, { allOf }]) => {
          const type = allOf[0].$ref?.match(/definitions\/(?<type>.+)$/)?.groups
            .type;
          return type && // only fields with the types in these arrays are supported
            (numericSchemaTypes.includes(type) ||
              stringSchemaTypes.includes(type))
            ? [propertyName, type]
            : [];
        }
      );
    })
    .flat()
    .filter((e) => e.length)
);

type SofarRouteKeys = keyof (RouteExtensions &
  RouteInfoExtensions &
  CalculatedScheduleElementExtensions &
  CommonScheduleElementExtensions &
  WaypointExtensions);
const sofarRouteKeys: Omit<
  Record<SofarRouteKeys, "boolean" | "decimal" | "uuid" | "array" | "string">,
  "extension"
> = {
  fuelDollarsPerMt: "decimal",
  timeCharterDollarsPerDay: "decimal",
  significantWaveHeight: "decimal",
  seasHeight: "decimal",
  seasDirection: "decimal",
  seasPeriod: "decimal",
  swellHeight: "decimal",
  swellDirection: "decimal",
  swellPeriod: "decimal",
  meanDirection: "decimal",
  peakWaveFrequency: "decimal",
  percentPowerMCR: "decimal",
  economicCost: "decimal",
  ecaFuelDollarsPerMt: "decimal",
  surchargeDollarsPerCo2Mt: "decimal",
  effectiveSurchargeDollarsPerCo2Mt: "decimal",
  euEtsStatus: "string",
  fuelEconomicCost: "decimal",
  ecaFuelKg: "decimal",
  ecaFuelEconomicCost: "decimal",
  fuelEmissionsSurchargeEconomicCost: "decimal",
  emissionsCo2Mt: "decimal",
  timeInEcaMinutes: "decimal",
  distanceInEcaNm: "decimal",
  opportunityEconomicCost: "decimal",
  rollAngle: "decimal",
  pitchAngle: "decimal",
  uuid: "uuid",
  readonly: "boolean",
  course: "decimal",
  simulatedCourse: "decimal",
  isSimulated: "boolean",
  isStationaryRoute: "boolean",
  currentFactor: "decimal",
  barometricPressure: "decimal",
  waveLength: "decimal",
  waveSpeed: "decimal",
  waveEncounterPeriod: "decimal",
  guidanceType: "array",
  sofarRouteVersion: "string",
  canCreateChannels: "boolean",
  // overwrite rtz pitch type
  pitch: "decimal",

  precipitation: "decimal",
  visibility: "decimal",

  // safety warnings,
  synchronousRollWarning: "string",
  parametricRollWarning: "string",
  broachingWarning: "string",
  highWaveWarning: "string",
  rollAngleWarning: "string",
  pitchAngleWarning: "string",

  drifting: "string",

  originalRouteUuid: "uuid",
  originalRouteSource: "string",
};
const sofarAndRtzPropertyTypes = {
  ...rtzPropertyTypes,
  ...sofarRouteKeys,
};
// The subset of properties in the file that will result in an error
// if they are not valid
const propertyNamesRequiredToValidate = [
  ...Object.keys(sofarRouteKeys),
  "version",
  "lat",
  "lon",
  "geometryType",
  "portsideXTD",
  "speedMax",
  "speedMin",
  "starboardXTD",
  "routeName",
  "id",
  "currentDirection",
  "currentSpeed",
  "eta",
  "etd",
  "fuel",
  "rpm",
  "pitch",
  "speed",
  "waypointId",
  "windDirection",
  "windSpeed",
  "name",
];

const validateDataType = (name: string, value: number | string | undefined) => {
  switch (sofarAndRtzPropertyTypes[name]) {
    case "string":
    case "QName":
    case "dateTime":
    case "time":
    case "duration":
      return typeof value === "string";
    case "GeometryType":
      return value === "Orthodrome" || value === "Loxodrome";
    case "integer":
      return (
        typeof value === "number" &&
        !isNaN(value) &&
        isFinite(value) &&
        Number.isInteger(value)
      );
    case "nonNegativeInteger":
      return (
        typeof value === "number" &&
        !isNaN(value) &&
        isFinite(value) &&
        Number.isInteger(value) &&
        value >= 0
      );
    case "decimal":
      if (name === "speed") {
        return (
          typeof value === "number" &&
          !isNaN(value) &&
          isFinite(value) &&
          value >= 0
        );
      }
      return typeof value === "number" && !isNaN(value) && isFinite(value);
    case "uuid":
      return value && typeof value === "string" && validateUuid(value);
    case "boolean":
      return typeof value === "boolean";
    case "array":
      return isArray(value);
    default:
      return false;
  }
};

export const fixDataType = (
  name: string,
  value: number | string | boolean | any[] | undefined
) => {
  const correctType = sofarAndRtzPropertyTypes[name];
  switch (correctType) {
    case "string":
    case "QName":
    case "dateTime":
    case "time":
      const fixedValue = value?.toString();
      if (name === "version") {
        return typeof fixedValue === "string"
          ? // pad version if it is an int
            fixedValue?.length === 1
            ? `${fixedValue}.0`
            : fixedValue
          : undefined;
      } else {
        return fixedValue;
      }
    case "GeometryType":
      const fixedGeometryValue = value?.toString();
      return fixedGeometryValue === "orthodrome"
        ? "Orthodrome"
        : fixedGeometryValue === "loxodrome"
        ? "Loxodrome"
        : fixedGeometryValue;
    case "integer":
    case "nonNegativeInteger":
      if (typeof value === "number" && Math.round(value) !== value) {
        console.warn(
          `${name} property in route should be of type ${correctType}, but ${value} found. Rounding to nearest integer.`
        );
      }
      return (
        value &&
        (typeof value === "string"
          ? parseInt(value)
          : typeof value === "number"
          ? Math.round(value)
          : value)
      );
    case "decimal":
      return value && (typeof value === "string" ? parseFloat(value) : value);
    case "uuid":
      return value;
    case "boolean":
      return value === "true" ? true : value === "false" ? false : value;
    case "array":
      return value;
    default:
      return value;
  }
};

const traverseObjectPropertiesAndValidateOrFixDataTypes = (
  parent: Record<string, any>,
  parentName?: string,
  fixValues?: boolean
):
  | undefined
  | {
      message: string;
      data: {
        property: string;
        value: number;
        type: string;
      };
    } => {
  for (const childName in parent) {
    if (typeof parent[childName] === "object") {
      if (parentName !== "extensions") {
        if (Array.isArray(parent[childName])) {
          for (const item of parent[childName]) {
            if (typeof parent[childName] === "object") {
              // note: there are only 1d arrays in the spec
              const result = traverseObjectPropertiesAndValidateOrFixDataTypes(
                item,
                childName,
                fixValues
              );
              if (result) return result;
            }
          }
        } else {
          const result = traverseObjectPropertiesAndValidateOrFixDataTypes(
            parent[childName],
            childName,
            fixValues
          );
          if (result) return result;
        }
      }
    } else {
      if (!validateDataType(childName, parent[childName]))
        if (fixValues) {
          // if we want to fix the values, then try to do so
          if (parent[childName] === undefined || parent[childName] === null) {
            // Undefined is never a valid value
            delete parent[childName];
          } else {
            parent[childName] = fixDataType(childName, parent[childName]);
            // if fixing failed, then delete it if it is not needed and print a warning
            if (
              !validateDataType(childName, parent[childName]) &&
              !propertyNamesRequiredToValidate.includes(childName)
            ) {
              console.warn(
                `Property "${childName}" with value ${
                  parent[childName]
                } of type ${typeof parent[
                  childName
                ]} is either unknown or invlaid. It is not required to be valid for Wayfinder to operate, so it will be deleted.`
              );
              delete parent[childName];
            }
          }
        } else {
          // otherwise, just return the result of the failed validation
          return {
            message: `Property "${childName}" with value ${
              parent[childName]
            } of type ${typeof parent[
              childName
            ]} is either unknown or invlaid.`,
            data: {
              property: childName,
              value: parent[childName],
              type: typeof parent[childName],
            },
          };
        }
    }
  }
  return undefined;
};

const validateRouteDataTypes = (route: Route) => {
  return traverseObjectPropertiesAndValidateOrFixDataTypes(route);
};
const fixRouteDataTypes = (route: Route) => {
  return traverseObjectPropertiesAndValidateOrFixDataTypes(
    route,
    undefined,
    true
  );
};
/**
 *
 * @param routeValidationInput Returns a list of problems with a route if any are found.
 * @returns
 */
export const validateRoute = ({
  route,
  isRouteImported = false,
}: RouteValidationInput): RouteValidationResult => {
  const errors: RouteValidationErrorWithMessage[] = [];

  // check if data types in the route are correct based on schema and sofar definitions
  const dataTypeResult = validateRouteDataTypes(route);

  if (dataTypeResult)
    errors.push({
      type: "properties-are-wrong-data-type",
      ...dataTypeResult,
    });

  if (!route.routeInfo?.routeName) {
    errors.push({
      type: "missing-route-name",
      message: `Route name is missing.`,
    });
  }

  // check if there is a default waypoint
  if (
    !route.waypoints.defaultWaypoint ||
    typeof route.waypoints.defaultWaypoint.id !== "number"
  ) {
    errors.push({
      type: "missing-default-waypoint-id",
      message: `Could not find the default waypoint id.`,
    });
  }
  const routeWaypoints = route.waypoints.waypoints;
  const waypointIds = new Set(routeWaypoints.map((wp) => wp.id));
  if (routeWaypoints.length !== waypointIds.size) {
    errors.push({
      type: "imported-route-with-duplicate-waypoint-ids",
      message: `Imported route contains duplicate waypoint ids.`,
    });
  }

  // check if speeds in the first manual schedule exist
  const scheduleElements =
    route.schedules?.schedules?.[0]?.manual?.scheduleElements;
  if (
    !!scheduleElements &&
    !!route.schedules?.schedules?.[0]?.calculated?.scheduleElements
  ) {
    errors.push({
      type: "has-manual-and-calculated-schedules",
      message: "Route has both manual and calculated schedules",
    });
  }

  if (!scheduleElements) {
    errors.push({
      type: "missing-first-manual-schedule-elements",
      message: `Could not find schedule elements in first manual schedule.`,
    });
  } else {
    // check if the order of the schedule elements matches the order of the waypoints
    // this must include detecting schedule elements that do not match any waypoint id
    const scheduleElementsInWaypointOrder = routeWaypoints
      .map((
        w // allow wrong data type here because a different check will flag that
      ) => scheduleElements.find((s) => Number(s.waypointId) === Number(w.id)))
      .filter((s): s is ScheduleElement => Boolean(s));
    if (scheduleElementsInWaypointOrder.length < scheduleElements.length) {
      errors.push({
        type: "waypoints-in-schedule-do-not-exist",
        message: `There are waypointIds in the schedule elements that are not found in the waypoints list.`,
        data: { scheduleElementsInWaypointOrder },
      });
    } else if (!_.isEqual(scheduleElements, scheduleElementsInWaypointOrder)) {
      errors.push({
        type: "schedule-element-order-does-not-match-waypoint-order",
        message: `The order of the schedule elements does not match the order of the waypoints.`,
        data: { scheduleElementsInWaypointOrder },
      });
    }
    const elementsWithSpeeds = scheduleElements.filter(
      // waypoint speeds should never be negative or 0
      (s) => !isNil(s.speed) && (isRouteImported ? s.speed > 0 : s.speed >= 0)
    );
    const hasSpeedForFirstWaypoint = scheduleElements[0].speed !== undefined;
    if (hasSpeedForFirstWaypoint) {
      errors.push({
        type: "has-speed-on-first-schedule-element",
        message: `The first schedule element in first manual schedule has a speed. Wayfinder supports sail-to, so this is an error.`,
      });
    }
    const missingSpeeds =
      elementsWithSpeeds.length < scheduleElements.length - 1;
    if (missingSpeeds) {
      errors.push({
        type: "missing-speeds",
        message: `${elementsWithSpeeds.length} of ${scheduleElements.length} schedule elements in first manual schedule have speeds.`,
      });
    }
    // check if times exist and parse using ISO 8601
    const elementsWithTimes = scheduleElements.filter(
      (s, i) => s[i === 0 ? "etd" : "eta"] !== undefined && s !== null
    );
    const hasTimes = elementsWithTimes.length === scheduleElements.length;
    if (!hasTimes) {
      errors.push({
        type: "missing-times",
        message: `${elementsWithTimes.length} of ${scheduleElements.length} schedule elements in first manual schedule have times.`,
      });
    } else if (
      // do not bother checking if times and speeds agree if there are missing waypoints
      // because it will fail anyway
      !errors.find((e) => e.type === "waypoints-in-schedule-do-not-exist")
    ) {
      const timesThatParse = scheduleElements.filter((s, i) => {
        const time = s[i === 0 ? "etd" : "eta"];
        return typeof time === "string" && !isNaN(new Date(time).getTime());
      });
      const allTimesParse = timesThatParse.length === scheduleElements.length;
      if (!allTimesParse) {
        errors.push({
          type: "times-do-not-parse",
          message: `Only ${elementsWithTimes.length} of ${scheduleElements.length} schedule elements in first manual schedule have times that parse.`,
        });
      } else {
        // check if speeds and times agree
        const routeSpeeds = scheduleElements.map((s) => s.speed);
        const computedDistances = scheduleElements.map((end, i) => {
          if (i === 0) return undefined; // no speeds on first waypoint in schedule
          const start = scheduleElements[i - 1];
          const startWaypoint = route.waypoints.waypoints.find(
            (w) => w.id === start.waypointId
          );
          const endWaypoint = route.waypoints.waypoints.find(
            (w) => w.id === end.waypointId
          );
          if (!startWaypoint || !endWaypoint) {
            errors.push({
              type: "waypoints-in-schedule-do-not-exist",
              message: `Waypoint with id ${
                !startWaypoint ? start.waypointId : end.waypointId
              } does not exist.`,
              data: !startWaypoint ? start.waypointId : end.waypointId,
            });
            return undefined;
          }
          let distance = undefined;
          try {
            distance =
              startWaypoint &&
              endWaypoint &&
              calculateNauticalMilesBetweenWaypoints(
                startWaypoint,
                endWaypoint
              );
            /**
             * We were previously using an old version of the turfjs package which threw
             * an error when any string value was provided as a coordinate.
             * See: https://github.com/Turfjs/turf/blob/a0340be84b2d73f5e38d09e16e76ddb86fb54e46/packages/turf-invariant/index.mjs#L19
             * Using the latest version of turfjs it only checks that the coordinate values are
             * not arrays, which accepts strings and will work if the string can be converted to a number,
             * but will return NaN when the string is not a number value.
             * See: https://github.com/Turfjs/turf/blob/master/packages/turf-invariant/index.ts#L46-L47
             */
            if (isNaN(distance)) throw new Error("Computed distance was NaN");
          } catch (e: any) {
            errors.push({
              type: "could-not-compute-distance-between-waypoints-in-schedule",
              message: `Could not compute distance between waypoints ${startWaypoint.id} and ${endWaypoint.id}: \nLat: ${startWaypoint.position.lat}, Lon: ${startWaypoint.position.lon} and Lat: ${endWaypoint.position.lat}, Lon: ${endWaypoint.position.lon}.`,
              data: { startWaypoint, endWaypoint },
            });
          }
          return distance;
        });
        const computedSpeeds = scheduleElements.map((end, i) => {
          if (i === 0) return undefined; // no speeds on first waypoint in schedule
          const start = scheduleElements[i - 1];
          if (!start?.[i - 1 === 0 ? "etd" : "eta"]) return undefined;
          const distance = computedDistances[i];
          if (distance === undefined) return undefined;
          const startMoment = moment(start[i - 1 === 0 ? "etd" : "eta"]);
          const endMoment = moment(end.eta);
          const hoursElapsed = endMoment.diff(startMoment, "hours", true);
          return distance / hoursElapsed;
        });
        const differences = computedSpeeds.map((c, i) => {
          const routeSpeed = routeSpeeds[i];
          return c !== undefined && routeSpeed !== undefined
            ? parseFloat(
                Math.abs(c - routeSpeed).toFixed(SPEED_COMPARISON_PRECISION)
              )
            : undefined;
        });
        if (differences.filter((d) => d && d > 0).length) {
          errors.push({
            type: "times-and-speeds-do-not-agree",
            message: `The times in the schedule for this route cannot be met with the specified speeds and waypoint positions.`,
            data: { routeSpeeds, computedSpeeds, computedDistances },
          });
        }

        // check if any two schedule elements have identical times, if true, insert a "duplicated-times" error right before
        // "times-and-speeds-do-not-agree" error so that always trigger a "times-and-speeds-do-not-agree-fix" after a
        // "duplicated-times-fix", this is anti-pattern, will have to refactor the whole error-handling structure to fix
        if (!isRouteImported) {
          // only check non-imported route
          const timesAndSpeedsDoNotAgreeErrorIndex = errors.findIndex(
            (e) => e.type === "times-and-speeds-do-not-agree"
          );
          scheduleElements.forEach(function checkForDuplicateTimes(se, i) {
            if (
              i < scheduleElements.length - 1 &&
              ((se.etd && se.etd === scheduleElements[i + 1].etd) ||
                (se.eta && se.eta === scheduleElements[i + 1].eta))
            ) {
              if (timesAndSpeedsDoNotAgreeErrorIndex === -1) {
                errors.push({
                  type: "duplicated-times",
                  message: `${i}th and ${
                    i + 1
                  }th schedule element have the identical times`,
                });
                errors.push({
                  type: "times-and-speeds-do-not-agree",
                });
              } else {
                // insert at timesAndSpeedsDoNotAgreeErrorIndex
                errors.splice(timesAndSpeedsDoNotAgreeErrorIndex, 0, {
                  type: "duplicated-times",
                  message: `${i}th and ${
                    i + 1
                  }th schedule element have the identical times`,
                });
              }
              return;
            }
          });
        }
      }
    }
  }
  // check for the special case that schedule elements only exist for the first and last waypoints
  if (
    scheduleElements &&
    scheduleElements.length === 2 &&
    route.waypoints.waypoints.length > 2 &&
    scheduleElements[0].waypointId === route.waypoints.waypoints[0].id &&
    scheduleElements[1].waypointId ===
      route.waypoints.waypoints[route.waypoints.waypoints.length - 1].id
  ) {
    errors.push({
      type: "schedule-only-includes-departure-and-arrival",
      message: `Only found schedule elements for start and end of the route.`,
      data: { scheduleElements, waypoints: route.waypoints.waypoints },
    });
  } else if (scheduleElements) {
    // check for sparse schedule elements
    const scheduleElementsFromWaypointIds = route.waypoints.waypoints.map((w) =>
      scheduleElements.find((s) => s.waypointId === w.id)
    );
    const totalWaypointsMissingScheduleElements = scheduleElementsFromWaypointIds.filter(
      (s) => s === undefined
    ).length;
    const missingScheduleElements = totalWaypointsMissingScheduleElements !== 0;
    if (missingScheduleElements) {
      errors.push({
        type: "missing-schedule-elements",
        message: `${totalWaypointsMissingScheduleElements} waypoints are missing schedule elements.`,
        data: scheduleElementsFromWaypointIds,
      });
    } else if (isRouteImported === true) {
      // TODO currently we prefer to keep additional conditional statements like isRouteImported out of the control flow,
      //  as it is easier to understand the code and predict possible bugs. Be cautious about adding more conditions
      //  like if (isRouteImported === true) for now. We want to Polaris team about the best ways to handle "degenerate"
      //  routes and decide if we should remove isRouteImported in a follow-up or respect conditional statements more
      // duplicate waypoints with same coordinate should be removed for imported route
      const waypoints = route.waypoints.waypoints;
      const duplicateWaypointsIdsToRemove: number[] = [];
      waypoints.forEach((waypoint, index) => {
        if (index < waypoints.length - 1) {
          const { lat, lon } = waypoint.position;
          const { lat: nextLat, lon: nextLon } = waypoints[index + 1].position;
          const duplicateLat = (lat === 0 || lat) && lat === nextLat;
          const duplicateLon = (lon === 0 || lon) && lon === nextLon;
          if (duplicateLat && duplicateLon) {
            duplicateWaypointsIdsToRemove.push(index + 1);
          }
        }
      });
      if (duplicateWaypointsIdsToRemove.length > 0) {
        errors.push({
          type: "imported-route-with-duplicate-waypoint",
          message: `${duplicateWaypointsIdsToRemove.length} duplicate waypoints should be removed.`,
          data: {
            duplicateWaypointsToRemove: duplicateWaypointsIdsToRemove.map(
              (i) => waypoints[i]
            ),
          },
        });
      }
    }
  }
  // check if all numeric fields (as defined in rtz spec) have the "number" type
  // check if route has a uuid
  return errors.length ? { isValid: false, errors } : { isValid: true };
};

/** Move a Route's "calculated" schedule elements to the "manual" schedule
 *  elements if necessary. Also removes any undefined schedules.
 */
function moveCalculatedScheduleToManualSchedule(route: Route) {
  if (route?.schedules?.schedules)
    route.schedules.schedules = route?.schedules?.schedules?.filter((s) => s);
  const schedules = route?.schedules?.schedules;
  if (schedules?.length && !schedules[0]?.manual && schedules[0].calculated) {
    schedules[0].manual = schedules[0].calculated as Manual;
    delete schedules[0].calculated;
  }
  return route;
}

/**
 * Make sure the route has a default waypoint with an id
 * @param route
 * @returns
 */
export function setDefaultWaypointIdIfMissing(route: Route) {
  if (
    route?.waypoints.waypoints.length &&
    (!route.waypoints.defaultWaypoint ||
      typeof route.waypoints.defaultWaypoint.id !== "number")
  )
    route.waypoints.defaultWaypoint = {
      ...(route.waypoints.defaultWaypoint ?? {}),
      id: Math.max(...route?.waypoints.waypoints.map((w) => w.id)) + 1,
    };
  return route;
}

/**
 * Return speed defined unabiguously in identical `speedMax` and `speedMin`
 * @param w
 * @returns
 */
export const getSpeedFromWaypointMinMaxSpeed = (w: Waypoint) =>
  w.leg?.speedMax === w.leg?.speedMin ? w.leg?.speedMax : undefined;

/**
 * Validates the route and tries to fix any issues by directly modifying the route
 * If the route cannot be fixed, an array of remaining issues is returned.
 * @param routeValidationInput
 * @returns RouteValidationResult with an array of remaining issues
 */
export const validateAndFixRoute = ({
  route,
  isRouteImported = false,
  isFinal,
}: RouteValidationInput): RouteValidationResult => {
  const validationResult = validateRoute({ route, isRouteImported });
  const { errors: validationErrors } = validationResult;
  if (validationResult.isValid) {
    return { isValid: true };
  } else if (validationErrors?.length) {
    // keep track of remaining errors as they are fixed so that fixes can use results of previous fixes
    let remainingErrors = [...validationErrors];
    // iterate over the validation errors using the order in which they were reported
    for (const error of validationErrors) {
      let errorFixed = false;
      const remainingErrorTypes = remainingErrors.map((e) => e.type);
      switch (error.type) {
        case "uuid-is-invalid":
          // this is not recoverable. do nothing.
          break;
        case "missing-route-name":
          route.routeInfo.routeName = "Unnamed Route";
          errorFixed = true;
          break;
        case "missing-first-manual-schedule-elements":
          // Routes from Crystal Globe incorrectly have their schedule data in the
          // "calculated" schedule elements already, while they should be treated
          // as "manual" schedule elements. This function will move them to the correct place
          moveCalculatedScheduleToManualSchedule(route);
          if (
            validateRoute({ route, isRouteImported })
              .errors?.map((e) => e.type)
              .includes("missing-first-manual-schedule-elements")
          ) {
            // if moving the schedule did not fix it, then there is something unexpected happening
            // rather than try to guess at what is wrong, just write a new schedule with no times or speeds
            // and restart validation and fixing
            route.schedules = {
              schedules: [
                {
                  id: 0,
                  manual: {
                    scheduleElements: route.waypoints.waypoints.map((w) => ({
                      waypointId: w.id,
                    })),
                  },
                },
              ],
            };
          }
          // when the schedule is fixed, we need to restart the validation to catch any issues there
          return validateAndFixRoute({ route, isRouteImported, isFinal });

        case "has-manual-and-calculated-schedules":
          const schedules = route.schedules?.schedules;
          if (!!schedules && schedules.length > 0) {
            delete schedules[0].calculated;
          }
          break;

        case "imported-route-with-duplicate-waypoint-ids":
          let waypointId = 0;
          const updatedWaypoints: Waypoint[] = [];
          const updatedScheduleElements: ScheduleElement[] = [];
          const manualScheduleElements =
            route.schedules?.schedules?.[0]?.manual?.scheduleElements;
          const calculatedScheduleElements =
            route.schedules?.schedules?.[0]?.calculated?.scheduleElements;

          route.waypoints.waypoints.forEach((w) => {
            const newId = waypointId++;
            updatedWaypoints.push({ ...w, id: newId });
            if (manualScheduleElements) {
              updatedScheduleElements.push({
                ...manualScheduleElements.find((se) => se.waypointId === w.id),
                waypointId: newId,
              });
            } else if (calculatedScheduleElements) {
              updatedScheduleElements.push({
                ...calculatedScheduleElements.find(
                  (se) => se.waypointId === w.id
                ),
                waypointId: newId,
              });
            }
          });

          route.waypoints.waypoints = updatedWaypoints;
          if (manualScheduleElements)
            route.schedules!.schedules![0].manual!.scheduleElements = updatedScheduleElements;
          else if (calculatedScheduleElements)
            route.schedules!.schedules![0].calculated!.scheduleElements = updatedScheduleElements;

          // when the schedule is fixed, we need to restart the validation to catch any issues there
          return validateAndFixRoute({ route, isRouteImported, isFinal });

        case "imported-route-with-duplicate-waypoint":
          const duplicateWaypointsToRemove: Waypoint[] = error.data!
            .duplicateWaypointsToRemove;
          const duplicateWaypointsIdsToRemove = duplicateWaypointsToRemove.map(
            (w) => w.id
          );

          const newScheduleElements: ManualScheduleElement[] = [];
          const newWaypoints: Waypoint[] = [];
          let removedWaypointsCounter = 0;
          let removedSeCounter = 0;

          route.schedules!.schedules![0].manual!.scheduleElements.forEach(
            function fixImportedRouteWithDuplicateWaypoint(se) {
              if (!duplicateWaypointsIdsToRemove.includes(se.waypointId)) {
                newScheduleElements.push({
                  ...se,
                  waypointId: se.waypointId - removedSeCounter,
                });
              } else {
                removedSeCounter += 1;
              }
            }
          );

          route.waypoints.waypoints.forEach((waypoint, i) => {
            if (!duplicateWaypointsIdsToRemove.includes(waypoint.id)) {
              newWaypoints.push({
                ...waypoint,
                id: waypoint.id - removedWaypointsCounter,
              });
            } else {
              removedWaypointsCounter += 1;
            }
          });

          if (
            removedWaypointsCounter === removedSeCounter &&
            removedWaypointsCounter === duplicateWaypointsToRemove.length
          ) {
            route.waypoints.waypoints = newWaypoints;
            route.schedules!.schedules![0].manual!.scheduleElements = newScheduleElements;
            route.waypoints.defaultWaypoint = {
              ...(route.waypoints.defaultWaypoint ?? {}),
              id: Math.max(...route?.waypoints.waypoints.map((w) => w.id)) + 1,
            };
            errorFixed = true;
          }
          break;
        case "schedule-only-includes-departure-and-arrival":
          // if things are not totally messed up, try to fix them
          if (route.schedules?.schedules?.[0]?.manual?.scheduleElements) {
            const originalScheduleElements =
              route.schedules?.schedules?.[0]?.manual?.scheduleElements;
            // get a speed defined on the last waypoint using speedMin and speedMax,
            const lastWaypointSpeed = getSpeedFromWaypointMinMaxSpeed(
              route.waypoints.waypoints[route.waypoints.waypoints.length - 1]
            );
            const newScheduleElements = [
              { ...originalScheduleElements[0] },
              // fill the space between with a schedule element for each waypoint
              ...route.waypoints.waypoints.slice(1, -1).map((w) => {
                const speed = getSpeedFromWaypointMinMaxSpeed(w);
                return {
                  waypointId: w.id,
                  // if the speed is defined unambiguously on the waypoint, use it
                  ...(speed && { speed }),
                } as ScheduleElement;
              }),
              {
                ...originalScheduleElements[
                  originalScheduleElements.length - 1
                ],
                // if the speed is defined unambiguously on the last waypoint, use it
                ...(lastWaypointSpeed && { speed: lastWaypointSpeed }),
              },
            ];
            // replace the original elements with the new ones
            route.schedules.schedules[0].manual.scheduleElements = newScheduleElements;
            // when the schedule is fixed, we need to restart the validation to catch any issues there
            return validateAndFixRoute({ route, isRouteImported, isFinal });
          }
          // otherwise bail
          break;

        case "missing-default-waypoint-id":
          setDefaultWaypointIdIfMissing(route);
          errorFixed = true;
          break;
        case "schedule-element-order-does-not-match-waypoint-order":
          route.schedules!.schedules![0]!.manual!.scheduleElements =
            error.data.scheduleElementsInWaypointOrder;
          errorFixed = true;
          // when the schedule is re-ordered, we need to restart the validation to catch any new issues introduced
          return validateAndFixRoute({ route, isRouteImported, isFinal });
        case "has-speed-on-first-schedule-element":
          // do not fix this if there are missing schedule elements.
          // because the first schedule element may not actually be the first one after they are backfilled
          if (remainingErrorTypes.includes("missing-schedule-elements")) {
            break;
          }
          // wayfinder is sail-to, as is the rtz format, so an imported route should never have an initial speed
          // assume it is an error and delete it.
          const scheduleElement =
            route.schedules?.schedules?.[0]?.manual?.scheduleElements?.[0];
          if (scheduleElement) {
            delete scheduleElement.speed;
          }
          errorFixed = true;
          break;

        // if one schedule's time is the same as last schedule's time, simply add 100 ms to the new schedule's time
        case "duplicated-times":
          if (route.schedules?.schedules?.[0]?.manual?.scheduleElements) {
            const newScheduleElements = [];
            const originalScheduleElements =
              route.schedules?.schedules?.[0]?.manual?.scheduleElements;

            for (let i = 0; i < originalScheduleElements.length; i++) {
              const newScheduleElement = Object.assign(
                {},
                originalScheduleElements[i]
              );
              if (i > 0) {
                const lastScheduleElement = originalScheduleElements[i - 1];
                if (
                  newScheduleElement.etd &&
                  lastScheduleElement.etd &&
                  newScheduleElement.etd === lastScheduleElement.etd
                ) {
                  newScheduleElement.etd = moment
                    .utc(newScheduleElement.etd)
                    .add(100, "milliseconds")
                    .toISOString();
                }
                if (
                  newScheduleElement.eta &&
                  lastScheduleElement.eta &&
                  newScheduleElement.eta === lastScheduleElement.eta
                ) {
                  newScheduleElement.eta = moment
                    .utc(newScheduleElement.eta)
                    .add(100, "milliseconds")
                    .toISOString();
                }
              }
              newScheduleElements.push(newScheduleElement);
            }
            route.schedules.schedules[0].manual.scheduleElements = newScheduleElements;
            errorFixed = true;
          }
          break;

        case "missing-speeds":
          // try to get the speeds from the max and min speeds found in the waypoint legs
          // TODO consider getting rid of the min/max speeds from waypoints
          const waypoints = route.waypoints.waypoints;
          const speedsFromLegs = route.schedules?.schedules?.[0]?.manual?.scheduleElements
            ?.map((se, i) => {
              // https://s3-eu-west-1.amazonaws.com/stm-stmvalidation/uploads/20160420144429/ML2-D1.3.2-Voyage-Exchange-Format-RTZ.pdf
              // page 6 clearly states that legs contain info about the leg from the previous waypoint
              // so ignore any invalid leg data found on the first waypoint
              if (i === 0) {
                return undefined;
              }
              const leg = waypoints.find((w) => w.id === se.waypointId)?.leg;
              return leg && leg?.speedMax === leg?.speedMin
                ? leg.speedMin
                : undefined;
            })
            .filter(
              (speed, i) =>
                i === 0 ||
                (isNumber(speed) && (isRouteImported ? speed > 0 : speed >= 0))
            );
          if (
            speedsFromLegs &&
            speedsFromLegs.length ===
              route.schedules?.schedules?.[0]?.manual?.scheduleElements.length
          ) {
            speedsFromLegs.forEach((speed, index) => {
              const se =
                route.schedules?.schedules?.[0]?.manual?.scheduleElements?.[
                  index
                ];
              if (se && isNumber(speed)) se.speed = speed;
            });
            errorFixed = true;
            break;
          }

        // the next case should be treated like missing speeds
        // but without the check for speeeds in the waypoint speedMin / speedMax properties
        // eslint-disable-next-line no-fallthrough
        case "times-and-speeds-do-not-agree":
          if (
            remainingErrorTypes.includes("missing-times") ||
            remainingErrorTypes.includes("times-do-not-parse") ||
            remainingErrorTypes.includes(
              "could-not-compute-distance-between-waypoints-in-schedule"
            )
          ) {
            // if there are no times or distances then we cannot compute speeds. do nothing
            break;
          } else {
            const scheduleElements =
              route.schedules?.schedules?.[0]?.manual?.scheduleElements;
            scheduleElements?.forEach((sailToElement, i) => {
              if (i > 0) {
                const sailFromElement = scheduleElements[i - 1];
                const sailFromWaypoint = route.waypoints.waypoints.find(
                  (w) => w.id === sailFromElement.waypointId
                );
                const sailToWaypoint = route.waypoints.waypoints.find(
                  (w) => w.id === sailToElement.waypointId
                );
                if (sailToWaypoint && sailFromWaypoint) {
                  const startTime =
                    sailFromElement?.eta ?? sailFromElement?.etd;
                  const endTime = sailToElement?.eta;
                  const timeElapsed =
                    startTime &&
                    endTime &&
                    Math.abs(moment(endTime).diff(startTime, "hours", true));
                  sailToElement.speed = !timeElapsed
                    ? undefined
                    : calculateNauticalMilesBetweenWaypoints(
                        sailFromWaypoint,
                        sailToWaypoint
                      ) / timeElapsed;
                }
              }
            });
          }
          errorFixed = true;
          break;
        case "missing-schedule-elements": {
          const scheduleElements =
            route.schedules?.schedules?.[0]?.manual?.scheduleElements;
          if (!scheduleElements) {
            break;
          }
          // because we do not have schedule elements to order the data by, we
          // use the natural order of the waypoints
          const scheduleElementsAndWaypoints = route.waypoints.waypoints.map(
            (waypoint) => {
              const scheduleElement = scheduleElements.find(
                (s) => s.waypointId === waypoint.id
              );
              return {
                waypoint,
                scheduleElement,
              };
            }
          );
          const newScheduleElements = scheduleElementsAndWaypoints.map(
            ({ waypoint, scheduleElement }) => {
              return scheduleElement ?? { waypointId: waypoint.id };
            }
          );
          scheduleElements.splice(
            0,
            scheduleElements.length,
            ...newScheduleElements
          );
          // when the schedule is fixed, we need to restart the validation to catch any issues there
          return validateAndFixRoute({ route, isRouteImported, isFinal });
        }
        case "missing-times":
          const scheduleElements =
            route.schedules?.schedules?.[0]?.manual?.scheduleElements;
          const routeEtd =
            scheduleElements?.[0].etd || scheduleElements?.[0].eta;
          const routeEta = scheduleElements?.[scheduleElements.length - 1].eta;
          const routeEtdIsValid =
            routeEtd && !isNaN(new Date(routeEtd).getTime());
          const routeEtaIsValid =
            routeEta && !isNaN(new Date(routeEta).getTime());
          const routeEtdAndEtaAreValid = Boolean(
            routeEtdIsValid && routeEtaIsValid
          );
          if (
            remainingErrorTypes.includes("missing-speeds") ||
            remainingErrorTypes.includes("times-and-speeds-do-not-agree") ||
            remainingErrorTypes.includes(
              "could-not-compute-distance-between-waypoints-in-schedule"
            ) ||
            !routeEtdIsValid
          ) {
            if (routeEtdAndEtaAreValid) {
              // use initial and final times to recompute schedule
              const durationMs = moment(routeEta).diff(moment(routeEtd));
              computeNewRouteSpeedsAndTimes(route, durationMs, routeEtd);
              errorFixed = true;
              break;
            } else {
              // if there are no speeds or distances then we cannot compute times, throw out the incomplete data
              scheduleElements?.forEach((se) => {
                delete se.eta;
                delete se.etd;
                if (
                  remainingErrorTypes.includes("missing-speeds") ||
                  remainingErrorTypes.includes("times-and-speeds-do-not-agree")
                ) {
                  delete se.speed;
                }
              });
              break;
            }
          } else {
            // iterate through schedule and replace missing times
            // based on previous time and speed, compute the correct time for this element
            const newScheduleElements = cloneDeep(scheduleElements);

            newScheduleElements?.forEach((sailToElement, i, iterationArray) => {
              if (i > 0) {
                const sailFromElement = iterationArray[i - 1];
                const sailFromWaypoint = route.waypoints.waypoints.find(
                  (w) => w.id === sailFromElement.waypointId
                );
                const sailToWaypoint = route.waypoints.waypoints.find(
                  (w) => w.id === sailToElement.waypointId
                );
                if (sailToWaypoint && sailFromWaypoint) {
                  // prefer the etd of the previous schedule element if it exists
                  // in case the vessel stays on the waypoint after it arrives
                  const startTime =
                    sailFromElement?.etd ?? sailFromElement?.eta;
                  const distance = calculateNauticalMilesBetweenWaypoints(
                    sailFromWaypoint,
                    sailToWaypoint
                  );
                  const speed = sailToElement.speed;
                  const timeElapsed = distance && speed && distance / speed;
                  sailToElement.eta =
                    startTime &&
                    moment(startTime).add(timeElapsed, "hours").toISOString();
                }
              }
            });
            const newFinalScheduleElement =
              newScheduleElements[newScheduleElements.length - 1];
            const newRouteEta =
              newFinalScheduleElement.eta ??
              newScheduleElements[newScheduleElements.length - 1].etd;
            if (
              // if there is not a complete set of initial and final times to compare with
              !routeEtdAndEtaAreValid ||
              // or if the comparison is within a reasonable threshold
              (newRouteEta &&
                Math.abs(moment(newRouteEta).diff(routeEta, "hours")) <
                  SCHEDULE_TIME_ALIGNMENT_THRESHOLD)
            ) {
              // then use the computed timestamps
              scheduleElements.splice(
                0,
                scheduleElements.length,
                ...newScheduleElements
              );
            } else {
              // otherwise, use initial and final times to recompute schedule
              const durationMs = moment(routeEta).diff(moment(routeEtd));
              computeNewRouteSpeedsAndTimes(route, durationMs, routeEtd);
            }
            errorFixed = !validateRoute({ route, isRouteImported })
              .errors?.map((e) => e.type)
              .includes(error.type);
          }
          errorFixed = true;
          break;
        case "times-do-not-parse":
          // this makes no sense. delete them
          route.schedules?.schedules?.[0]?.manual?.scheduleElements.forEach(
            function deleteTimesThatDontParse(se) {
              // only delete etd if it is invlaid, so we can possibly figure out remaining times from speeds
              if (se.etd && isNaN(new Date(se.etd).getTime())) {
                if (isNaN(new Date(se.etd + "Z").valueOf())) {
                  delete se.etd;
                } else {
                  se.etd = se.etd + "Z";
                }
              }

              if (isNaN(new Date(se.eta + "Z").valueOf())) {
                // but delete all others if a "Z" does not help
                delete se.eta;
              } else {
                se.eta = se.eta + "Z";
              }
            }
          );
          return validateAndFixRoute({ route, isRouteImported, isFinal });
        case "properties-are-wrong-data-type":
          fixRouteDataTypes(route);
          const errorAfterFix = validateRoute({
            route,
            isRouteImported,
          }).errors?.find((e) => e.type === "properties-are-wrong-data-type");
          if (errorAfterFix) return { isValid: false, errors: [errorAfterFix] };
          break;
        case "waypoints-in-schedule-do-not-exist":
          // this makes no sense. bail.
          break;
        case "could-not-compute-distance-between-waypoints-in-schedule":
          // this makes no sense. bail.
          break;
      }
      // if the error was fixed in the switch statement
      // clear the error now that we fixed it, so that in the following iterations,
      // we can make other fixes that depend on this one
      if (errorFixed)
        remainingErrors = remainingErrors?.filter((e) => e.type !== error.type);
    }

    // now that we have made our best effort to fix the route,
    // check if it worked
    const validationResult = validateRoute({ route, isRouteImported });

    if (validationResult.isValid || isFinal) {
      return validationResult;
    }

    // as a last-ditch attempt, if the route fails to validate, rip out the schedule, put the speeds back if we have them, and try again
    // prevent the last try from entering recursion, because if the route is really messed up, it could go on forever
    const manualSchedule = route.schedules?.schedules?.[0]?.manual;
    const defaultSchedule = { schedules: [] as Schedule[] };
    const hasSpeeds = !validationResult.errors
      ?.map((e) => e.type)
      .includes("missing-speeds");
    if (manualSchedule && hasSpeeds) {
      defaultSchedule.schedules.push({
        id: 1,
        manual: {
          scheduleElements: manualSchedule.scheduleElements.map(
            ({ waypointId, speed }) => ({ waypointId, speed })
          ),
        },
      });
    }

    route.schedules = defaultSchedule;
    const fixedResult = validateAndFixRoute({
      route,
      isRouteImported,
      isFinal: true,
    });

    // pass along the result of a final validation check.
    // any non-recoverable errors above will be returned
    return fixedResult;
  }
  return { isValid: false };
};

const MAX_SENTRY_CONTEXT_BYTE_COUNT = 8000;

export const logRouteValidationErrors = (
  route: Route,
  errors: RouteValidationErrorWithMessage[],
  additionalContext?: Context
) => {
  const error = Error(
    `Errors encountered ${
      !!additionalContext?.isFixed ? "(and fixed) " : ""
    }while validating route while validating route ${
      route.extensions?.uuid
    }: \n${errors.map((e) => e.message).join(",\n")}`
  );
  console.warn(error);
  captureException(error, {
    contexts: {
      route: {
        truncatedRoute: JSON.stringify(route).slice(
          0,
          MAX_SENTRY_CONTEXT_BYTE_COUNT / 2 // chars are 16 bits in js
        ),
        routeUuid: route.extensions?.uuid,
      },
      ...(additionalContext ? { additionalContext } : {}),
    },
    fingerprint: ["Route Validation Errors", ...errors.map((e) => e.type)],
  });
};

import rhumbDestination from "@turf/rhumb-destination";
import gcDestination from "@turf/destination";
import rhumbDistance from "@turf/rhumb-distance";
import gcDistance from "@turf/distance";
import moment from "moment";
import { round } from "lodash";
import { PlotVariableUnit } from "../components/WeatherAlongRoutePlot/ConnectedWeatherAlongRoutePlot";
import { PlotValue } from "./simulation";

const NAUTICAL_MILES_PER_KM = 0.539957;

export function kmToNauticalMiles(km: number) {
  return NAUTICAL_MILES_PER_KM * km;
}

export function nauticalMilesToKm(nm: number) {
  return nm / NAUTICAL_MILES_PER_KM;
}

export function knotsToKmPerHour(knots: number) {
  return 1.852 * knots;
}

export function metersPerSecondToKnots(mps: number) {
  return 1.94384 * mps;
}

export function knotsToMetersPerSecond(knots: number) {
  return 0.514444 * knots;
}

export function pascalsToMillibars(pascals: number) {
  return 0.01 * pascals;
}

export function kgToMetricTonnes(kg: number) {
  return kg * 0.001;
}

export function frequencyToPeriod(value: number) {
  return 1 / value;
}

export function hoursToMilliseconds(hours: number) {
  return hours * 3600000;
}

// 3.114 is the ratio of MT carbon emitted per MT fuel burned
export function fuelAmountToCarbonEmissions(fuelInMT: number) {
  return fuelInMT * 3.114;
}

export const dateToUtc = (date: Date) =>
  new Date(moment(date).add(moment(date).utcOffset(), "m").utc().format());
export const dateFromUtc = (dateInUtc: Date) =>
  new Date(
    moment(dateInUtc).add(-moment(dateInUtc).utcOffset(), "m").utc().format()
  );

export function millisecondsToDaysAndHours(milliseconds: number) {
  const days = Math.floor(moment.duration(milliseconds).asDays());
  const hours = Math.floor(
    moment
      .duration(
        moment(milliseconds).diff(
          moment.duration(days, "days").asMilliseconds()
        )
      )
      .asHours()
  );
  return {
    days,
    hours,
  };
}

export function convertToDisplayUnits(
  value: PlotValue,
  magnitudeUnits: PlotVariableUnit | null,
  displayUnits: PlotVariableUnit | null
) {
  if (magnitudeUnits === displayUnits) {
    return value;
  }

  if (magnitudeUnits === "kg/(m^2 s)" && displayUnits === "mm/hr") {
    return precipitationToMmPerHour(value);
  } else if (magnitudeUnits === "m" && displayUnits === "NM") {
    return isNumber(value) ? kmToNauticalMiles(value / 1000) : null;
  } else if (magnitudeUnits === "m / s" && displayUnits === "kts") {
    return isNumber(value) ? metersPerSecondToKnots(value) : value;
  } else if (magnitudeUnits === "Pa" && displayUnits === "mbar") {
    return isNumber(value) ? pascalsToMillibars(value) : value;
  } else if (
    magnitudeUnits === "% MCR as fraction" &&
    displayUnits === "% MCR"
  ) {
    return isNumber(value) ? round(100 * value, 1) : value;
  } else if (magnitudeUnits === "Hz" && displayUnits === "s") {
    return value ? frequencyToPeriod(value) : value;
  }

  throw new Error(
    `Could not convert units from "${magnitudeUnits}" to "${displayUnits}"`
  );
}

const precipitationToMmPerHour = (value: PlotValue): number | null => {
  // 1 kg/(m^2 s) = .001 m^3/(m^2 s) = 1 mm/s = 3600 mm/hr
  return isNumber(value) ? value * 3600 : null;
};

export const getTickValues = ({
  magnitudeMin,
  magnitudeMax,
  displayUnits,
  magnitudeUnits,
  count,
  precision,
}: {
  magnitudeMin: { plot: number };
  magnitudeMax: { plot: number };
  displayUnits: PlotVariableUnit | null;
  magnitudeUnits: PlotVariableUnit | null;
  count: number;
  precision: number;
}) => {
  const convertUnits = (v: number) =>
    convertToDisplayUnits(v, magnitudeUnits, displayUnits);
  const [min, max] = [magnitudeMin.plot, magnitudeMax.plot].map(
    convertUnits
  ) as [number, number];
  return new Array(count)
    .fill(0)
    .map((v, i) => min + (i * (max - min)) / (count - 1))
    .map((v) => parseFloat(v.toFixed(precision)));
};

/**
 * Wrap then angle to fall within 0 and 360 degrees
 * @param degrees
 */
export function normalizeAngleDegrees(degrees: number) {
  const normalized = degrees % 360;
  return normalized < 0 ? 360 + normalized : normalized;
}

/**
 * Convert an angle measured counterclockwise from the positive x axis
 * to an angle measured clockwise from the positive y axis
 * @param degrees
 */
export function azimuthToBearingDegrees(degrees: number) {
  return 450 - degrees;
}
/**
 * Convert an angle measured clockwise from the positive y axis
 * to an angle measured counterclockwise from the positive x axis
 * @param degrees
 */
export function bearingToAzimuthDegrees(degrees: number) {
  return 450 - degrees;
}
/**
 * Convert an angle measured clockwise from the positive y axis
 * to an angle measured counterclockwise from the positive x axis
 * @param radians
 */
export function bearingToAzimuthRadians(radians: number) {
  return 7.85398163397 - radians;
}

export function degreesToCompassDirection(degrees: number) {
  const normalizedDegrees = normalizeAngleDegrees(degrees);
  const points = [
    "N",
    "NNE",
    "NE",
    "ENE",
    "E",
    "ESE",
    "SE",
    "SSE",
    "S",
    "SSW",
    "SW",
    "WSW",
    "W",
    "WNW",
    "NW",
    "NNW",
  ];
  const degreesPerDivision = 360 / points.length;
  const division =
    Math.round(normalizedDegrees / degreesPerDivision) % points.length;
  return points[division];
}

// use Orthorome by default
export function distanceFunction(
  geometryType: "Loxodrome" | "Orthodrome" | undefined
) {
  return geometryType === "Loxodrome" ? rhumbDistance : gcDistance;
}

export function destinationFunction(
  geometryType: "Loxodrome" | "Orthodrome" | undefined
) {
  return geometryType === "Loxodrome" ? rhumbDestination : gcDestination;
}

// edited version of coordToDMS from sexagesimal lib to support precise fractional minutes
export function coordToDM(input: number, dim: "lat" | "lon") {
  const dirs = { lat: ["N", "S"], lon: ["E", "W"] }[dim] || "";
  const dir = dirs[input >= 0 ? 0 : 1];
  const abs = Math.abs(input);
  const whole = Math.floor(abs);
  const fraction = abs - whole;
  const fractionMinutes = fraction * 60;

  return {
    whole,
    fractionMinutes,
    dir: dir,
  };
}

function isNegativeZero(value: number) {
  return value === 0 && Object.is(value, -0);
}

/** This function is based on the @mapbox/sexagesimal.coordToDMS implementation
 * the sexagesimal uses only integers for seconds which causes there to be a precision error where we loose a fair bit of accuracy.
 */
export function coordToDMS(input: number, dim: "lat" | "lon") {
  var dirs = { lat: ["N", "S"], lon: ["E", "W"] }[dim] || "";
  // If the coords are 0 & the direction is switched, the input becomes -0,
  // which cannot be checked via a regular === equality check, so we special
  // case it here.
  var dir = dirs[input < 0 || isNegativeZero(input) ? 1 : 0];

  var abs = Math.abs(input);
  var whole = Math.floor(abs);
  var fraction = abs - whole;
  var fractionMinutes = fraction * 60;
  var minutes = Math.floor(fractionMinutes);
  var fractionSeconds = (fractionMinutes - minutes) * 60;
  var seconds = fractionSeconds;

  return {
    whole,
    minutes,
    seconds,
    dir,
    fractionMinutes,
  };
}

export const isNumber = (value: any): value is number =>
  typeof value === "number";

export function degToRad(deg: number) {
  return deg * (Math.PI / 180.0);
}
export function radToDeg(rad: number) {
  return rad * (180.0 / Math.PI);
}

export function polarToCartesian(
  angle: number,
  radius: number,
  invertY?: boolean
) {
  return [
    Math.cos(angle) * radius,
    (invertY ? -1 : 1) * Math.sin(angle) * radius,
  ];
}

import React, { MouseEventHandler, useMemo } from "react";
import { Box, SxProps, Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { typographyStyles, typographyStylesEms } from "styles/theme";

const useWayfinderTypographyStyles = makeStyles()(typographyStyles);
const useWayfinderTypographyStylesEms = makeStyles()(typographyStylesEms);

const useColorStyles = makeStyles<{ color?: string }>()(
  (theme: Theme, { color }) => ({
    color: {
      color: color ?? undefined,
    },
  })
);

export type WayfinderTypographyProps = {
  variant?:
    | keyof ReturnType<typeof useWayfinderTypographyStyles>["classes"]
    | "none";
  className?: string;
  component?: "span" | "div" | "p" | "tspan" | "text";
  color?: string;
  sx?: SxProps;
  onClick?: MouseEventHandler;
  onMouseEnter?: MouseEventHandler;
  onMouseLeave?: MouseEventHandler;
  dataTestid?: string;
  useEms?: boolean;
};

export const WayfinderTypography: React.FC<WayfinderTypographyProps> = ({
  variant = "body",
  className,
  component = "div",
  color,
  children,
  onClick,
  onMouseEnter,
  onMouseLeave,
  dataTestid,
  useEms,
  sx,
}) => {
  const { classes: typographyStylesPx } = useWayfinderTypographyStyles();
  const { classes: typographyStylesEms } = useWayfinderTypographyStylesEms();
  const typographyStyles = useEms ? typographyStylesEms : typographyStylesPx;
  const colorProps = useMemo(() => ({ color }), [color]);
  const { classes: colorStyles } = useColorStyles(colorProps);

  // only add the color class if there is a color, otherwise we are adding a lot of color styles that are not required.
  const classes = `${variant !== "none" ? typographyStyles[variant] : ""} ${
    className ?? ""
  } ${color ? colorStyles.color : ""}`;

  return (
    <Box
      component={component}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={classes}
      data-testid={dataTestid}
      sx={sx}
    >
      {children}
    </Box>
  );
};

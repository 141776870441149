import { captureException, setExtra } from "@sentry/react";

export const consoleAndSentryError = (
  error: any,
  contexts?: any,
  extraData?: Record<string, any>
) => {
  console.error(error, contexts);
  if (extraData !== undefined) {
    Object.entries(extraData).forEach(([key, value]) => setExtra(key, value));
  }

  captureException(
    error,
    contexts !== undefined
      ? {
          contexts,
        }
      : undefined
  );
};

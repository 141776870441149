import {
  Dialog,
  DialogActions,
  DialogActionsProps,
  DialogContent,
  DialogContentProps,
  DialogContentText,
  DialogContentTextProps,
  DialogProps,
  DialogTitle,
  DialogTitleProps,
} from "@mui/material";
import {
  useWayfinderDialogActionsClasses,
  useWayfinderDialogContentClasses,
  useWayfinderDialogTextClasses,
  useWayfinderDialogTitleClasses,
  useWayfinderDialogClasses,
} from "DLS/wayfinder-dialog/wayfinderDialogStyles";
import React, { FC } from "react";

export type WayfinderDialogProps = Omit<DialogProps, "fullWidth" | "maxWidth">;

export const WayfinderDialog: FC<WayfinderDialogProps> = (props) => {
  const { classes } = useWayfinderDialogClasses();

  return (
    <Dialog
      {...props}
      fullWidth={true}
      maxWidth={"sm"}
      PaperProps={{
        className: classes.unroundedBorders,
      }}
    >
      {props.children}
    </Dialog>
  );
};

export type WayfinderDialogTitleProps = Omit<
  DialogTitleProps,
  "disableTypography"
>;

export const WayfinderDialogTitle: FC<WayfinderDialogTitleProps> = (props) => {
  const { classes } = useWayfinderDialogTitleClasses();

  return (
    <DialogTitle {...props} classes={classes}>
      {props.children}
    </DialogTitle>
  );
};

export type WayfinderDialogContentProps = DialogContentProps;
export const WayfinderDialogContent: FC<WayfinderDialogContentProps> = (
  props
) => {
  const { classes } = useWayfinderDialogContentClasses();

  return (
    <DialogContent {...props} classes={classes}>
      {props.children}
    </DialogContent>
  );
};

export type WayfinderDialogContentTextProps = DialogContentTextProps;
export const WayfinderDialogContentText: FC<WayfinderDialogContentTextProps> = (
  props
) => {
  const { classes } = useWayfinderDialogTextClasses();

  return (
    <DialogContentText {...props} classes={classes}>
      {props.children}
    </DialogContentText>
  );
};

export type WayfinderDialogActionsProps = DialogActionsProps;
export const WayfinderDialogActions: FC<WayfinderDialogActionsProps> = (
  props
) => {
  const { classes } = useWayfinderDialogActionsClasses();

  return (
    <DialogActions {...props} classes={classes}>
      {props.children}
    </DialogActions>
  );
};

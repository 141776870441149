import { RouteStoreContext } from "contexts/RouteStoreContext";
import { useContext, useMemo } from "react";
import { useStableDeepComparedReference } from "../../../shared-hooks/use-stable-deepcompared-reference";

export const useImportedRouteUuids = () => {
  const { routes } = useContext(RouteStoreContext);
  const importedRouteUuids = useMemo(
    () => Object.keys(routes).filter((key) => routes[key].metadata.isImported),
    [routes]
  );
  //FIXME is this useStableDeepComparedReference still needed
  return useStableDeepComparedReference(importedRouteUuids);
};

import { useCallback, useContext, useMemo } from "react";
import { useQuery } from "react-query";

import config from "config";
import { CrystalGlobeApiContext } from "contexts/CrystalGlobeApiContext";
import useAppSetting from "contexts/AppSettingsContext";
import { VoyageStatusV2 } from "@sofarocean/wayfinder-typescript-client";
import { getMlvQueryKey } from "../../helpers/crystalGlobeApi";

export const useMultiLegVoyage = (multiLegVoyageUuid: string | undefined) => {
  const context = useContext(CrystalGlobeApiContext);
  const { MultiLegVoyagesApi } = context;

  const { data, remove, refetch, isLoading, isFetching } = useQuery(
    getMlvQueryKey(multiLegVoyageUuid),
    async () => {
      return multiLegVoyageUuid
        ? await MultiLegVoyagesApi.getMultiLegVoyage({
            multiLegVoyageUuid,
            withDeleted: false,
          })
        : undefined;
    },
    {
      retry: 2,
      refetchInterval: config.routePollIntervalMs,
      enabled: Boolean(multiLegVoyageUuid),
    }
  );

  const forceRefresh = useCallback(() => {
    remove();
    return refetch();
  }, [remove, refetch]);

  // Allow voyage status override for testing
  const { value: voyageStatusOverride } = useAppSetting("voyageStatusOverride");
  const dataWithOverrides = useMemo(() => {
    if (voyageStatusOverride && data) {
      return {
        ...data,
        voyageLegs: data?.voyageLegs?.map((l) => ({
          ...l,
          statusV2: voyageStatusOverride as VoyageStatusV2,
        })),
      };
    }
    return data;
  }, [data, voyageStatusOverride]);

  return useMemo(
    () => ({
      data: dataWithOverrides,
      forceRefresh,
      isLoading,
      isFetching,
    }),
    [dataWithOverrides, forceRefresh, isFetching, isLoading]
  );
};

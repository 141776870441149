import { FormFieldChangeAnalyticsEvent } from "contexts/Analytics/use-analytics-state";
import { WayfinderDeepLink } from "contexts/RouteExplorerContext/useDeepLinkParser";
import React from "react";

export type AnalyticsContextType = {
  appIsInForeground: boolean;
  trackAnalyticsEvent: (
    type: AnalyticsEvent,
    eventData?: Record<string, any>
  ) => void;
  trackFormChangeAnalyticsEvents: (
    events: FormFieldChangeAnalyticsEvent[]
  ) => void;
  mixpanelInitialized: boolean;
  deepLink: WayfinderDeepLink;
};
const AnaltyticsContextDefaults: AnalyticsContextType = {
  appIsInForeground: false,
  trackAnalyticsEvent: () => null,
  trackFormChangeAnalyticsEvents: () => null,
  mixpanelInitialized: false,
  deepLink: {
    url: window.location.href,
    urlSearchParams: new URLSearchParams(),
  },
};

const AnalyticsContext = React.createContext<AnalyticsContextType>(
  AnaltyticsContextDefaults
);

// We should NEVER rename an analytics event (change the string)
// unless our team has specifically asked us to do so.
// Changing these values can destroy our ability to infer user behavior from our analytics
export enum AnalyticsEvent {
  // Top level
  AnalyticsInitialized = "Analytics initialized",
  Focused = "Focused",
  TimeSpent = "Time spent",
  // Weather
  DownloadLatestForecast = "Download latest forecast",
  WaitingForForecastDownload = "Waiting for forecast download",
  // General UI
  TimelineInteracted = "Timeline interacted",
  TimeStepperInteracted = "Time stepper interacted",
  ComponentErrorBoundaryShown = "Component Error boundary shown",
  ComponentErrorBoundaryReloadClicked = "Component Error boundary reload clicked",

  LayersButtonClicked = "Layers button clicked",
  TropicalStormsLegendButtonClicked = "Tropical storms legend button clicked",
  VesselAlertsButtonClicked = "Vessel alerts button clicked",
  MapMeasurementButtonClicked = "Map measurement button clicked",
  PanToVesselLocationClicked = "Map pan to vessel location clicked",
  WeatherDownloadStatus = "Weather download status",
  WaypointModified = "Waypoint modified",
  VisualizationTypeChanged = "Visualization type changed",
  SpinnerShown = "Spinner shown",
  StartedSessionReplayRecording = "Started session replay recording",
  // Map element interactions
  SpotterClicked = "Spotter clicked",
  WeatherClicked = "Weather clicked",
  AisClicked = "AIS clicked",
  ReportPositionClicked = "Vessel report position clicked",
  PortClicked = "Port clicked",
  AreaConstraintClicked = "Area constraint clicked",
  SafetyWarningShown = "Safety warning shown",
  FleetViewVesselShown = "Fleet view vessel shown",
  NavigationalWarningClicked = "Navigational warning geometry clicked",
  // Fleet view
  VisitedFleetHome = "Visited fleet home",
  FleetViewMapVesselSelected = "Fleet view map vessel selected",
  FleetViewSidebarVesselSelected = "Fleet view sidebar vessel selected",
  FleetViewSidebarVoyageSelected = "Fleet view sidebar voyage selected",
  FleetViewSidebarVesselHighlighted = "Fleet view sidebar vessel highlighted",
  FleetViewListVesselHighlighted = "Fleet view list vessel highlighted",
  FleetViewSidebarTabSelected = "Fleet view sidebar tab selected",
  FleetViewFollowedVoyageLinkInInspector = "Fleet followed voyage link in inspector",

  // Suggestions
  SuggestionPromptShown = "Suggestion prompt shown",
  SuggestionAcceptDeclineShown = "Suggestion accept/decline shown",
  SuggestionAccepted = "Suggestion accepted",
  SuggestionRejected = "Suggestion rejected",
  // Migration
  MigratedToVoyageUuid = "Migrated to voyage uuid",
  // Route views
  LoadedRouteComparison = "Loaded route comparison",
  LoadedRouteDetail = "Loaded route detail",
  SavedRoute = "Saved changes to a route",
  DeletedMultiLegVoyage = "Deleted multi-leg voyage",
  UpdatedMultiLegVoyage = "Updated multi-leg voyage",
  SavedMultiLegVoyage = "Saved multi-leg voyage",
  MadeRouteActive = "Made route the active route",
  MadeImportedRouteActive = "Made imported route active",
  DeletedImportedRoute = "Deleted imported route",
  // Splash screen
  LoadedSplashScreen = "Loaded splash screen",
  RedirectedToPrimaryVoyageFromSplash = "Redirected to primary voyage from splash",
  RedirectedToLatestVoyageOfAnyStatusFromSplash = "Redirected to latest voyage of any status from splash",
  EnteredVoyageUuidOnSplash = "Entered Voyage Uuid on splash",
  // Import/export
  ImportInfoDialogShown = "Import info dialog shown",
  ImportRouteSuccess = "Import route success",
  ImportRouteFailure = "Import route failure",
  ExportRouteSuccess = "Export route success",
  ExportRouteExplorerRouteSuccess = "Export route explorer route success",
  // Vessel
  UpdatedVessel = "Updated a vessel",
  // Voyage
  UpdatedVoyage = "Updated a voyage",
  // Whitelist
  Whitelist = "Whitelist",
  TestConnection = "TestConnection",
  // Performance metrics
  PerformanceMemory = "Performance:memory",
  PerformanceGpu = "Performance:gpu",
  // Area constraint
  CreateAreaConstraint = "Created an area constraint",
  UpdateAreaConstraint = "Updated an area constraint",
  DeleteAreaConstraint = "Deleted an area constraint",
  // Bank routes
  CreateBankRoute = "Created an bank route",
  UpdateBankRoute = "Updated an bank route",
  DeleteBankRoute = "Deleted an bank route",
  // Optimization
  VisitedOptimizationSidebar = "Visited optimization sidebar",
  EditedFuelCostInOptimizationSidebar = "Edited fuel cost in optimization sidebar",
  EditedTimeCostInOptimizationSidebar = "Edited time cost in optimization sidebar",
  EditedRtaInOptimizationSidebar = "Edited rta in optimization sidebar",
  EditedAverageSpeedInOptimizationSidebar = "Edited average speed in optimization sidebar",
  EditedInstructedSpeedInOptimizationSidebar = "Edited instructed speed in optimization sidebar",
  EditedMaxDailyFuelInOptimizationSidebar = "Edited max daily fuel in optimization sidebar",
  EditedMaxDailyFoInOptimizationSidebar = "Edited max daily FO in optimization sidebar",
  EditedMaxDailyDoGOInOptimizationSidebar = "Edited max daily do/go in optimization sidebar",
  EditedCompleteVoyageInOptimizationSidebar = "Edited complete voyage in optimization sidebar",
  VisitedOptimizationHistorySidebar = "Visited optimization history sidebar",
  // Voyage Creation
  ClickedNewVoyageFromAllVoyages = "Start new voyage from All Voyages",
  ClickedNewVoyageFromMoreOption = "Start new voyage from More button",
  ViewedAllVoyages = "Viewed All Voyages",
  AbandonedNewVoyage = "Abandon new voyage",
  AbandonedEditVoyage = "Abandon editing voyage",
  CreatedNewVoyage = "Create new voyage (hit save)",
  SwitchedVoyageLegRoutesPanel = "Switched voyage legs from Routes Panel",
  EditedDeparturePortVoyageEdit = "Edit departure port from Voyage Edit",
  EditedArrivalPortVoyageEdit = "Edit arrival port from Voyage Edit",
  EditedEtdVoyageEdit = "Edit ETD from Voyage Edit",
  EditedEtaVoyageEdit = "Edit ETA from Voyage Edit",
  EditedMaxFuelVoyageEdit = "Edit max fuel from Voyage Edit",
  EditedMaxFoVoyageEdit = "Edit max Fo from Voyage Edit",
  EditedMaxDoGoVoyageEdit = "Edit max do/go from Voyage Edit",
  EditedRtaVoyageEdit = "Edit RTA from Voyage Edit",
  EditedAverageSpeedVoyageEdit = "Edit average speed from Voyage Edit",
  EditedInstructedSpeedVoyageEdit = "Edit instructed speed from Voyage Edit",
  EditedRouteVoyageEdit = "Edit includes a route change",
  LegAddedVoyageEdit = "A leg was added during Voyage Edit",
  // Route Import Form
  EditedRtaInRouteImportForm = "Edited rta in route import form",
  EditedAverageSpeedInRouteImportForm = "Edited average speed in route import form",
  EditedInstructedSpeedInRouteImportForm = "Edited instructed speed in route import form",
  EditedMaxDailyFuelInRouteImportForm = "Edited max daily fuel in route import form",
  EditedMaxDailyFoInRouteImportForm = "Edited max daily Fo in route import form",
  EditedMaxDailyDoGoInRouteImportForm = "Edited max daily do/go in route import form",
  // Vessel Sidebar
  ViewedVesselOverview = "Viewed Vessel Overview page",
  ViewedVesselAdherence = "Viewed Vessel Acceptance/Adherence page",
  ViewedVesselPerformance = "Viewed Vessel Performance page",
  ViewedVesselDataQuality = "Viewed Vessel Data Quality page",
  ExportedVesselPerformanceCurve = "Exported Vessel Performance curve",
  ViewedVesselReports = "Viewed Vessel Reports page",
  // Route Editing
  EnteredRouteEditMode = "Entered route edit mode",
  SavedEditedRoute = "Saved edited route",
  FollowedEditedRoute = "Followed edited route",
  AbandonedRouteEditMode = "Abandoned route edit mode",
  ClickedUndo = "Clicked undo",
  ClickedRevert = "Clicked revert",
  DraggedWaypoint = "Dragged waypoint",
  AddedWaypoint = "Added waypoint",
  EditedWaypointLonLat = "Edited waypoint's lon/lat",
  ClickedMultiDelete = "Clicked multi delete",
  ClickedMultiEdit = "Clicked multi edit",
  ClickedIndividualDelete = "Clicked individual delete",
  UpdatedRLGC = "Updated RL/GC",
  // Import market factors csv
  ImportedMarketFactorCsv = "Imported market factor csv",
  // Import area constraints csv
  ImportedAreaConstraintCsv = "Imported area constraint csv",
  // Guidance Justification
  CreatedGuidanceJustification = "Created guidance justification",
  UpdatedGuidanceJustification = "Updated guidance justification",
  // Local time switch
  ToggledLocalTimeSwitch = "Toggled local time switch",
  // Seakeeping
  EditedOverallLength = "Edited vessel's overall length",
  EditedDraft = "Edited vessel's draft",
  EditedDisplacement = "Edited vessel's displacement",
  EditedMetacentricHeight = "Edited vessel's metacentric height",
  EditedRollPeriod = "Edited vessel's roll period",
  DismissedSeakeepingInputAlert = "Dismissed seakeeping input alert",
  // Map Layers
  NavigationalWarningToggled = "A navigational warning type was toggled",
  SeaIceLayerToggled = "The sea ice concentration layer was toggled",
  PressureIsobarsToggled = "The  pressure isobars layer was toggled",
  NauticalChartsToggled = "The nautical charts layer was toggled",
  PortsLayerToggled = "The ports layer was toggled",
  SpottersLayerToggled = "The spotters layer was toggled",
  TropicalStormsLayerToggled = "The tropical storms layer was toggled",
  AreaConstraintsLayerToggled = "The area constraints layer was toggled",
  FleetViewVesselsToggled = "The fleet view vessel layer toggled",
  WavesLayerSelected = "The waves layer was selected",
  WindLayerSelected = "The wind layer was selected",
  WindGustLayerSelected = "The wind gust layer was selected",
  CurrentsLayerSelected = "The currents layer was selected",
  PrecipitationLayerSelected = "The precipitation layer was selected",
  VisibilityLayerSelected = "The visibility layer was selected",
  WindBarbsSelected = "The wind barbs layer was selected",
  WeatherDirectionSelected = "The weather direction option was selected",
  NoonReportFormSubmitted = "Noon report entry form submitted",
  NoonReportFormCancelled = "Noon report entry form cancelled",

  // Favorite Vessels & Selected Fleet View Vessels
  FavoriteVesselToggled = "A vessel selection in My Vessels was toggled",
  FavoriteVesselGroupToggled = "A vessel group selection in My Vessels was toggled",
  SelectedFleetViewVesselToggled = "A vessel selection in Fleet View was toggled",
  SelectedFleetViewVesselGroupToggled = "A vessel group selection in Fleet View was toggled",
  FleetViewVesselListModeChanged = "The Vessel List Mode in Fleet View was changed",

  // Efficient First Route events
  // These should all be identified with the routeRequestUuid as their distinct_id
  RouteRequestLinkFollowed = "Route Request: Link Followed",
  RouteRequestShowNearbyPortsClicked = "Route Request: Show Nearby Ports Clicked",
  RouteRequestCreatedRoute = "Route Request: Created Route",
  RouteRequestDuplicatedRoute = "Route Request: Duplicated Route",
  RouteRequestEditedInExplorer = "Route Request: Edited in Exploroer",
  RouteRequestSavedBankRoute = "Route Request: Saved Bank Route",
  RouteRequestDownloadedRoute = "Route Request: Downloaded Route",
  RouteRequestAddedRouteToVoyage = "Route Request: Added Route to Voyage",
  RouteRequestEditedInVoyage = "Route Request: Edited in Voyage",
  RouteRequestActivatedForVoyage = "Route Request: Activated for Voyage",
}

export default AnalyticsContext;

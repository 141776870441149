import { CrystalGlobeApiContext } from "contexts/CrystalGlobeApiContext";
import { getPortsQueryKey } from "helpers/crystalGlobeApi";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useInfiniteQuery } from "react-query";
import { Port, portDtoToPort } from "shared-types/PortTypes";

type PortContextType = {
  ports: Port[];
  portsByUnlocode: Record<string, Port>;
  isLoading: boolean;
  setPrimaryPorts: (portUuids: string[]) => void;
  setSecondaryPorts: (portUuids: string[]) => void;
};

const PortContextDefaults: PortContextType = {
  ports: [],
  portsByUnlocode: {},
  isLoading: false,
  setPrimaryPorts: () => null,
  setSecondaryPorts: () => null,
};

export const PortContext = React.createContext<PortContextType>(
  PortContextDefaults
);

const usePortState = (): PortContextType => {
  const { PortsApi } = useContext(CrystalGlobeApiContext);
  const [primaryPorts, setPrimaryPorts] = useState<string[]>([]);
  const [secondaryPorts, setSecondaryPorts] = useState<string[]>([]);

  // get all pages of Ports data
  const {
    data: portPages,
    isLoading: portsLoading,
    hasNextPage: canFetchMore,
    fetchNextPage: fetchMore,
    isFetching,
  } = useInfiniteQuery(
    [getPortsQueryKey()],
    async ({ pageParam }) =>
      await PortsApi.getPorts({
        cursor: pageParam,
        pageSize: 1000,
      }),
    {
      retry: 3,
      refetchInterval: false,
      getNextPageParam: (lastGroup) => {
        return lastGroup?.metadata.hasNextPage
          ? lastGroup.metadata.nextCursor
          : undefined;
      },
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  );

  useEffect(() => {
    if (canFetchMore && !isFetching) {
      fetchMore();
    }
  }, [canFetchMore, fetchMore, isFetching]);

  const ports: Port[] = useMemo(() => {
    const allPorts =
      portPages?.pages?.flatMap((p) => p?.data)?.map(portDtoToPort) ?? [];
    return allPorts.map((p) =>
      p.uuid && primaryPorts.includes(p.uuid)
        ? { ...p, variant: "primary" }
        : p.uuid && secondaryPorts.includes(p.uuid)
        ? { ...p, variant: "secondary" }
        : p
    );
  }, [portPages, primaryPorts, secondaryPorts]);

  const portsByUnlocode = useMemo(() => {
    const dict: { [key: string]: Port } = {};
    ports.forEach((port) => (dict[port.unlocode as string] = port));
    return dict;
  }, [ports]);

  return useMemo(
    () => ({
      ports,
      portsByUnlocode,
      isLoading: portsLoading,
      setPrimaryPorts,
      setSecondaryPorts,
    }),
    [ports, portsByUnlocode, portsLoading, setPrimaryPorts, setSecondaryPorts]
  );
};

export const PortContextProvider: React.FC<{}> = ({ children }) => {
  const state = usePortState();
  return <PortContext.Provider value={state}>{children}</PortContext.Provider>;
};

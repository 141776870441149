import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import WayfinderApp from "./components/WayfinderApp";
import "./styles/fonts.ts";
import "styles/print.css";
import "styles/firefox.css";
import "styles/resets.css";

const root = document.getElementById("root");
const loading = document.getElementById("bundle-loading");

root!.removeChild(loading!);

// You probably don't want to add things here unless they have to do with
// configuring React or the ServiceWorker. Add new contexts, etc to the
// `WayfinderApp` component or one of its descendents.
ReactDOM.render(
  <React.StrictMode>
    <WayfinderApp />
  </React.StrictMode>,
  root
);

import React from "react";
import useLinkStyles from "./use-link-styles";

export const TextLink: React.FC<{
  href: string;
  className?: string;
  target?: "_blank";
}> = ({ href, target, className, children }) => {
  const { classes } = useLinkStyles();

  return (
    <a
      className={`${className} ${classes.link}`}
      href={href}
      target={target}
      rel="noopener noreferrer"
    >
      {children}
    </a>
  );
};

export default TextLink;

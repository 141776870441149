import { Button, ButtonProps, Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import React, { useMemo } from "react";
import { BUTTON_STYLES } from "styles/component-variables";
import { typographyStyles, extendedPalette } from "../../styles/theme";

const useStyles = makeStyles()((theme: Theme) => ({
  contained: {
    ...typographyStyles.header,
    lineHeight: 1,
    color: "#ffff",
    backgroundColor: theme.palette.primary.main,
    boxShadow: "inherit",
    "&.Mui-disabled": {
      color: "#fff",
      backgroundColor: extendedPalette.neutralMedium,
    },

    "&:hover": {
      boxShadow: "inherit",
      backgroundColor: theme.palette.primary.main,
      filter: "drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.25))",
    },
    "&:focus-visible, &:active": {
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    },
    "& .MuiTouchRipple-root span": {
      backgroundColor: "#0050A0",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      opacity: 0.3,
    },
  },
  outlined: {
    ...typographyStyles.header,
    lineHeight: 1,
    color: "#000",
    boxShadow: "inherit",
    border: "2px solid #111111",
  },
  disabled: {
    ...typographyStyles.header,
    lineHeight: 1,
    color: "#fff",
  },
  text: {
    ...typographyStyles.title,
    color: theme.palette.primary.main,
    borderRadius: 0,

    "&:hover": {
      backgroundColor: extendedPalette.seaLight,
    },
    "&.Mui-disabled": {
      color: extendedPalette.neutralDark,
    },
  },
}));

export type WayfinderButtonProps = Omit<ButtonProps, "variant"> & {
  variant?: "primary" | "secondary" | "tertiary";
  forwardRef?: ButtonProps["ref"];
};

const variantMap = new Map<
  "primary" | "secondary" | "tertiary",
  "text" | "outlined" | "contained"
>([
  ["primary", "contained"],
  ["secondary", "outlined"],
  ["tertiary", "text"],
]);

export const WayfinderButton: React.FC<WayfinderButtonProps> = ({
  variant = "primary",
  children,
  onClick,
  forwardRef,
  ...rest
}) => {
  const { classes } = useStyles();
  const buttonClasses = useMemo(() => {
    return { ...classes, ...rest.classes };
  }, [classes, rest.classes]);

  return (
    <Button
      {...rest}
      classes={buttonClasses}
      variant={variantMap.get(variant)}
      onClick={onClick}
      sx={{
        height: "32px",
        borderRadius: "21px",
        textTransform: "initial",
        padding: `${BUTTON_STYLES.BUTTON_PADDING_Y}px ${BUTTON_STYLES.BUTTON_LABEL_PADDING_X}px`,
        ...rest.sx,
      }}
      ref={forwardRef}
    >
      {children}
    </Button>
  );
};

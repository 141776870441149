import { Switch, Theme } from "@mui/material";
import { withStyles } from "tss-react/mui";
import { extendedPalette } from "../styles/theme";

export const WayfinderSwitch = withStyles(Switch, (theme: Theme) => ({
  switchBase: {
    color: extendedPalette.neutralMedDark,
    "& + $track": {
      backgroundColor: extendedPalette.neutralMedDark,
    },
    "&$checked": {
      color: theme.palette.primary.main,
    },
    "&$checked + $track": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  checked: {},
  track: {},
}));

export default WayfinderSwitch;

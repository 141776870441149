import { useContext, useMemo } from "react";
import { isNil, isNumber } from "lodash";
import config from "config";
import { useActiveRoute } from "shared-hooks/use-active-route";
import useRoute from "contexts/RouteStoreContext/use-route";
import moment from "moment";
import { findWaypointClosestToTime } from "helpers/routes";
import useVoyage from "shared-hooks/data-fetch-hooks/use-voyage";
import { useVesselPositionHistory } from "shared-hooks/data-fetch-hooks/use-vessel-position-history";
import useAppSetting from "contexts/AppSettingsContext";
import { isInProgressRouteRequest } from "helpers/api";
import { PortContext } from "contexts/PortContext";
import { useWayfinderUrlUuids } from "shared-hooks/use-wayfinder-url";

export type TelemetryVesselPosition = {
  lat: number | undefined;
  lon: number | undefined;
  heading: number | undefined;
};

export const useLatestVesselAisPosition = (): TelemetryVesselPosition => {
  const { vesselUuid } = useWayfinderUrlUuids();
  const { latestAis } = useVesselPositionHistory(vesselUuid);
  const lon: number | undefined = vesselUuid
    ? latestAis?.longitude ?? undefined
    : undefined;
  const lat: number | undefined = vesselUuid
    ? latestAis?.latitude ?? undefined
    : undefined;
  const heading = vesselUuid
    ? getVesselHeadingForDisplay(
        latestAis?.heading ?? undefined,
        latestAis?.course ?? undefined
      )
    : undefined;

  return {
    lat,
    lon,
    heading,
  };
};

export const useLatestOrProjectedVesselPosition = (
  voyageUuid: string | undefined,
  now: Date
): TelemetryVesselPosition => {
  const { vesselUuid } = useWayfinderUrlUuids();
  const { voyage } = useVoyage(voyageUuid);
  const { routeRequest } = voyage ?? {};
  const { ports } = useContext(PortContext);
  const departurePort = isInProgressRouteRequest(routeRequest)
    ? ports.find(
        ({ unlocode }) => unlocode === routeRequest?.departurePortUnlocode
      )
    : undefined;
  const { latestAis } = useVesselPositionHistory(vesselUuid);
  const { activeRouteUuid } = useActiveRoute(voyageUuid);
  const { simulatedRoute, lookup } = useRoute(activeRouteUuid, true);
  const { value: currentVesselLongitudeOverride } = useAppSetting(
    "currentVesselLongitudeOverride"
  );

  let lon: number | undefined = vesselUuid
    ? latestAis?.longitude ?? undefined
    : undefined;
  let lat: number | undefined = vesselUuid
    ? latestAis?.latitude ?? undefined
    : undefined;
  let heading = vesselUuid
    ? getVesselHeadingForDisplay(
        latestAis?.heading ?? undefined,
        latestAis?.course ?? undefined
      )
    : undefined;

  if (
    (!latestAis ||
      // if ais data is more than 3 hours old, then use the projected location
      moment(latestAis?.eventTimestamp)
        .add(config.aisMaxAgeForComputingTimezoneHours, "hours")
        .isBefore(now)) &&
    simulatedRoute
  ) {
    const waypointId = findWaypointClosestToTime(
      now,
      undefined,
      simulatedRoute,
      { clampTimeToRouteSchedule: true }
    );
    const { position, extensions } =
      (isNumber(waypointId) &&
        lookup?.simulatedRouteWaypoints?.byId?.[waypointId]) ||
      (simulatedRoute?.waypoints.waypoints.find((w) => w.id === waypointId) ??
        {});
    const { simulatedCourse } = extensions ?? {};
    lon = position?.lon ?? undefined;
    lat = position?.lat ?? undefined;
    heading = simulatedCourse;
  }
  const lonWithOverride = useMemo(() => {
    const currentVesselLongitudeOverrideNumber = parseFloat(
      currentVesselLongitudeOverride
    );
    return !isNaN(currentVesselLongitudeOverrideNumber)
      ? currentVesselLongitudeOverrideNumber
      : lon;
  }, [currentVesselLongitudeOverride, lon]);

  return useMemo(
    () => ({
      // Only fallback to route request departure port coordinates if both lat and lon are missing
      lat:
        !isNil(lat) && !isNil(lonWithOverride) ? lat : departurePort?.latitude,
      lon:
        !isNil(lat) && !isNil(lonWithOverride)
          ? lonWithOverride
          : departurePort?.longitude,
      heading,
    }),
    [heading, lat, lonWithOverride, departurePort]
  );
};

export function getVesselHeadingForDisplay(
  heading: number | undefined,
  course: number | undefined
) {
  return (
    // course is most reliable in telemetry data, and sometimes heading is set to 0 even if it is just missing
    heading || // if heading is missing or 0 use course
    course
  ); // because if the heading is really 0, then course will probably be 0 too
}

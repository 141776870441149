import React, { useContext, useMemo } from "react";
import {
  CurrentSessionData,
  currentSessionDataDefaults,
} from "./use-current-session";

export type CurrentVoyageLegContextType = CurrentSessionData["dataRecords"]["voyageLeg"];
export const CurrentVoyageLegContextDefaults: CurrentVoyageLegContextType =
  currentSessionDataDefaults.dataRecords.voyageLeg;
export const CurrentVoyageLegContext = React.createContext<CurrentVoyageLegContextType>(
  CurrentVoyageLegContextDefaults
);
export const useCurrentVoyageLeg = () => {
  const { data, isLoading } = useContext(CurrentVoyageLegContext);
  return useMemo(() => ({ voyage: data, isLoading }), [data, isLoading]);
};

export type CurrentMultiLegVoyageContextType = CurrentSessionData["dataRecords"]["multilegVoyage"];
export const CurrentMultiLegVoyageContextDefaults: CurrentMultiLegVoyageContextType =
  currentSessionDataDefaults.dataRecords.multilegVoyage;
export const CurrentMultiLegVoyageContext = React.createContext<CurrentMultiLegVoyageContextType>(
  CurrentMultiLegVoyageContextDefaults
);
export const useCurrentMultiLegVoyage = () => {
  const { data, isLoading } = useContext(CurrentMultiLegVoyageContext);
  return useMemo(() => ({ data, isLoading }), [data, isLoading]);
};

export type CurrentVesselContextType = CurrentSessionData["dataRecords"]["vessel"];
export const CurrentVesselContextDefaults: CurrentVesselContextType =
  currentSessionDataDefaults.dataRecords.vessel;
export const CurrentVesselContext = React.createContext<CurrentVesselContextType>(
  CurrentVesselContextDefaults
);
export const useCurrentVessel = () => {
  const { data, isLoading, isError } = useContext(CurrentVesselContext);
  return useMemo(() => ({ vessel: data, isLoading, isError }), [
    data,
    isError,
    isLoading,
  ]);
};

export type CurrentVesselUpdatesContextType = CurrentSessionData["dataRecords"]["vesselUpdates"];
export const CurrentVesselUpdatesContextDefaults: CurrentVesselUpdatesContextType =
  currentSessionDataDefaults.dataRecords.vesselUpdates;
export const CurrentVesselUpdatesContext = React.createContext<CurrentVesselUpdatesContextType>(
  CurrentVesselUpdatesContextDefaults
);
export const useCurrentVesselUpdates = () => {
  const { data, isLoading, isError } = useContext(CurrentVesselUpdatesContext);
  return useMemo(() => ({ vesselUpdates: data, isLoading, isError }), [
    data,
    isError,
    isLoading,
  ]);
};

export type CurrentActiveRouteContextType = {
  currentSessionIsLoading: CurrentSessionData["currentSessionIsLoading"];
  routeStoreObject: CurrentSessionData["dataRecords"]["activeRoute"];
  activeRouteUuid: CurrentSessionData["activeRouteUuid"];
};
export const CurrentActiveRouteContextDefaults: CurrentActiveRouteContextType = {
  currentSessionIsLoading: currentSessionDataDefaults.currentSessionIsLoading,
  routeStoreObject: currentSessionDataDefaults.dataRecords.activeRoute,
  activeRouteUuid: currentSessionDataDefaults.activeRouteUuid,
};
export const CurrentActiveRouteContext = React.createContext<CurrentActiveRouteContextType>(
  CurrentActiveRouteContextDefaults
);
export const useCurrentActiveRoute = () => {
  return useContext(CurrentActiveRouteContext);
};

export type CurrentSuggestedRouteContextType = {
  currentSessionIsLoading: CurrentSessionData["currentSessionIsLoading"];
  suggestedRouteUuid: CurrentSessionData["suggestedRouteUuid"];
  routeStoreObject: CurrentSessionData["dataRecords"]["suggestedRoute"];
  routeSuggestion: CurrentSessionData["dataRecords"]["routeSuggestion"];
};
export const CurrentSuggestedRouteContextDefaults: CurrentSuggestedRouteContextType = {
  currentSessionIsLoading: currentSessionDataDefaults.currentSessionIsLoading,
  suggestedRouteUuid: currentSessionDataDefaults.suggestedRouteUuid,
  routeStoreObject: currentSessionDataDefaults.dataRecords.suggestedRoute,
  routeSuggestion: currentSessionDataDefaults.dataRecords.routeSuggestion,
};
export const CurrentSuggestedRouteContext = React.createContext<CurrentSuggestedRouteContextType>(
  CurrentSuggestedRouteContextDefaults
);
export const useCurrentSuggestedRoute = () => {
  return useContext(CurrentSuggestedRouteContext);
};

export type CurrentRoutesToCompareContextType = {
  routesToCompare: CurrentSessionData["dataRecords"]["routesToCompare"];
  routeUuidsToCompare: string[];
};
export const CurrentRoutesToCompareContextDefaults: CurrentRoutesToCompareContextType = {
  routesToCompare: currentSessionDataDefaults.dataRecords.routesToCompare,
  routeUuidsToCompare: [],
};
export const CurrentRoutesToCompareContext = React.createContext<CurrentRoutesToCompareContextType>(
  CurrentRoutesToCompareContextDefaults
);
export const useCurrentRoutesToCompare = () => {
  return useContext(CurrentRoutesToCompareContext);
};

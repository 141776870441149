import {
  RouteRequestDto,
  RouteRequestStatus,
  RtzJsonDto,
  ScheduleDto,
  WaypointDto,
} from "@sofarocean/wayfinder-typescript-client";
import { isNil } from "lodash";
import { match } from "ts-pattern";
import {
  GeometryType,
  Route,
  Schedule,
  Waypoint,
} from "../shared-types/RouteTypes";

export const isInProgressRouteRequest = (
  routeRequest?: RouteRequestDto | null
): routeRequest is RouteRequestDto =>
  !isNil(routeRequest) &&
  routeRequest.status !== RouteRequestStatus.Completed &&
  routeRequest.status !== RouteRequestStatus.Cancelled;

const convertWaypointDtoToWaypoint = (
  waypointDto: WaypointDto
): { waypoint: Waypoint; errors: Error[] } => {
  const errors: Error[] = [];
  return {
    waypoint: {
      ...waypointDto,
      leg: {
        ...waypointDto.leg,
        geometryType: waypointDto.leg?.geometryType
          ? ((geometryType: GeometryType | string | undefined): GeometryType =>
              match(geometryType)
                .with("Loxodrome", "Orthodrome", (geoType) => geoType)
                .otherwise(() => {
                  errors.push(
                    Error(`Found leg with geometryType: ${geometryType}`)
                  );
                  return "Orthodrome" as const;
                }))(waypointDto.leg?.geometryType)
          : undefined,
      },
    },
    errors,
  };
};

const convertScheduleDtoToSchedule = (scheduleDto: ScheduleDto): Schedule => {
  const { manual, calculated, ...scheduleDtoRemainder } = scheduleDto;
  const { scheduleElements: manualScheduleElements, ...manualRemainder } =
    scheduleDto.manual ?? {};
  const {
    scheduleElements: calculatedScheduleElements,
    ...calculatedRemainder
  } = scheduleDto.calculated ?? {};
  return {
    ...scheduleDtoRemainder,
    ...(manual
      ? {
          manual: {
            ...manualRemainder,
            scheduleElements: scheduleDto.manual?.scheduleElements || [],
          },
        }
      : undefined),
    ...(calculated
      ? {
          calculated: {
            ...calculatedRemainder,
            scheduleElements: scheduleDto.calculated?.scheduleElements || [],
          },
        }
      : undefined),
  };
};

export const convertRtzJsonDtoToRoute = ({
  rtzJsonDto,
  routeId,
  routeName,
  includeSchedule = true,
}: {
  rtzJsonDto: RtzJsonDto;
  routeId?: string;
  routeName?: string;
  includeSchedule?: boolean;
}): { rtzRoute: Route; errors: Error[] } => {
  const errors: Error[] = [];
  return {
    rtzRoute: {
      ...rtzJsonDto,
      routeInfo: {
        ...rtzJsonDto.routeInfo,
        routeName: routeName ?? rtzJsonDto.routeInfo.routeName,
      },
      waypoints: {
        waypoints:
          rtzJsonDto.waypoints.waypoints?.map((waypointDto: WaypointDto) => {
            const {
              waypoint,
              errors: waypointErrors,
            } = convertWaypointDtoToWaypoint(waypointDto);
            errors.push(...waypointErrors);
            return waypoint;
          }) ?? [],
      },
      extensions: {
        ...rtzJsonDto.extensions,
        readonly: true,
        uuid: routeId ?? rtzJsonDto.extensions?.uuid,
      },
      schedules: {
        schedules: includeSchedule
          ? rtzJsonDto.schedules?.schedules?.map(convertScheduleDtoToSchedule)
          : [],
      },
    },
    errors,
  };
};

export const convertWaypointsDtoToRoute = ({
  waypoints,
  routeId,
  routeName,
}: {
  waypoints: WaypointDto[];
  routeId: string;
  routeName: string;
}): { rtzRoute: Route; errors: Error[] } => {
  const errors: Error[] = [];
  return {
    rtzRoute: {
      version: "1.0",
      routeInfo: { routeName },
      waypoints: {
        waypoints:
          waypoints.map((waypointDto) => {
            const {
              waypoint,
              errors: waypointErrors,
            } = convertWaypointDtoToWaypoint(waypointDto);
            errors.push(...waypointErrors);
            return waypoint;
          }) ?? [],
      },
      extensions: {
        uuid: routeId,
        readonly: true,
      },
      schedules: { schedules: [] },
    },
    errors,
  };
};

export const convertWaypointsDtoToRtzJsonDto = ({
  waypoints,
  routeId,
  routeName,
}: {
  waypoints: WaypointDto[];
  routeId: string;
  routeName: string;
}): { rtzJsonDto: RtzJsonDto } => {
  return {
    rtzJsonDto: {
      version: "1.0",
      routeInfo: { routeName },
      waypoints: {
        waypoints,
      },
      extensions: {
        uuid: routeId,
      },
      schedules: { schedules: [] },
    },
  };
};

import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useLinkStyles = makeStyles()((theme: Theme) => ({
  link: {
    textDecoration: "none",
    color: theme.palette.primary.main,
    "&.active, &:hover": {
      color: theme.palette.primary.main,
    },
  },
}));

export default useLinkStyles;

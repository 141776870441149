import {
  RouteDataLookups,
  RouteMetadata,
  RouteStoreObject,
  TimestampedRouteData,
} from "contexts/RouteStoreContext/state-types";
import { useContext, useEffect, useMemo } from "react";
import { useStableDeepComparedReference } from "shared-hooks/use-stable-deepcompared-reference";
import {
  RouteStoreContext,
  useRouteStoreDispatch,
} from "contexts/RouteStoreContext";
import { RouteSummaryData } from "helpers/routeSummary";
import { Route, SimulatedRoute } from "../../shared-types/RouteTypes";

export type UseManyRoutesResult = {
  voyageUuid?: string;
  uuid: string;
  route: Route | undefined;
  simulatedRoute: SimulatedRoute | undefined;
  routeSummaryData: RouteSummaryData | undefined;
  timestampedRouteData: TimestampedRouteData | undefined;
  metadata:
    | Omit<
        RouteMetadata,
        "simulationSubscriberReferenceCount" | "subscriberReferenceCount"
      >
    | undefined;
  lookup: RouteDataLookups | undefined;
}[];

/** Hook used for getting data from multiple routes at once.
 *
 *  This can be useful if you have a component that needs to perform comparison
 *  calculations between multiple `Route`s' data.
 *
 *  If all you need is data from a single `Route`, use the `useRoute` hook
 *  instead.
 */
const useManyRoutes = (
  /** The Uuids of the Routes the consuming component cares about. */
  routeUuids: string[],
  /** Set this flag to `true` if the consuming component cares about getting
   *  up-to-date simulated route data for each route.
   */
  withSimulationData: boolean = false,
  /** Optional parameter to specify the voyageUuid for these routes. This is
   *  only used temporarily as a hint to the route pre-loader because the
   *  Crystal Globe server needs both a routeUuid and voyageUuid to fetch a route.
   *  Once the routes are pre-loaded, changing this value or leaving it blank
   *  should have no practical effect
   */
  voyageUuid: string | null = null,
  debug?: boolean
): UseManyRoutesResult => {
  // Even though this component does not return any state-updating callbacks,
  // we need `dispatch` to subscribe/unsubscribe from simulator data.
  const dispatch = useRouteStoreDispatch();
  const voyages = useContext(RouteStoreContext);

  // Let the global store know we want to subscribe to each of the Routes.
  // This will automatically lazy-load data for each route if needed.
  useEffect(() => {
    routeUuids.forEach((r) => {
      dispatch({
        type: "changeRouteSubscription",
        payload: {
          routeUuid: r,
          change: "subscribe",
        },
      });
    });

    return () => {
      routeUuids.forEach((r) => {
        dispatch({
          type: "changeRouteSubscription",
          payload: {
            routeUuid: r,
            change: "unsubscribe",
          },
        });
      });
    };
  }, [routeUuids, dispatch, voyageUuid]);

  // If the hook's args request simulator data, let our central store know
  // we care about the simulation results. The app should only run simulations
  // on routes where more than one mounted component is subscribed to simulator
  // results.
  useEffect(() => {
    if (withSimulationData) {
      routeUuids.forEach((r) => {
        dispatch({
          type: "changeSimulatedRouteSubscription",
          payload: {
            routeUuid: r,
            change: "subscribe",
          },
        });
      });

      return () => {
        routeUuids.forEach((r) => {
          dispatch({
            type: "changeSimulatedRouteSubscription",
            payload: {
              routeUuid: r,
              change: "unsubscribe",
            },
          });
        });
      };
    }
  }, [withSimulationData, routeUuids, dispatch]);

  // Return the matching Routes from the global store
  // Memoizing this with useCallback lets useWayfinderSelector avoid rerunning it if nothing it depends on has changed
  const routes = useMemo(
    () =>
      routeUuids
        .map((r) => ({ uuid: r, result: voyages.routes[r] }))
        .map(
          ({
            uuid,
            result,
          }: {
            uuid: string;
            result: RouteStoreObject | undefined;
          }) => {
            // remove subscriber counts to prevent deepcompare changes
            let metadata;
            const voyageUuid = result?.voyageUuid ?? undefined;
            if (result?.metadata) {
              const {
                simulationSubscriberReferenceCount,
                subscriberReferenceCount,
                ...rest
              } = result.metadata;
              metadata = rest;
            }
            return result
              ? {
                  voyageUuid,
                  uuid,
                  route: result.data.route,
                  simulatedRoute: withSimulationData
                    ? result.data.simulatedRoute
                    : undefined,
                  routeSummaryData: result.data.routeSummaryData,
                  timestampedRouteData: result.data.timestampedRouteData,
                  metadata,
                  lookup: result.data.lookup,
                }
              : {
                  voyageUuid,
                  uuid,
                  route: undefined,
                  simulatedRoute: undefined,
                  routeSummaryData: undefined,
                  metadata: undefined,
                  lookup: undefined,
                  timestampedRouteData: undefined,
                };
          }
        ),
    [routeUuids, voyages.routes, withSimulationData]
  );
  // FIXME eliminate this if possible
  // The array ref coming out of this selector is not stable. Even if contents are the same.
  const stableRoutes = useStableDeepComparedReference(routes, debug);
  return stableRoutes;
};
export default useManyRoutes;

import React from "react";
import { AuthenticationContext } from "contexts/AuthenticationContext";
import { useAuthenticationState } from "../../contexts/AuthenticationContext/useAuthenticationState";

/**
 * Check if user is authenticated before rendering the app.
 */
export const AuthenticationProvider: React.FC<{}> = ({ children }) => {
  const authenticationState = useAuthenticationState();
  return (
    <AuthenticationContext.Provider value={authenticationState}>
      {children}
    </AuthenticationContext.Provider>
  );
};

import { IdToken, useAuth0 } from "@auth0/auth0-react";
import {
  Configuration,
  CurrentUserApi,
} from "@sofarocean/wayfinder-typescript-client";
import { errorReportingFetch } from "contexts/CrystalGlobeApiContext";
import {
  defaultBase,
  useWayfinderBaseUrl,
} from "contexts/CrystalGlobeApiContext/use-wayfinder-base";
import { getUserQueryKey } from "helpers/crystalGlobeApi";
import { consoleAndSentryError } from "helpers/error-logging";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useQueryParam } from "use-query-params";
import config from "../../config";
import { FeatureName, featurePermissions } from "./permissions";
import { AuthenticationContextType, UserMetadata } from ".";

export const useAuthenticationState = (): AuthenticationContextType => {
  const auth0 = useAuth0();
  const {
    getIdTokenClaims,
    getAccessTokenSilently,
    loginWithRedirect: loginWithRedirectBase,
    logout,
    error,
    isLoading: auth0IsLoading,
  } = auth0;
  const [user, setUser] = useState(auth0.user);
  const [isAuthenticated, setIsAuthenticated] = useState(auth0.isAuthenticated);
  useEffect(() => setUser(auth0.user), [auth0.user]);
  useEffect(() => setIsAuthenticated(auth0.isAuthenticated), [
    auth0.isAuthenticated,
  ]);

  const [metadata, setMetadata] = useState<UserMetadata | undefined>();
  const [invitation] = useQueryParam<string>("invitation");
  const [organization] = useQueryParam<string>("organization");
  const [organization_name] = useQueryParam<string>("organization_name");
  const [metadataIsLoading, setMetadataIsLoading] = useState(true);
  const [token, setToken] = useState<string | undefined>();
  const [tokenIsLoading, setTokenIsloading] = useState(true);
  const getLoginOptions = useCallback(
    () => ({
      appState: {
        returnTo: `${window.location.pathname}${window.location.search}`,
        invitation,
        organization,
        organization_name,
      },
    }),
    [invitation, organization, organization_name]
  );
  const loginWithRedirect = useCallback(
    () => loginWithRedirectBase(getLoginOptions()),
    [getLoginOptions, loginWithRedirectBase]
  );
  const logoutWithRedirect = useCallback(
    () => logout({ returnTo: `${window.location.origin}/authenticate` }),
    [logout]
  );
  const updateToken = useCallback(async () => {
    if (!auth0IsLoading) {
      let currentToken;
      let filteredMetadata;
      try {
        setTokenIsloading(true);
        currentToken = await getAccessTokenSilently();
      } catch (e: any) {
        // if there is an exception getting the token, it means user is not logged in
        console.warn("User is not logged in.");
        loginWithRedirect();
      }
      setToken(currentToken);
      setTokenIsloading(false);
      try {
        setMetadataIsLoading(true);
        const currentMetadata: IdToken | undefined = await getIdTokenClaims();
        if (!currentMetadata) throw Error("Failed to get user metadata");
        // Pull out relevant fields
        // The auth0 API requires us to prefix the user's metadata fields with a url when adding them to the idToken
        // For now we have set this to the prefix here. In the future, we may delegate user metadata to our API
        // in a more orderly typed fashion
        const prefix = "https://sofarocean.com/";
        filteredMetadata = Object.fromEntries(
          (Object.keys(currentMetadata) as string[])
            .filter((k) => k.indexOf(prefix) === 0)
            .map((k) => [k.replace(prefix, ""), currentMetadata?.[k]])
            .map(([k, value]) => [k, value])
        ) as UserMetadata;
      } catch (e: any) {
        if (currentToken)
          consoleAndSentryError(
            "Could not load user metadata for logged in user"
          );
      }
      setMetadata({ ...config.defaultUserMetadata, ...filteredMetadata });
      setMetadataIsLoading(false);
    }
  }, [
    auth0IsLoading,
    getAccessTokenSilently,
    getIdTokenClaims,
    loginWithRedirect,
  ]);
  useEffect(() => {
    updateToken();
  }, [updateToken]);
  useEffect(() => {
    console.warn("User logged in:", user);
  }, [user]);

  const authorizationHeaders = useMemo(
    () => ({
      Authorization: `Bearer ${token}`,
    }),
    [token]
  );

  const { wayfinderBaseUrl } = useWayfinderBaseUrl();

  const currentUserApi = useMemo(
    () =>
      new CurrentUserApi(
        new Configuration({
          basePath: wayfinderBaseUrl || defaultBase,
          fetchApi: errorReportingFetch("CurrentUserApi", {
            headers: authorizationHeaders,
          }),
        })
      ),
    [wayfinderBaseUrl, authorizationHeaders]
  );

  const {
    data: wayfinderApiCurrentUser,
    isLoading: currentUserIsLoading,
    isError: currentUserIsError,
  } = useQuery(
    getUserQueryKey(),
    () => currentUserApi.getCurrentUserInfo({ headers: authorizationHeaders }),
    {
      retry: 3,
      enabled: !tokenIsLoading && Boolean(token),
      refetchInterval: config.currentUserPollIntervalMs,
    }
  );

  const authenticationIsLoading =
    auth0IsLoading ||
    tokenIsLoading ||
    metadataIsLoading ||
    (currentUserIsLoading && !currentUserIsError);

  const featureIsAllowed = useCallback(
    (featureName: FeatureName) => {
      return Boolean(
        ((wayfinderApiCurrentUser?.permissions as unknown) as
          | string[]
          | undefined)?.includes(featurePermissions[featureName])
      );
    },
    [wayfinderApiCurrentUser]
  );

  return useMemo(
    () => ({
      token,
      loginWithRedirect,
      logoutWithRedirect,
      authenticationError: error,
      isAuthenticated,
      authenticationIsLoading,
      user,
      metadata,
      metadataIsLoading,
      auth0IsLoading,
      authorizationHeaders,
      featureIsAllowed,
      wayfinderApiCurrentUser,
    }),
    [
      token,
      loginWithRedirect,
      logoutWithRedirect,
      error,
      isAuthenticated,
      authenticationIsLoading,
      user,
      metadata,
      metadataIsLoading,
      auth0IsLoading,
      authorizationHeaders,
      featureIsAllowed,
      wayfinderApiCurrentUser,
    ]
  );
};

import { Box, Container } from "@mui/material";
import React from "react";
import logo from "bundle-data/images/Wayfinder-by-sofar-logo.png";
import { WayfinderTypography } from "DLS";

const NotFound: React.FC = () => (
  <Container>
    <Box
      display="flex"
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
      height="100vh"
      width="100%"
    >
      <Box marginBottom="1em">
        <img src={logo} alt={"Sofar Wayfinder"} style={{ maxWidth: "50vw" }} />
      </Box>

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        marginTop="15px"
      >
        <WayfinderTypography
          variant="displayLarge"
          sx={{
            fontSize: 28,
          }}
        >
          Whoops! We couldn't find that page. Click <a href="/">here</a> to go
          back to the home page.
        </WayfinderTypography>
      </Box>
    </Box>
  </Container>
);

export default NotFound;

import React, { useContext, useMemo } from "react";
import { Story as StoryType } from "@storybook/react/types-6-0";
import useRoute from "../use-route";

type ContextualRouteContextType = {
  voyageUuid?: string;
  routeUuid?: string;
};

// Don't export this directly— should only be used via the hooks below
const ContextualRouteContext = React.createContext<ContextualRouteContextType>(
  {}
);

/** Get a reference to the "contextual" route — a route that is shared by an
 *  ancestor of this component. Useful to avoid prop drilling while still using
 *  the `useRoute` hook under the hood.
 */
export const useContextualRoute = (withSimulation = false) => {
  const { routeUuid } = useContext(ContextualRouteContext);

  return useRoute(routeUuid, withSimulation);
};

/** Get a pre-built context provider that injects the specified routeUuid and
 *  voyageUuid into the "contextual" route context. When this provider is
 *  mounted, all its ancestors will have a reference to this route and voyage
 *  and can access that directly via the `useContextualRoute` hook.
 */
export const useContextualRouteProvider = (
  routeUuid?: string,
  voyageUuid?: string
) => {
  return useMemo(
    (): React.FC<{}> => ({ children }) => (
      <ContextualRouteContext.Provider value={{ voyageUuid, routeUuid }}>
        {children}
      </ContextualRouteContext.Provider>
    ),
    [routeUuid, voyageUuid]
  );
};

// Having the Decorator here breaks the pattern that we have in the app.
// The problem is that the 'ContextualRouteContext' is not exported.
// We can't use the 'useContextualRouteProvider' above as a Storybook decorator cannot use a hook as it
// is not a function component.
export const ContextualRouteDecorator = (
  voyageUuid?: string,
  routeUuid?: string
) => (Story: StoryType) => {
  return (
    <ContextualRouteContext.Provider value={{ voyageUuid, routeUuid }}>
      <Story />
    </ContextualRouteContext.Provider>
  );
};

import * as React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useEffect } from "react";
import { consoleAndSentryError } from "helpers/error-logging";
import {
  ErrorContextProvider,
  ErrorContextType,
} from "../../contexts/ErrorContext";
import ErrorDialog from "./ErrorDialog";

export type GlobalErrorHandlerProps = {
  state: ErrorContextType;
};

const getResetFunction = (resetErrorBoundary: () => void) => {
  return () => {
    resetErrorBoundary();
    window.document.location.replace("/");
  };
};

const ErrorFallback = ({
  error,
  resetErrorBoundary,
}: {
  error: Error;
  resetErrorBoundary: () => void;
}) => {
  // Notify Sentry every time we show the global error dialog
  // because we definitely want to know when users are seeing this
  useEffect(() => {
    if (error) {
      // Pass an error that indicates the dialog was shown, but retain the original
      // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error/cause
      consoleAndSentryError(
        new Error(`Global error dialog shown: ${error.message}`, {
          cause: error,
        }),
        error
      );
    }
  }, [error]);
  return (
    <ErrorDialog
      showError={true}
      showDetails={true}
      showTitle={true}
      error={error}
      reloadPage={getResetFunction(resetErrorBoundary)}
    />
  );
};

/**
 * Global error handler that displays uncaught errors from react or
 * errors that where set via @see ErrorContext.
 *
 */
const GlobalErrorHandler: React.FC<GlobalErrorHandlerProps> = ({
  children,
  state,
}) => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <ErrorContextProvider value={state}>{children}</ErrorContextProvider>
    </ErrorBoundary>
  );
};

export default GlobalErrorHandler;

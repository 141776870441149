import React, { useContext } from "react";
import FallbackLoadingScreen from "screens/FallbackLoadingScreen";
import { AuthenticationContext } from "../../contexts/AuthenticationContext";

/**
 * Check if user is authenticated before rendering the app.
 */
export const AuthenticationGuard: React.FC<{}> = ({ children }) => {
  const authenticationState = useContext(AuthenticationContext);
  const {
    authenticationIsLoading,
    isAuthenticated,
    wayfinderApiCurrentUser,
  } = authenticationState;
  const userNotAuthenticated =
    !authenticationIsLoading && (!isAuthenticated || !wayfinderApiCurrentUser);

  return (
    // if auth is loading
    // then wait before returning the app
    authenticationIsLoading ||
      // once nothing is loading, refuse to return the app if user is not authenticated
      userNotAuthenticated ? (
      <FallbackLoadingScreen
        message={
          userNotAuthenticated ? "Could not authenticate." : "Authenticating"
        }
        animate={!userNotAuthenticated}
      />
    ) : (
      <AuthenticationContext.Provider value={authenticationState}>
        {children}
      </AuthenticationContext.Provider>
    )
  );
};

import { featureCollection } from "@turf/helpers";
import intersect from "@turf/intersect";
import { Position } from "geojson";
import { GM_Point, Waypoint } from "../shared-types/RouteTypes";
import { nauticalMilesToKm } from "./units";

export const POSITION_IMPORT_DECIMAL_PRECISION = 5;

/*
 * Helper to calculate the area of a polygon given its coordinates
 *
 * This is used in sorting the polygons by area in the map layer to ensure
 * that smaller polygons are drawn on top of larger ones and are clickable
 * Algorithm from https://stackoverflow.com/questions/16285134/calculating-polygon-area
 */
export const calculatePolygonArea = (coordinates: Position[]): number => {
  let total = 0;
  for (let i = 0, l = coordinates.length; i < l; i++) {
    const addX = coordinates[i][0];
    const addY = coordinates[i == coordinates.length - 1 ? 0 : i + 1][1];
    const subX = coordinates[i == coordinates.length - 1 ? 0 : i + 1][0];
    const subY = coordinates[i][1];

    total += addX * addY * 0.5;
    total -= subX * subY * 0.5;
  }

  return Math.abs(total);
};

export const generateCircleGeometry = ({
  center,
  radiusNm,
  numVertices,
}: {
  center: Position;
  radiusNm: number;
  numVertices?: number;
}): Position[] => {
  const earthRadiusKm = 6371;
  const coordinates: Position[] = [];
  const radiusKm = nauticalMilesToKm(radiusNm);

  // If vertex count isn't provided, cap the number of vertices to a
  // reasonable number, but ideally use less if the radius of the circle
  // is small-ish (although not less than 16)
  const vertices =
    numVertices || Math.min(Math.max(Math.round(radiusNm / 3), 16), 64);

  for (let i = 0; i < vertices; i++) {
    const angle = (i * 2 * Math.PI) / vertices; // Angle for each vertex
    const dLat = (radiusKm / earthRadiusKm) * Math.cos(angle); // Displacement in latitude
    const dLng =
      (radiusKm / (earthRadiusKm * Math.cos((center[1] * Math.PI) / 180))) *
      Math.sin(angle); // Displacement in longitude

    const vertexLon = center[0] + (dLng * 180) / Math.PI;
    const vertexLat = center[1] + (dLat * 180) / Math.PI;

    coordinates.push([vertexLon, vertexLat]);
  }

  if (coordinates.length) {
    // mapbox-gl-draw Polygons have a ring structure where the last element
    // is the same as the first one, so we're copying it here.
    coordinates.push(coordinates[0]);
  }

  return coordinates;
};

export function nearestPointOnLine(
  position: GM_Point,
  start: Waypoint,
  end: Waypoint
) {
  const segmentVector = difference(end.position, start.position);
  const segmentLength = magnitude(segmentVector.lat, segmentVector.lon);
  const segmentUnitVector = scale(segmentVector, 1 / segmentLength);
  const positionVector = difference(position, start.position);
  const positionSegmentComponent = dotProduct(
    positionVector,
    segmentUnitVector
  );
  return sum(
    start.position,
    scale(segmentUnitVector, positionSegmentComponent)
  );
}

export function dotProduct(v1: GM_Point, v2: GM_Point) {
  return v1.lat * v2.lat + v1.lon * v2.lon;
}

export function difference(v1: GM_Point, v2: GM_Point) {
  return {
    lat: v1.lat - v2.lat,
    lon: v1.lon - v2.lon,
  };
}

export function sum(v1: GM_Point, v2: GM_Point) {
  return {
    lat: v1.lat + v2.lat,
    lon: v1.lon + v2.lon,
  };
}

export function scale(v1: GM_Point, s: number) {
  return {
    lat: v1.lat * s,
    lon: v1.lon * s,
  };
}

export function magnitude(x: number, y: number) {
  return Math.sqrt(x * x + y * y);
}

export function vectorAngleInDegrees(x: number, y: number): number {
  const angleInRads = Math.atan2(y, x);
  return angleInRads * (180 / Math.PI);
}

// findSparsestAngle determines the angle that farthest away from all other given angles
export function findSparsestAngle(angles: number[]): number {
  const normalizedAngles = angles
    .map((angle) => normalizeAngleWithinPlusMinus180(angle))
    .sort((a, b) => a - b);

  // add first angle at end to compare last angle to first
  normalizedAngles.push(normalizedAngles[0] + 360);

  let largestGap = 0;
  let largestGapIndex = 0;
  for (let i = 0; i < normalizedAngles.length - 1; i++) {
    const gap = normalizedAngles[i + 1] - normalizedAngles[i];
    if (gap > largestGap) {
      largestGap = gap;
      largestGapIndex = i;
    }
  }

  // farthest angle is the mid angle of the largest gap between two angles
  return normalizedAngles[largestGapIndex] + largestGap / 2;
}

export function pointToPosition(point: { x: number; y: number }) {
  return [point.x, point.y];
}

export function normalizePointLongitude(point: GM_Point) {
  return { ...point, lon: normalizeLongitude(point.lon) };
}

export function normalizeLongitude(lon: number) {
  return normalizeAngleWithinPlusMinus180(lon);
}

export function normalizeAngleWithinPlusMinus180(angle: number) {
  while (angle > 180) angle -= 360;
  while (angle < -180) angle += 360;
  return angle;
}

const WEB_MERCATOR_MAX_LATITUDE = 89;
const LONGITUDE_LIMIT = 179.999999999999;
/** Slice the input polygons so they do not cross the antimeridian and all the
 *  coordinates fit in the expected longitude range of [-180, 180].
 */
export function slicePolygonsToSingleWorldCopies(
  geometry:
    | GeoJSON.Polygon
    | GeoJSON.MultiPolygon
    | GeoJSON.Feature<GeoJSON.Polygon | GeoJSON.MultiPolygon>
) {
  const out = [];
  for (let i = -1; i <= 1; i++) {
    const world = {
      type: "Polygon" as const,
      coordinates: [
        [
          [-LONGITUDE_LIMIT + 360 * i, WEB_MERCATOR_MAX_LATITUDE],
          [LONGITUDE_LIMIT + 360 * i, WEB_MERCATOR_MAX_LATITUDE],
          [LONGITUDE_LIMIT + 360 * i, -WEB_MERCATOR_MAX_LATITUDE],
          [-LONGITUDE_LIMIT + 360 * i, -WEB_MERCATOR_MAX_LATITUDE],
          [-LONGITUDE_LIMIT + 360 * i, WEB_MERCATOR_MAX_LATITUDE],
        ],
      ],
    };
    const intersection = intersect(geometry, world);
    if (!intersection) continue;

    // Shift intersection over into the correct range
    if (i !== 0) {
      if (intersection?.geometry.type === "Polygon") {
        intersection.geometry.coordinates[0] = intersection.geometry.coordinates[0].map(
          (c) => [c[0] - i * 360, c[1]]
        );
      } else if (intersection?.geometry.type === "MultiPolygon") {
        intersection.geometry.coordinates[0] = intersection.geometry.coordinates[0].map(
          (ring) => ring.map((c) => [c[0] - i * 360, c[1]])
        );
      }
    }
    out.push(intersection);
  }
  return featureCollection(out);
}

export function toGM_Point(lngLat: mapboxgl.LngLat | number[]): GM_Point {
  if (Array.isArray(lngLat)) {
    return { lon: lngLat[0], lat: lngLat[1] };
  } else {
    const { lng, lat } = lngLat;
    return { lon: lng, lat };
  }
}

export function pointInRect(point: { x: number; y: number }, rect: ClientRect) {
  return (
    point.x > rect.left &&
    point.x < rect.left + rect.width &&
    point.y > rect.top &&
    point.y < rect.top + rect.height
  );
}

import useAppSetting from "contexts/AppSettingsContext";
import { AuthenticationContext } from "contexts/AuthenticationContext";
import { useContext, useMemo } from "react";

export const useFleetViewVisibility = () => {
  const { value: showFleetViewToAllUsers } = useAppSetting(
    "showFleetViewToAllUsers"
  );
  const { featureIsAllowed } = useContext(AuthenticationContext);
  return useMemo(
    () => showFleetViewToAllUsers || featureIsAllowed("fleetView"),
    [featureIsAllowed, showFleetViewToAllUsers]
  );
};

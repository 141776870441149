import { Button } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import React, { useCallback, useContext, useEffect } from "react";
import { AuthenticationContext } from "contexts/AuthenticationContext/index";
import logo from "bundle-data/images/Wayfinder-by-sofar-logo.png";
import { WayfinderTypography } from "DLS";
import { useStyles as useFallbackStyles } from "../FallbackLoadingScreen";

const useStyles = makeStyles()(() => ({
  text: { padding: 30 },
  buttonContainer: {
    textAlign: "center",
    width: 400,
  },
  button: { display: "inline", margin: "0 8px" },
}));

/**
 * Redirects to login if the user is logged out
 * Shows a logout button in confirmation text if the user is logged in
 */
export const AuthenticationScreen: React.FC = () => {
  const {
    loginWithRedirect,
    logoutWithRedirect,
    isAuthenticated,
    authenticationIsLoading,
  } = useContext(AuthenticationContext);
  const { classes: fallbackClasses } = useFallbackStyles();
  const { classes } = useStyles();
  const onClickLogout = useCallback(() => logoutWithRedirect(), [
    logoutWithRedirect,
  ]);
  const onClickGoToWayfinder = useCallback(
    () => (window.location.href = window.location.origin),
    []
  );

  useEffect(() => {
    if (!authenticationIsLoading && !isAuthenticated) {
      loginWithRedirect();
    }
  }, [authenticationIsLoading, isAuthenticated, loginWithRedirect]);
  return (
    <div className={fallbackClasses.fullscreen}>
      <img src={logo} className={fallbackClasses.logo} alt="Sofar Wayfinder" />
      <>
        <div>
          {isAuthenticated && (
            <div>
              <WayfinderTypography variant="header" className={classes.text}>
                You have successfully logged into Wayfinder
              </WayfinderTypography>
              <div className={classes.buttonContainer}>
                <Button
                  variant={"contained"}
                  color="primary"
                  onClick={onClickGoToWayfinder}
                  className={classes.button}
                >
                  Go to Wayfinder
                </Button>
                <Button
                  variant={"outlined"}
                  onClick={onClickLogout}
                  className={classes.button}
                >
                  Logout
                </Button>
              </div>
            </div>
          )}
        </div>
      </>
    </div>
  );
};
